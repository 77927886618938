import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { NoteWithJobHeader } from './types/pro-note.type';

export const useProNotesStore = defineStore(
  'pro-notes',
  () => {
    const notesWithJobHeaders = ref<NoteWithJobHeader[]>([]);

    return { notesWithJobHeaders };
  },
  { persist: false },
);
