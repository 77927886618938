<script setup lang="ts">
import { useInviteMember } from '@/composables/useInviteMember';

const emit = defineEmits<{
  (e: 'inviteSent'): void;
}>();

const { isLoading, emailInput, isValidEmailInput, inviteSent, onInvite, resetState } =
  useInviteMember();

async function onInviteClick() {
  await onInvite();
  if (inviteSent.value) {
    resetState();
    emit('inviteSent');
  }
}
</script>

<template>
  <div class="flex flex-col p-4">
    <span class="text-base font-bold">Invite your teammates</span>
    <span class="py-1 text-xs">Collaborate with your team to get the best out of FactoryFix</span>

    <div class="grid grid-cols-12 items-center">
      <input
        v-model="emailInput"
        type="text"
        class="col-span-8 mt-4 h-10 rounded-l-md border border-tint-100 p-2 text-sm"
        placeholder="Email.."
      />

      <button
        :disabled="!isValidEmailInput || isLoading"
        :class="{
          'col-span-4 mt-4 h-10 rounded-r-md text-xs': true,
          'bg-primary-500': isValidEmailInput,
          'bg-shade-500': !isValidEmailInput,
        }"
        @click="onInviteClick"
      >
        Invite
      </button>
    </div>
  </div>
</template>
