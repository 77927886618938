<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import RadioGroupInput from '@/components/Shared/FormInputs/RadioGroupInput/RadioGroupInput.vue';
import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import {
  OTHER_REASON,
  standardRejectionReasons,
} from '@/core/conversations/pro-profile/utils/application-statuses';
import { isRequired, minimumLength } from '@/utils/forms';
import TextField from '@/components/Shared/Input/TextInputs/TextField.vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

const isAutoSend = ref<boolean>(false);

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
  (e: 'onCancel'): void;
  (e: 'onUpdateRejectedReason', payload: string, isSilent: boolean): void;
}>();

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}
function cancelModal() {
  emit('onCancel');
  handleUpdateModelValue(false);
}

const selectedReason = ref<string>(standardRejectionReasons[0].value);
const standardReasons = ref<typeof standardRejectionReasons>(standardRejectionReasons);
const customReason = ref<string>('');

const updateReason = (newValue: string) => {
  selectedReason.value = newValue;
};
const handleAutoSendSelection = () => {
  isAutoSend.value = !isAutoSend.value;
  localStorage.setItem('isAutoSend', isAutoSend.value.toString());
};
onMounted(() => {
  isAutoSend.value = localStorage.getItem('isAutoSend') === 'true';
});

const loading = ref<boolean>(false);
const form = ref<boolean>(false); /** is Form valid?*/
const onSubmit = () => {
  if (!form.value) return;
  loading.value = true;
  emit(
    'onUpdateRejectedReason',
    selectedReason.value === OTHER_REASON ? customReason.value : selectedReason.value,
    !isAutoSend.value /** If auto-send is `true` then 'IS NOT SILENT' */,
  );
  loading.value = false;
};
</script>
<template>
  <v-dialog :model-value="isOpen" max-width="600px" height="550px" persistent>
    <div class="rounded-2xl bg-white p-8">
      <img
        class="float-right mt-1 cursor-pointer"
        src="@/assets/svg/close-black.svg"
        alt="close"
        width="20"
        height="20"
        @click="cancelModal()"
      />
      <p class="mb-4 font-serif text-2xl font-black text-copilot">Rejection reason</p>
      <v-form v-model="form" @submit.prevent="onSubmit">
        <div class="mb-6">
          <RadioGroupInput
            :items="standardReasons"
            :value="selectedReason"
            :on-change="updateReason"
          />
          <template v-if="selectedReason === OTHER_REASON">
            <text-field
              v-model="customReason"
              placeholder="Add rejection reason"
              maxlength="100"
              counter
              class="mt-4"
              :rules="[isRequired, minimumLength]"
            />
          </template>
        </div>

        <!-- Actions -->
        <div class="flex items-center justify-end">
          <div class="ml-2 w-full text-sm">
            <span @click.stop="handleAutoSendSelection" class="cursor-pointer"
              ><Checkbox :model-value="isAutoSend" class="mb-[-2px] mr-2 inline-block"></Checkbox
              >Auto-send response to candidate.</span
            >
          </div>
          <v-btn
            :ripple="false"
            class="modal-button-secondary"
            variant="flat"
            @click="cancelModal()"
            >Cancel</v-btn
          >
          <v-btn
            :ripple="false"
            :disabled="!form"
            :loading="loading"
            class="modal-button-primary ml-4"
            type="submit"
            variant="flat"
            >Submit</v-btn
          >
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>
