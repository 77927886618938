import type { GroupMembership, UserProfileWithRelations } from '@factoryfixinc/ats-interfaces';

export function hasAuthGroup(
  userProfile: UserProfileWithRelations<'userAuthGroup'> | undefined,
  groupMembership: GroupMembership,
): boolean {
  if (!userProfile || !groupMembership) {
    return false;
  }

  return userProfile.userAuthGroup?.groupMembership === groupMembership;
}
