<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: 'Label',
  },
});

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get: () => props.value,
  set: (value) => emit('update:modelValue', value),
});
</script>

<template>
  <v-switch
    :label="label"
    v-model="modelValue"
    :disabled="disabled"
    class="custom-switch"
    density="compact"
    hide-details
  ></v-switch>
</template>

<style>
.custom-switch {
  .v-switch__track {
    width: 24px !important;
    height: 12px !important;
    background: #94a3b8 !important;
    opacity: 1 !important;
  }
  .v-selection-control--dirty {
    .v-switch__track {
      @apply bg-highlight-500 !important;
      opacity: 1 !important;
    }
  }
  .v-switch__thumb {
    width: 16px !important;
    height: 16px !important;
    box-shadow: none !important;
    border: 0.5px solid #94a3b8;
  }
  .v-selection-control__input::before {
    opacity: 0 !important;
  }
  .v-ripple__container {
    display: none;
  }
  .v-selection-control__wrapper {
    @apply ml-2;
  }
}
</style>
