import type { Subscription } from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSubscriptionV2Store = defineStore('subscription-v2', () => {
  const subscription = ref<Subscription>();

  return {
    subscription,
    portalSessionUrl: ref<string>(),
  };
});
