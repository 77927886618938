import type {
  GetMessagePagination,
  GetMessagesByConversationResponse,
  MessagesByConversation,
} from '@/core/conversations/message/conversation-message.type';
import SearchUtils from '@/utils/search-utils';
import { useAtsConversationApi, useAtsConversationApiWithOptions } from '@/composables/useApi';
import { useConversationMessageStore } from '@/core/conversations/message/conversation-message.store';
import type { UserMessageStatus } from './types/get-user-message-status.type';
import { InternalError } from '@/core/shared/errors/internal.error';
import { ErrorName } from '@/core/shared/errors/enums/error-name.enum';
import type { Employer } from '@factoryfixinc/ats-interfaces';

export class ConversationMessagePersistence {
  private readonly store = useConversationMessageStore();

  public async fetchConversationMessages(
    conversationId: number,
    page = 1,
  ): Promise<(MessagesByConversation & { conversationId: number })[]> {
    const paginationSearch = SearchUtils.convertSearchObjectToSearchUrl({
      pagination: { page } as GetMessagePagination,
    });

    const url = `/message/conversation/${conversationId}/messages?${paginationSearch}`;

    const concurrentCalls = this.store.cancellableRequests.filter(
      (req) => req.caller === this.fetchConversationMessages.name,
    );

    if (concurrentCalls.length) {
      await Promise.all(concurrentCalls.map((req) => req.abort()));
      this.store.cancellableRequests = this.store.cancellableRequests.filter(
        (req) => req.caller !== this.fetchConversationMessages.name,
      );
    }

    const { execute, abort, data, error, statusCode } = useAtsConversationApi(url, {
      immediate: false,
    })
      .get()
      .json<GetMessagesByConversationResponse>();

    this.store.cancellableRequests.push({ abort, caller: this.fetchConversationMessages.name });
    await execute();

    if (error.value) {
      const internalError = new InternalError('Failed to fetch conversation messages', {
        error: error.value,
        status: statusCode.value,
        data: { conversationId, page },
      });

      if (internalError.name === ErrorName.ABORT_ERROR) {
        return [];
      }

      throw internalError;
    }
    const messages =
      data.value?.messages?.map((m) => ({ ...m, conversationId: conversationId })) ?? [];
    return messages;
  }

  public async getUserMessageStatus(
    userProfileId: number,
    employerId: Employer['id'],
  ): Promise<UserMessageStatus> {
    const url = `message/pro/${userProfileId}/message-status`;
    const { data, error, statusCode } = await useAtsConversationApiWithOptions({
      headers: { 'employer-id': `${employerId}` },
    })(url)
      .get()
      .json<UserMessageStatus>();

    if (error.value) {
      throw new InternalError('Failed to get user message status', {
        error: error.value,
        status: statusCode.value,
        data: { userProfileId },
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response received from getUserMessageStatus', {
        status: statusCode.value,
        data: { userProfileId },
      });
    }

    return data.value;
  }
}
