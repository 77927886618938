export const isValidPostalCode5 = (
  value: string | null,
  message: string = 'Please enter a valid 5-digit ZIP code',
): boolean | string => {
  const regex = /^\d{5}$/;

  if (value && regex.test(value)) {
    return true;
  }

  return message;
};
