import xlsx, { type IColumn, type IContent, type IJsonSheet } from 'json-as-xlsx';

import {
  type JobApplicant,
  JobApplicantStatus,
  type ProHistoryEmployer,
  type UserProfile,
} from '@factoryfixinc/ats-interfaces';
import dayjs from 'dayjs';

export type CustomJobApplicant = {
  name?: Partial<UserProfile['nameFirst'] & UserProfile['nameLast']>;
  nameFirst?: UserProfile['nameFirst'];
  nameLast?: UserProfile['nameLast'];
  postalCode?: string;
  email?: UserProfile['email'];
  phone?: UserProfile['phone'];
  historyJobTitle?: ProHistoryEmployer['jobTitle'];
  status?: JobApplicantStatus | string;
  link?: string | URL;
  updateTs?: Partial<JobApplicant['updateTs'] | JobApplicant['createTs']>;
  userProfileId?: JobApplicant['userProfileId'];
  id?: JobApplicant['id'];
};
export type Columns = keyof CustomJobApplicant;

export interface CandidateExportInfo {
  candidateData: CustomJobApplicant[];
  jobId: string | number;
  fileName: string;
  sheetName: string;
}

type Row = CustomJobApplicant;

/**
 *
 * @param userProfileId
 * @param jobId
 * @param applicationId
 * @returns {string}
 * @description
 * 1. If no jobId or applicationId, return link to pro profile
 * 2. If jobId and applicationId, return link to application
 * N.B - this would not generate links for admins, as the expected use case is for employers or admins generating links for employers
 */
export const generateApplicationLink = (
  userProfileId: number,
  jobId?: number | string,
  applicationId?: number,
): string => {
  if (!userProfileId) {
    return '';
  }
  if (!jobId || !applicationId) {
    return `${window.location.origin}/employer/pro/${userProfileId}`;
  }
  return (
    `${window.location.origin}/conversations/conversation` +
    `?jobApplicantId=${applicationId}&jobId=${jobId}&isDeepLink=1`
  );
};

export const setDisplayStatus = (status: Row['status']): string => {
  if (!status) {
    return '';
  }
  switch (status) {
    case JobApplicantStatus.CLIENT:
      return 'Engaged';
    case JobApplicantStatus.NEW:
    case JobApplicantStatus.CONTACT:
      return 'New Applications';
    case JobApplicantStatus.REVIEW:
      return 'Reviewed';
    default:
      return status;
  }
};

export const columnFields: IColumn[] = [
  { label: 'Name', value: 'name' },
  { label: 'Zip Code', value: 'Zip Code' },
  { label: 'Email', value: 'email' },
  {
    label: 'Phone',
    value: (row: Row) => (row.phone ? row.phone : ''),
    format: '(###)-###-####',
  },
  {
    label: 'Last Experience',
    value: 'historyJobTitle',
  },
  { label: 'Status', value: 'status' },
  {
    label: 'Profile Link',
    value: 'link',
  },
  {
    label: 'Apply Date',
    value: (row: Row) => dayjs(row.updateTs).format('MM/DD/YYYY'),
    format: 'm/d/yy',
  },
];

export const exportXlsx = (exportInfo: CandidateExportInfo): Buffer | undefined => {
  const { candidateData, jobId, fileName, sheetName } = exportInfo;

  const data: IJsonSheet[] = [];
  const columns = columnFields;
  const content: IContent[] =
    candidateData?.map((row: CustomJobApplicant) => {
      return {
        name: `${row.nameFirst} ${row.nameLast}`,
        'Zip Code': row.postalCode,
        email: row.email,
        phone: row.phone,
        historyJobTitle: row.historyJobTitle as string,
        status: setDisplayStatus(row.status),
        updateTs: row.updateTs,
        link: generateApplicationLink(row.userProfileId as number, jobId, row.id),
      } as IContent;
    }) ?? [];

  data.push({
    sheet: sheetName || 'FactoryFix Skilled Pros',
    columns,
    content,
  });

  const settings = {
    fileName: fileName || 'ff-export', // Name of the resulting spreadsheet
    extraLength: 1, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  return xlsx(data, settings);
};
