import { useAtsEmployerFeatureApi } from '@/composables/useApi';
import type { EmployerFeature } from '@factoryfixinc/ats-interfaces';
import { useMeStore } from '../me/me.store';

export class EmployerFeaturePersistence {
  private store = useMeStore();

  public async fetchEmployerFeatures(): Promise<EmployerFeature[]> {
    const employerId = this.store.employer?.id;

    const { data, error } = await useAtsEmployerFeatureApi(`/employer-features/${employerId}`).json<
      EmployerFeature[]
    >();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('No data');
    }

    return data.value;
  }
}
