export function formatPhoneNumber(phoneNumber?: string | null): string {
  if (!phoneNumber) {
    return '';
  }

  // Remove all non-numeric characters from the phone number
  const digits = phoneNumber.replace(/\D/g, '');

  // Format the digits into the desired format
  const match = digits.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  // Returns an empty string if the phone number is not in the expected format
  return '';
}
