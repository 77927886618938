interface Title {
  displayTitle: string;
  subTitle: string;
}
/**
 * Looks up the title for the job and generates the appropriate title
 * @param {Object} job - Job object
 * @param {Boolean} includeId - Option to include the job's ID in the title. Default is true
 */
export function getJobTitle(
  job?: {
    title?: string;
    jobTitle?: { title?: string };
    jobTitleCustom?: string;
    id?: number;
    displayTitle?: string;
  },
  includeId: boolean = true,
): Title {
  const title: Title = { displayTitle: '', subTitle: '' };
  let jobTitle: string = '';

  if (job?.title) {
    jobTitle = job.title;
  } else if (job?.jobTitle && job.jobTitle.title) {
    jobTitle = job.jobTitle.title;
  } else if (job?.jobTitleCustom) {
    jobTitle = job.jobTitleCustom;
  } else {
    jobTitle = 'No Job Title';
  }

  if (job?.displayTitle) {
    title.displayTitle = job.displayTitle;
    title.subTitle = jobTitle;
  } else {
    title.displayTitle = jobTitle;
  }

  if (includeId && job?.id) {
    title.displayTitle = `${title.displayTitle} [${job.id}]`;
    title.subTitle = title.subTitle ?? '';
  }

  return title;
}
