import type { Job } from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useJobStore = defineStore(
  'job',
  () => {
    const jobs = ref<Job[]>([]);

    return {
      jobs,
    };
  },
  { persist: false },
);
