import { useAuthenticationStore } from './authentication.store';
import { jwtDecode } from 'jwt-decode';
import MeService from '@/core/shared/me/me.service';
import { AuthenticationPersistence } from '@/core/shared/authentication/authentication.persistence';
import { InternalError } from '@/core/shared/errors/internal.error';
import { getExponentialBackoffWithJitter } from '@/utils/backoff/backoff.util';
import { ApiRequestFatalError } from '../errors/api-request-fatal.error';
import { ErrorService } from '../errors/error.service';

export default class AuthenticationService {
  private readonly store = useAuthenticationStore();
  private readonly persistence = new AuthenticationPersistence();
  private readonly meService = new MeService();

  get isAuthenticated(): boolean {
    let isLoggedIn = false;

    if (this.store.idToken && this.store.idTokenExpiresAt) {
      if (Date.now() < this.store.idTokenExpiresAt) {
        isLoggedIn = true;
      }
    }

    return isLoggedIn;
  }

  get accessToken(): string | undefined {
    return this.store.accessToken;
  }

  setAccessToken(token: string): void {
    const decodedToken = jwtDecode(token);
    this.store.accessToken = token;

    const expireTs = decodedToken.exp
      ? decodedToken.exp * 1000
      : // if no exp is set, use maximum refresh interval
        new Date().getTime() + accessTokenRefreshMaximum;

    this.store.accessTokenExpiresAt = expireTs;
    this.scheduleAccessTokenRefresh(expireTs);
  }

  get idToken(): string | undefined {
    return this.store.idToken;
  }

  setIdToken(token?: string | null): void {
    if (token) {
      this.store.idToken = token;
    }

    if (!this.store.idToken) {
      return;
    }

    const decodedToken = jwtDecode(this.store.idToken);
    const expireTs = decodedToken.exp
      ? decodedToken.exp * 1000
      : // if no exp is set, use maximum refresh interval
        new Date().getTime() + idTokenRefreshMaximum;

    this.store.idTokenExpiresAt = expireTs;
    this.scheduleIdTokenRefresh(expireTs);
  }

  logout(): void {
    clearTimeout(this.store.idTokenRefreshTimerId);
    this.store.clear();
    this.meService.clear();
  }

  scheduleAccessTokenRefresh(expiresAt: number): void {
    const refreshTime = this.calculateAccessTokenRefreshTime(expiresAt);

    this.store.accessTokenRefreshTimerId = setTimeout(() => {
      this.refreshAccessToken();
    }, refreshTime);
  }

  scheduleAccessTokenRefreshRetry(retriesAttempted: number): void {
    const retryTime = this.calculateAccessTokenRefreshRetryTime(retriesAttempted);

    this.store.accessTokenRefreshTimerId = setTimeout(() => {
      this.refreshAccessToken(retriesAttempted + 1);
    }, retryTime);
  }

  scheduleIdTokenRefresh(expiresAt: number): void {
    const refreshTime = this.calculateIdTokenRefreshTime(expiresAt);

    this.store.idTokenRefreshTimerId = setTimeout(() => {
      this.refreshIdToken();
    }, refreshTime);
  }

  async refreshAccessToken(retriesAttempted: number = 0): Promise<void> {
    const employerId = this.meService.getSelectedEmployerId();
    if (!employerId) {
      // Fatal error. Retrying will not change the outcome.
      throw new InternalError('Could not get access token because employerId is unset.');
    }
    try {
      const token = await this.persistence.getAccessTokenWithEmployerContext(employerId);
      this.setAccessToken(token);
    } catch (error) {
      if (error instanceof ApiRequestFatalError) {
        // don't retry fatal errors that won't change with a retry
        throw error;
      } else {
        // retry on server errors and all other unknown errors
        ErrorService.captureMessage(
          `Failed to get access token for user ${this.meService.userProfile?.id}` +
            ` (${retriesAttempted} retries). Retrying...`,
        );
        this.scheduleAccessTokenRefreshRetry(retriesAttempted);
      }
    }
  }

  async refreshIdToken(): Promise<void> {
    const token = await this.persistence.refreshIdToken();
    this.setIdToken(token);
  }

  private calculateAccessTokenRefreshTime(expiresAt: number) {
    const currentTime = new Date().getTime();
    const expiresIn = expiresAt - currentTime;
    const refreshIn = expiresIn - accessTokenRefreshBuffer;
    return Math.max(Math.min(refreshIn, accessTokenRefreshMaximum), accessTokenRefreshMinimum);
  }

  private calculateAccessTokenRefreshRetryTime(retriesAttempted: number) {
    const backoffBase = 5000; // 5 seconds
    return getExponentialBackoffWithJitter(
      backoffBase,
      retriesAttempted,
      accessTokenRefreshMaximum,
    );
  }

  private calculateIdTokenRefreshTime(expiresAt: number) {
    const currentTime = new Date().getTime();
    const expiresIn = expiresAt - currentTime;
    const refreshIn = expiresIn - idTokenRefreshBuffer;
    return Math.max(Math.min(refreshIn, idTokenRefreshMaximum), idTokenRefreshMinimum);
  }
}

/**
 * How many milliseconds before a token expires ought we try to refresh it?
 * Anything less than 30 seconds seems risky.
 */
const accessTokenRefreshBuffer = 30 * 1000;
/**
 * Wait at least 30 seconds to refresh to avoid infinite loops with very short
 * token lifetimes
 */
const accessTokenRefreshMinimum = 30 * 1000;
/**
 * Access tokens should be refreshed frequently. Make 30 minutes the maximum
 * time between refreshes, even if the token lifetime is longer.
 */
const accessTokenRefreshMaximum = 30 * 60 * 1000;

/**
 * How many milliseconds before a token expires ought we try to refresh it? (use
 * 7190000 in testing to get a refresh every 10 seconds)
 */
const idTokenRefreshBuffer = 5 * 60 * 1000;
/**
 * Wait at least one minute to refresh to avoid infinite loops with very short
 * token lifetimes
 */
const idTokenRefreshMinimum = 1 * 60 * 1000;
/**
 * If token lifetime is larger than what a 32-bit integer can hold, we will
 * have issues with setTimeout. Therefore, we set a maximum of 2 hours here.
 *
 * refresh token every 2 hours whether it needs it or not
 */
const idTokenRefreshMaximum = 120 * 60 * 1000;
