<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import JobService from '@/core/shared/job/job.service';
import type { JobWithRelations, Project } from '@factoryfixinc/ats-interfaces';
import ProjectService from '@/core/shared/project/project.service';
import ChevronRightDoubleSvg from '@/assets/svg/chevron-right-double-20.svg?component';
import ProjectAtsSyncInfo from '@/components/Shared/Projects/ProjectAtsSyncInfo.vue';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import ProjectDescriptionOverlayNoContent from './ProjectDescriptionOverlayNoContent.vue';
import ProjectDescriptionDetails from './ProjectDescriptionDetails.vue';
import ProjectJobVersionSelector from './ProjectJobVersionSelector.vue';
import { JobUtil } from '@/utils/job.util';
import router from '@/router';
import VersionsService from '@/core/jobs/versions/versions.service';
import { ErrorService } from '@/core/shared/errors/error.service';

const dialogService = new DialogService();
const jobService = new JobService();
const projectService = new ProjectService();
const jobVersionsService = new VersionsService();

const isLoading = ref(true);
const project = ref<Project>();
const job = ref<JobWithRelations<'jobStatusHistories'>>();

const projectId = computed(() => dialogService.projectDescriptionOverlayProps.projectId);
const jobId = computed(() => dialogService.projectDescriptionOverlayProps.jobId);
const isOpen = computed(() => dialogService.projectDescriptionOverlayProps.isOpen);

const copilotActiveDaysAgo = computed(() => {
  const duration = JobUtil.getJobLiveStatusDuration(job.value);

  if (!duration) {
    return '';
  }

  return `Copilot was activated ${duration}`;
});

function closeOverlay() {
  dialogService.projectDescriptionOverlayProps.isOpen = false;
}

async function loadProjectJobInformation() {
  try {
    if (!projectId.value || !jobId.value) {
      return;
    }

    isLoading.value = true;
    project.value = projectService.getLocalProjectById(projectId.value);
    job.value = await jobService.getById(jobId.value);
    await jobVersionsService.getJobVersionsHeader(projectId.value);
  } finally {
    isLoading.value = false;
  }
}

function handleAddJobDetails() {
  if (!projectId.value || !jobId.value) {
    return;
  }
  closeOverlay();

  router.push(`/jobs/${projectId.value}/copilot-activation`);
}

watch(isOpen, (value) => {
  // Ensure that the project and job information is cleared and then loaded when the overlay is opened
  project.value = undefined;
  job.value = undefined;
  jobVersionsService.resetJobVersions();
  if (value === true) {
    loadProjectJobInformation();
  }
});

const showJobVersions = computed(() => jobVersionsService.jobVersions.length >= 1);
const currentJobVersionId = computed(() => jobVersionsService.currentJobVersionId);
const isLoadingOneJobVersion = ref(false);

watch(currentJobVersionId, async (value) => {
  if (!projectId.value || !value) return;
  try {
    isLoadingOneJobVersion.value = true;
    await jobVersionsService.getJobVersionById(projectId.value, value);
    // We need to preserve the original jobStatusHistories since our Job Versions snapshot do not save them
    job.value = {
      ...(jobVersionsService.jobVersionWithRelations as JobWithRelations<'jobStatusHistories'>),
      jobStatusHistories: job.value?.jobStatusHistories,
    };
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    isLoadingOneJobVersion.value = false;
  }
});
</script>

<template>
  <v-overlay
    v-model="dialogService.projectDescriptionOverlayProps.isOpen"
    contained
    persistent
    no-click-animation
    transition="slide-x-reverse-transition"
    content-class="project-description-overlay"
  >
    <div class="content-inset-shadow flex h-full flex-col py-4 pl-4 pr-1">
      <!-- Title and close overlay button -->
      <div class="mb-4 flex items-center justify-between pr-3">
        <Transition name="fade" mode="out-in">
          <div v-if="project" class="text-sm font-bold">
            {{ project.title }}
          </div>
          <div v-else>&nbsp;</div>
        </Transition>

        <button @click="closeOverlay">
          <ChevronRightDoubleSvg />
        </button>
      </div>

      <Transition name="fade" mode="out-in">
        <div v-if="isLoading" class="mr-3 flex h-full items-center justify-center">
          <SpinnerLoader />
        </div>

        <div v-else class="project-description-overlay-scrollbar flex-grow overflow-y-auto pr-3">
          <!-- Copilot Active Days Ago -->
          <div v-if="copilotActiveDaysAgo" class="mb-4 pr-3 text-xs text-shade-800">
            {{ copilotActiveDaysAgo }}
          </div>

          <!-- ATS Sync Info -->
          <div v-if="project?.remoteJobId" class="mb-4 pr-3">
            <ProjectAtsSyncInfo :project-id="project.id" :remote-job-id="project.remoteJobId" />
          </div>

          <!-- Job Version Selector -->
          <ProjectJobVersionSelector
            :class="{ 'pointer-events-none opacity-75 ': isLoadingOneJobVersion }"
            v-if="showJobVersions"
            :is-loading="isLoadingOneJobVersion"
          />

          <!-- Job Description -->
          <ProjectDescriptionDetails v-if="job?.description" :job="job" />

          <ProjectDescriptionOverlayNoContent
            v-else
            class="mt-28"
            @click:add-job-details="handleAddJobDetails"
          />
        </div>
      </Transition>
    </div>
  </v-overlay>
</template>

<style>
/*
 * Must not be scoped since the component is moved to the beginning of the v-app
 * https://vuetifyjs.com/en/api/v-overlay/#props-content-class
 */
.project-description-overlay {
  @apply h-full border-r border-r-shade-805 bg-white text-black;
  width: calc(100% + 2px);
  border-right-color: #ecf1f4ff;
}
</style>

<style scoped>
.content-inset-shadow {
  box-shadow: inset -16px 0px 16px -16px rgba(0, 0, 0, 0.1);
}

/** Scrollbar width */
.project-description-overlay-scrollbar::-webkit-scrollbar {
  width: 6px;
}

/** Scrollbar color and border radius */
.project-description-overlay-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-tint-60;
  border-radius: 10px;
}
</style>
