<template>
  <div class="flex space-x-0.5" v-if="applicationScore">
    <StarIcon
      v-for="n in numFullStars"
      :key="n"
      alt="Star"
      :class="['rounded', 'self-center']"
      :bg="starsColors.backgroundColor"
      :star="starsColors.halfStarLeftColor"
      :width="width"
      :height="height"
    />
    <StarHalfIcon
      v-if="hasHalfStar"
      alt="Half-star"
      class="self-center"
      :bg-left="starsColors.backgroundColor"
      :bg-right="starsColors.halfStarRightBackgroundColor"
      :half-star-left="starsColors.halfStarLeftColor"
      :half-star-right="starsColors.halfStarRightColor"
      :width="width"
      :height="height"
    />
    <img
      v-for="n in numEmptyStars"
      :key="n"
      src="@/assets/svg/conversations/star_empty.svg"
      alt="Star"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';

import StarIcon from '@/assets/svg/conversations/StarIcon.vue';
import StarHalfIcon from '@/assets/svg/conversations/StarHalfIcon.vue';

const props = withDefaults(
  defineProps<{
    score?: number;
    width?: number;
    height?: number;
  }>(),
  {
    score: 0,
    width: 16,
    height: 16,
  },
);
function normalizeScoreValue(score: number): number {
  return Number(ApplicationScoreService.formatScore(score));
}

const POSSIBLE_TOTAL_STARS = 5;
const applicationScore = computed(() => normalizeScoreValue(props?.score || 0));
const numFullStars = computed(() => Math.floor(applicationScore.value));
const hasHalfStar = computed(() => applicationScore.value - numFullStars.value >= 0.5);
const numEmptyStars = computed(
  () => POSSIBLE_TOTAL_STARS - numFullStars.value - Number(hasHalfStar.value),
);
const starsColors = computed(() => {
  if (applicationScore.value >= 4) {
    return {
      backgroundColor: '#53D58B',
      halfStarLeftColor: '#184F32',
      halfStarRightColor: '#DEFAEA',
      halfStarRightBackgroundColor: '#BEF4D5',
    };
  }
  if (applicationScore.value >= 2) {
    return {
      backgroundColor: '#EDCD77',
      halfStarLeftColor: '#5F4604',
      halfStarRightColor: '#FFF4D8',
      halfStarRightBackgroundColor: '#FBEABC',
    };
  }
  return {
    backgroundColor: '#E96363',
    halfStarLeftColor: '#5A0303',
    halfStarRightColor: '#FDE6E6',
    halfStarRightBackgroundColor: '#F6CDCD',
  };
});
</script>
