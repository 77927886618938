export const isValidMaxSalaryPayRate = (
  payRateSalaryMin: number | null,
  payRateSalaryMax: number | null,
): boolean | string => {
  if (payRateSalaryMin === null || payRateSalaryMax === null) {
    return false;
  }

  return Number(payRateSalaryMin) > Number(payRateSalaryMax)
    ? 'Max salary rate must be greater than minimum salary rate'
    : true;
};
