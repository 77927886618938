<template>
  <v-radio-group
    class="custom-radio-group"
    hide-details
    density="compact"
    v-model="radioValue"
    @change="() => onChange(radioValue)"
  >
    <v-radio
      v-for="item in items"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></v-radio>
  </v-radio-group>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const props = defineProps<{
  items: Array<{ label: string; value: string }>;
  value: string;
  onChange: (value: string) => void;
}>();

const radioValue = ref(props.value);
</script>
<style>
.custom-radio-group {
  .v-selection-control__input {
    width: 16px !important;
    height: 16px !important;
  }
  .v-selection-control__input::before {
    border: 1px solid;
    background: transparent;
    @apply border-tint-100;
    opacity: 1;
  }
  .v-selection-control__input input {
    opacity: 1;
    accent-color: #6c40e5;
  }
  .v-ripple__container {
    display: none;
  }
  .v-label {
    @apply px-2 py-1;
    opacity: 1;
  }
}
</style>
