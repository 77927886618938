import { useSubscriptionGatewayApi } from '@/composables/useApi';
import { HttpStatus } from '../errors/enums/http-status.enum';
import { InternalError } from '../errors/internal.error';
import type { Subscription } from '@factoryfixinc/ats-interfaces';

export class SubscriptionPersistenceV2 {
  async fetchSubscription(employerId: number): Promise<Subscription | undefined> {
    const { error, data, statusCode } = await useSubscriptionGatewayApi(
      `/v1/subscription/employer/${employerId}`,
    )
      .get()
      .json<{
        subscription: Subscription;
      }>();

    if (statusCode.value === HttpStatus.NOT_FOUND || !data.value) {
      return;
    }

    if (error.value) {
      throw new InternalError('Could not load subscription information', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value.subscription;
  }

  async fetchPortalSessionUrl(employerId: number): Promise<string> {
    const { error, data } = await useSubscriptionGatewayApi(
      `/v1/portal-session/employer/${employerId}`,
    )
      .post()
      .json<{
        portalSession: { access_url: string };
      }>();

    if (error.value || !data.value?.portalSession?.access_url) {
      throw new InternalError('Could not load billing portal session', {
        error: error.value,
      });
    }

    return data.value.portalSession.access_url;
  }
}
