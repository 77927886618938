<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="search-md">
      <path
        id="Icon"
        d="M56 56L44.4 44.4M50.6667 29.3333C50.6667 41.1154 41.1154 50.6667 29.3333 50.6667C17.5513 50.6667 8 41.1154 8 29.3333C8 17.5513 17.5513 8 29.3333 8C41.1154 8 50.6667 17.5513 50.6667 29.3333Z"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
