import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { ProScore } from './application-score.type';

export const useApplicationScoreStore = defineStore(
  'applicationScore',
  () => {
    const scores = ref(new Map<number, ProScore>());

    function setScoreDetail(proScore: ProScore) {
      scores.value.set(proScore.jobApplicationId, proScore);
    }

    function getScoreDetail(applicationId: number) {
      return scores.value.get(applicationId);
    }

    return {
      setScoreDetail,
      getScoreDetail,
    };
  },
  {
    persist: true,
  },
);
