<script setup lang="ts">
import { ref } from 'vue';
import GetHelpContent from './GetHelpContent.vue';

defineProps<{
  target: string;
}>();

const isMenuOpen = ref(false);

async function onSubmit() {
  isMenuOpen.value = false;
}
</script>

<template>
  <v-menu
    v-model="isMenuOpen"
    transition="slide-x-transition"
    location="right top"
    :offset="8"
    :target="target"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <div class="cursor-pointer" v-bind="props">
        <slot name="activator" :props="props" />
      </div>
    </template>

    <div
      :class="[
        'border-1',
        'flex',
        'flex-col',
        'rounded-md',
        'bg-white',
        'w-[400px]',
        'shadow-menu',
      ]"
    >
      <GetHelpContent @submit="onSubmit" />
    </div>
  </v-menu>
</template>
