import '@/assets/css/tailwind.css';

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

import initSentryPlugin from '@/plugins/sentry.plugin';
import initBootstrapPlugin from '@/plugins/bootstrap.plugin';
import initChameleonPlugin from '@/plugins/chameleon.plugin';
import initHotjarPlugin from '@/plugins/hotjar.plugin';
import vuetify from '@/plugins/vuetify.plugin';

async function main() {
  const app = createApp(App);

  // Pinia
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);

  // Install
  app.use(pinia);
  app.use(router);
  app.use(vuetify);

  // Plugins
  initSentryPlugin(app);
  await initBootstrapPlugin(router);
  initChameleonPlugin();
  initHotjarPlugin();
  app.mount('#app');
}

main();
