<script setup lang="ts">
import { type PropType, onMounted, ref, watch } from 'vue';
import AtsConnectService from '@/core/shared/ats-connect/ats-connect.service';

const props = defineProps({
  remoteJobId: {
    type: String as PropType<string | null>,
    required: true,
  },
  projectId: {
    type: Number,
    required: true,
  },
});

const atsStatus = ref<string>('');
const atsConnectService = new AtsConnectService();

const fillAtsStatus = async () => {
  if (props.remoteJobId) {
    const syncedJob = await atsConnectService.getSyncedJobByProjectId(props.projectId);
    atsStatus.value = syncedJob?.status || '';
  }
};

onMounted(async () => {
  await fillAtsStatus();
});

watch(props, async () => {
  await fillAtsStatus();
});
</script>

<template v-if="remoteJobId">
  <div
    data-test-id="project-header-ats-req-id"
    class="w-[120px] text-ellipsis whitespace-nowrap font-sans text-sm font-normal md:w-[unset]"
  >
    ATS ID: {{ remoteJobId }}
  </div>

  <div
    data-test-id="project-header-ats-status"
    class="w-[120px] text-ellipsis whitespace-nowrap font-sans text-sm font-normal md:w-[unset]"
  >
    ATS job status: {{ atsStatus.charAt(0).toUpperCase() + atsStatus.slice(1).toLowerCase() }}
  </div>
</template>
