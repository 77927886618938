export default class SearchUtils {
  public static convertSearchObjectToSearchUrl<T>(searchObject: T): string {
    const searchKeys = this.convertSearchObjectToSearchPairs(searchObject);
    const searchParams = new URLSearchParams();
    searchKeys.forEach(([keys, value]) =>
      searchParams.append(this.keysToBracketString(keys), value),
    );

    return searchParams.toString();
  }

  private static convertSearchObjectToSearchPairs<T>(searchObject: T): [string[], string][] {
    const pairs = [];
    for (const key in searchObject) {
      const value = searchObject[key];
      if (typeof value === 'object') {
        this.convertSearchObjectToSearchPairs(value).forEach(([_keys, _value]) => {
          pairs.push([[key, ..._keys], _value]);
        });
      } else {
        pairs.push([[key], value] as [string[], string]);
      }
    }

    return pairs;
  }

  private static keysToBracketString(keys: string[]) {
    return keys.slice(1).reduce((total, curr) => total + `[${curr}]`, keys[0]);
  }
}
