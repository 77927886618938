import { useLayoutStore } from './layout.store';

export class LayoutService {
  private readonly store = useLayoutStore();

  public get conversationsPanelWidth(): number {
    return this.store.conversationsPanelWidth;
  }

  public set conversationsPanelWidth(value: number) {
    this.store.conversationsPanelWidth = value;
  }
}
