/**
 * Error thrown when an API request gets an error response that indicates a server error has occurred.
 * Retrying the request later may result in a successful response.
 */
export class ApiRequestServerError extends Error {
  constructor(m?: string) {
    super(m);
    this.name = this.constructor.name;

    // https://stackoverflow.com/questions/41102060/typescript-extending-error-class/48342359#48342359
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
