import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Snackbar } from './types/snackbar.type';

export const SNACKBAR_TIMEOUT_SECONDS_3 = 3000;
export const SNACKBAR_DEFAULT_VERTICAL_POSITION = 'bottom';
export const SNACKBAR_DEFAULT_HORIZONTAL_POSITION = 'left';

export const useSnackbarStore = defineStore('snackbar', () => {
  const snackbar = ref<Snackbar>({
    show: false,
    timeout: SNACKBAR_TIMEOUT_SECONDS_3,
    verticalPosition: SNACKBAR_DEFAULT_VERTICAL_POSITION,
    horizontalPosition: SNACKBAR_DEFAULT_HORIZONTAL_POSITION,
  });

  return {
    snackbar,
  };
});
