<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 427320626">
      <path
        id="Vector 151"
        d="M2 2L10 10"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        id="Vector 152"
        d="M2 10L10 2"
        stroke="currentColor"
        stroke-width="4"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>
