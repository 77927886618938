export const clone = <T>(obj: T): T => {
  // check browser support structuredClone
  if (typeof structuredClone === 'function') {
    try {
      return structuredClone(obj);
    } catch {}
  }

  return JSON.parse(JSON.stringify(obj));
};
