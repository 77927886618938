import type { ViewedCandidate } from './types/viewed-candidate';
import { ViewedCandidatePersistence } from './viewed-candidate.persistence';
import { useViewedCandidateStore } from './viewed-candidate.store';

export class ViewedCandidateService {
  private store = useViewedCandidateStore();
  private persistence = new ViewedCandidatePersistence();

  public set viewedCandidates(viewedCandidates: ViewedCandidate[]) {
    this.store.viewedCandidates = viewedCandidates;
  }

  public get viewedCandidatesRecord(): Record<number, number> {
    return this.store.viewedCandidatesRecord;
  }

  public async fetchViewedCandidates(): Promise<ViewedCandidate[]> {
    const viewedCandidates = await this.persistence.fetchViewedCandidates();
    this.store.viewedCandidates = viewedCandidates;

    return viewedCandidates;
  }

  public async markCandidateAsViewed(proPreferenceId: number): Promise<void> {
    await this.persistence.markCandidateAsViewed(proPreferenceId);
    await this.fetchViewedCandidates();
  }
}
