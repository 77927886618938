import type { DefaultPagination } from '../types/default-pagination.type';

const defaultSort = 'createTs desc';
export const DEFAULT_ITEMS_PER_PAGE = 25;

export const defaultPagination: DefaultPagination = {
  groupBy: [],
  groupDesc: [],
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  itemsPerPageOptions: [10, 25, 50, 100],
  multiSort: false,
  mustSort: false,
  page: 1,
  scrollLevel: 0,
  sortBy: [defaultSort],
  sortDesc: [],
};
