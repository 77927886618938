<script setup lang="ts">
import { type PropType, computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const emits = defineEmits<{
  (e: 'changeTab', tabId: string): void;
}>();

const route = useRoute();
const router = useRouter();

const props = defineProps({
  items: {
    type: Array as PropType<
      {
        title: string;
        routeName: string;
        show: boolean;
        count?: number;
      }[]
    >,
    default: () => [],
    required: true,
  },
});

const selectedIndex = ref(0);
const currentRoute = computed(() => route.name);

const selectTab = (routeName: string, index: number) => {
  selectedIndex.value = index;
  router.push({ name: routeName, query: route.query });
  emits('changeTab', routeName);
};

watch(currentRoute, (newRoute) => {
  const tabIndex = props.items.findIndex((item) => item.routeName === newRoute);
  if (tabIndex !== -1) {
    selectedIndex.value = tabIndex;
    emits('changeTab', props.items[tabIndex].routeName);
  }
});

onMounted(() => {
  const initialTabIndex = props.items.findIndex((item) => item.routeName === currentRoute.value);
  if (initialTabIndex !== -1) {
    selectedIndex.value = initialTabIndex;
  }

  emits('changeTab', props.items[initialTabIndex].routeName);
});
</script>

<template>
  <section class="relative grid grid-cols-[minmax(300px,1fr)] pl-4">
    <div class="tabs">
      <div
        v-for="(tab, index) in items"
        :key="tab.title"
        :text="tab.title"
        @click="selectTab(tab.routeName, index)"
        class="tab"
        :class="{ 'tab--active': tab.routeName === currentRoute }"
        v-show="tab.show"
      >
        <span class="text-current absolute left-0 top-0">{{ tab.title }}</span>
        <span class="invisible font-bold">{{ tab.title }}</span>
        <span
          v-if="tab.count"
          class="ml-1 flex h-4 w-4 items-center justify-center rounded-full bg-highlight-50 font-sans text-xs font-bold text-highlight-600"
        >
          {{ tab.count }}
        </span>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;
  @apply mt-2 flex flex-row flex-nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
}
.tab {
  @apply border-b-2 border-transparent;
  @apply relative mr-6 flex cursor-pointer pb-1 text-xs tracking-wide text-shade-800 transition-all duration-100;
  &--active {
    @apply border-highlight-600 font-bold text-highlight-600;
  }
}
</style>
