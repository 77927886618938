<script setup lang="ts">
import { computed, ref } from 'vue';

import { copyToClipboard } from '@/utils/clipboard-utils';

import CopySvg from '@/assets/svg/conversations/copy-01.svg?component';
import PhoneIcon from '@/assets/svg/conversations/phone.svg?component';
import EmailIcon from '@/assets/svg/conversations/email.svg?component';
import CheckCircleIcon from '@/assets/svg/conversations/check-circle-broken.svg?component';

const props = defineProps({
  text: String,
  collapseText: Boolean,
  truncateText: Boolean,
  icon: String,
});

const showTooltip = ref(false);
const tooltipActive = ref(false);
const copyingText = ref(false);

const iconImg = computed(() => (props.icon === 'phone' ? PhoneIcon : EmailIcon));

const openTooltip = () => {
  tooltipActive.value = true;
  showTooltip.value = true;
};

const closeTooltip = () => {
  tooltipActive.value = false;
  setTimeout(() => {
    if (!tooltipActive.value) showTooltip.value = false;
  }, 300);
};

const copyText = async () => {
  copyingText.value = true;
  await copyToClipboard(String(props.text));
  setTimeout(() => {
    copyingText.value = false;
    closeTooltip();
  }, 1500);
};
</script>

<template>
  <div @mouseleave="closeTooltip" class="relative">
    <div
      @mouseenter="openTooltip"
      class="grid cursor-pointer space-x-1 text-shade-800"
      style="grid-template-columns: auto minmax(auto, 1fr)"
    >
      <component v-if="iconImg" :is="iconImg" class="h-4 w-4" />
      <div v-show="!collapseText" class="truncate">
        {{ text }}
      </div>
    </div>
    <transition name="fade">
      <div class="tooltip" v-show="showTooltip" @mouseenter="openTooltip">
        <template v-if="copyingText">
          <CheckCircleIcon class="h-4 w-4" />
          <span>Copied!</span>
        </template>
        <template v-else>
          <span class="max-w-32 break-words" :class="{ 'min-w-[100px]': icon === 'phone' }">
            {{ text }}
          </span>
          <CopySvg class="h-4 w-4 cursor-pointer" @click="copyText" />
        </template>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.tooltip {
  @apply absolute left-1/2 z-50 -translate-x-1/2;
  @apply mt-1.5 flex max-w-64 items-center space-x-2 rounded-md bg-shade-880 px-2 py-0.5 text-sm text-tint-0;
  &::before {
    content: '';
    @apply absolute -top-4 left-1/2 -translate-x-1/2 border-8 border-solid border-transparent border-b-shade-880;
  }
}
</style>
