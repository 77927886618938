const fallbackCopyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const copyToClipboard = async (text: string): Promise<void> => {
  try {
    if (!navigator.clipboard) throw new Error('navigator.clipboard is not available');
    await navigator.clipboard.writeText(text);
  } catch {
    fallbackCopyTextToClipboard(text);
  }
};
