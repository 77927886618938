import { defineStore } from 'pinia';
import type { SelectedFilter } from './types/selected-filter.type';
import type { SearchGatedUserProfile } from './types/search-gated-user-profile.type';
import { clone } from '@/utils/objects/clone.util';
import type { DefaultPagination } from './types/default-pagination.type';
import { computed, ref } from 'vue';
import { defaultSearchQuery } from './consts/default-seach-query.const';
import { defaultPagination } from './consts/default-pagination.const';
import { arrayToRecord } from '@/utils/objects/array-to-record.util';

export const useSearchStore = defineStore('sourcing-search', () => {
  const candidates = ref<SearchGatedUserProfile[]>([]);
  const serverItems = ref<number>();
  const totalPages = ref<number>();
  const scrollLevel = ref<number>(0);
  const searchQuery = ref<typeof defaultSearchQuery>(clone(defaultSearchQuery));
  const selectedFilters = ref<SelectedFilter[]>([]);
  const selectedFiltersId = ref<number>();
  const locationSearchInput = ref<string>('');
  const pagination = ref<DefaultPagination>(clone(defaultPagination));
  const candidateIds = ref<number[]>([]);
  const selectedFiltersRecord = computed(() => arrayToRecord(selectedFilters.value, 'type'));

  return {
    candidateIds,
    candidates,
    locationSearchInput,
    pagination,
    scrollLevel,
    searchQuery,
    selectedFilters,
    selectedFiltersId,
    selectedFiltersRecord,
    serverItems,
    totalPages,
  };
});
