import { useAtsConnectApi } from '@/composables/useApi';
import type {
  SearchSyncedApplicationsInput,
  SearchSyncedApplicationsOutput,
} from './ats-sync-application.type';
import SearchUtils from '@/utils/search-utils';
import type { SyncedJobOutput } from './synced-job.type';
import { InternalError } from '@/core/shared/errors/internal.error';

export class AtsConnectPersistence {
  public async syncJobApplicant(proUserProfileId: number, jobId: number): Promise<void> {
    const url = `/synced-application/user-profile/${proUserProfileId}/job/${jobId}/manual-sync-to-source`;

    const { error, statusCode } = await useAtsConnectApi(url).post();

    if (error.value) {
      throw new InternalError('Failed to sync job applicant', {
        error: error.value,
        status: statusCode.value,
        data: { proUserProfileId, jobId },
      });
    }
  }

  public async searchSyncedJobApplicants(
    params: SearchSyncedApplicationsInput,
  ): Promise<SearchSyncedApplicationsOutput> {
    const baseUrl = `/synced-application/employer/${params.employerId}`;
    const search = SearchUtils.convertSearchObjectToSearchUrl(params.search || {});
    const url = `${baseUrl}?${search}`;
    const { data, error, statusCode } = await useAtsConnectApi(url)
      .get()
      .json<SearchSyncedApplicationsOutput>();

    if (error.value) {
      throw new InternalError('Failed to search synced job applicants', {
        error: error.value,
        data: params,
        status: statusCode.value,
      });
    }

    return data.value ?? { results: [] };
  }

  public async getSyncedJobByProjectId(projectId: number): Promise<SyncedJobOutput | null> {
    const url = `/synced-job/project/${projectId}`;
    const { data, error, statusCode } = await useAtsConnectApi(url).get().json<SyncedJobOutput>();

    if (error.value) {
      throw new InternalError('Failed to get synced job by project id', {
        error: error.value,
        status: statusCode.value,
        data: { projectId },
      });
    }

    return data.value;
  }
}
