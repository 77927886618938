import type {
  Employer,
  EmployerWithRelations,
  UserProfile,
  UserProfileWithRelations,
} from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Me } from './types/me.type';

export const useMeStore = defineStore(
  'me',
  () => {
    const userProfile = ref<Me>();
    const employerUsers = ref<UserProfileWithRelations<'userAuthGroup'>[]>([]);
    const employer =
      ref<
        EmployerWithRelations<
          'assignee' | 'employerWatcherMaps' | 'subscription' | 'employerIndustries'
        >
      >();
    const selectedEmployerIds = ref<Record<UserProfile['id'], Employer['id']>>({});

    function clear() {
      userProfile.value = undefined;
      employer.value = undefined;
      employerUsers.value = [];
      selectedEmployerIds.value = {};
    }

    return {
      employer,
      employerUsers,
      selectedEmployerIds,
      userProfile,
      clear,
    };
  },
  { persist: true },
);
