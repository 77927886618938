import { useDialogStore } from './dialog.store';

export class DialogService {
  private store = useDialogStore();

  public get isCreateProjectDialogOpen(): boolean {
    return this.store.isCreateProjectDialogOpen;
  }

  public set isCreateProjectDialogOpen(value: boolean) {
    this.store.isCreateProjectDialogOpen = value;
  }

  public get projectDescriptionOverlayProps(): {
    isOpen: boolean;
    projectId?: number;
    jobId?: number;
  } {
    return this.store.projectDescriptionOverlayProps;
  }

  public set projectDescriptionOverlayProps(value: {
    isOpen: boolean;
    projectId?: number;
    jobId?: number;
  }) {
    this.store.projectDescriptionOverlayProps = value;
  }
}
