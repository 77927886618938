<script setup lang="ts">
import ArchiveSvg from '@/assets/svg/archive.svg?component';
import { computed } from 'vue';

const props = defineProps<{
  light?: boolean;
  mini?: boolean;
}>();

const isMiniVersion = computed(() => props.mini);
const isLight = computed(() => props.light);
const bgColor = computed(() => (isLight.value ? 'bg-tint-40' : 'bg-shade-800'));
const textColor = computed(() => (isLight.value ? 'text-shade-800' : 'text-shade-900'));
const strokeColor = computed(() => (isLight.value ? 'stroke-white' : 'text-shade-900'));
</script>

<template>
  <div :class="`rounded ${bgColor} ${textColor} p-1 text-[10px] font-bold leading-[10px]`">
    <span v-show="!isMiniVersion" class="font-sans"> ARCHIVED </span>
    <ArchiveSvg v-show="isMiniVersion" :class="`${strokeColor}`" />
  </div>
</template>
