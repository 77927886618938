import type { UserProfile } from '@factoryfixinc/ats-interfaces';

export type JobVersionHistory = {
  id: number;
  projectId: number;
  authorId?: number | undefined;
  suggestedByCopilot: boolean;
  reposted: boolean;
  snapshot?: string | undefined;
  author?: UserProfile | undefined;
  createTs: string;
};

export enum JobVersionViewStatus {
  NORMAL_MODE = 'NORMAL',
  VIEW_MODE = 'VIEW',
  EDIT_MODE = 'EDIT',
}

export enum JobVersionAuthorType {
  USER,
  COPILOT,
  SUGGESTED_COPILOT,
  ATS,
}
