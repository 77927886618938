export type ProScore = {
  jobApplicationId: number;
  score?: number;
  summary?: string;
  version?: 'v1' | 'v2';
  criteriaAssessment?: CriteriaAssessment;
};

export type CriteriaAssessment = {
  relevantExperience: {
    assessment: string;
    color: CriteriaColor;
  };
  jobHoppingRisk: {
    assessment: string;
    color: CriteriaColor;
  };
  payExpectations: {
    assessment: string;
    color: CriteriaColor;
  };
  shiftExpectations: {
    assessment: string;
    color: CriteriaColor;
  };
  commuteAbility: {
    assessment: string;
    color: CriteriaColor;
  };
  redFlagsOrConcerningPatterns: {
    assessment: string;
  };
};

export type CriteriaColor = 'Red' | 'Yellow' | 'Green';

export enum CriteriaColorEnum {
  RED = 'Red',
  YELLOW = 'Yellow',
  GREEN = 'Green',
}
