import { EmployerFeatureService } from '../employer-feature/employer-feature.service';
import MeService from '../me/me.service';
import TrackingService from '../tracking/tracking.service';
import { ACTIVE_SUBSCRIPTION_STATUSES } from './consts/active-subscription-statuses.const';
import { SubscriptionPersistenceV2 } from './subscription-v2.persistence';
import { useSubscriptionV2Store } from './subscription-v2.store';
import { SubscriptionPersistence } from './subscription.persistence';
import { useSubscriptionStore } from './subscription.store';
import type { ATSEmployerSubscription, Subscription } from '@factoryfixinc/ats-interfaces';
import {
  ATSProvider,
  ATSSubscriptionStatus,
  SubscriptionStatus,
} from '@factoryfixinc/ats-interfaces';

export default class SubscriptionService {
  private store = useSubscriptionStore();
  private storeV2 = useSubscriptionV2Store();
  private persistence = new SubscriptionPersistence();
  private persistenceV2 = new SubscriptionPersistenceV2();
  private meService = new MeService();
  private trackingService = new TrackingService();
  private employerFeatureService = new EmployerFeatureService();

  public get useV2(): boolean {
    return this.employerFeatureService.hasSubscriptionV2Enabled;
  }

  public get billingPortalUrl(): string {
    return this.useV2 ? this.storeV2.portalSessionUrl ?? '' : '';
  }

  public get subscription(): ATSEmployerSubscription | Subscription | undefined {
    return this.useV2 ? this.storeV2.subscription : this.store.subscription;
  }

  public get isMaxioSubscription(): boolean {
    return this.useV2 ? false : this.store.subscription?.provider === ATSProvider.MAXIO;
  }

  public get isSubscriptionFreePlan(): boolean {
    return this.useV2
      ? this.storeV2.subscription?.providerSubscriptionStatus === 'in_trial'
      : this.store.subscription?.status === ATSSubscriptionStatus.TRIAL;
  }

  public get isGatedSubscription(): boolean {
    return this.useV2 ? true : this.store.talentSearchGating?.isGated ?? false;
  }

  public get talentSearchCredits(): number {
    return this.useV2
      ? this.storeV2.subscription?.components.TS_UNLOCK.limit ?? 0
      : this.store.subscription?.talentSearchCredits ?? 0;
  }

  public get usedTalentSearchCredits(): number {
    return this.useV2
      ? this.storeV2.subscription?.components.TS_UNLOCK.used ?? 0
      : this.store.talentSearchGating?.usedCredits ?? 0;
  }

  public get jobSlots(): number {
    return this.useV2
      ? this.storeV2.subscription?.components.COPILOT_SLOT.limit ?? 0
      : this.store.subscription?.jobSlotQuantity ?? 0;
  }

  public get usedJobSlots(): number {
    return this.useV2
      ? this.storeV2.subscription?.components.COPILOT_SLOT.used ?? 0
      : this.store.jobsCount;
  }

  public get isEmployerSubscribed(): boolean {
    let isSubscribed = false;

    if (this.useV2) {
      if (this.storeV2.subscription?.status === SubscriptionStatus.ACTIVE) {
        isSubscribed = true;
      }
    } else {
      if (this.store.subscription?.status) {
        isSubscribed = ACTIVE_SUBSCRIPTION_STATUSES.includes(this.store.subscription.status);
      }
    }

    return isSubscribed;
  }

  public updateCopilotsUsedLocalValue(addValue: number): void {
    if (this.useV2) {
      if (this.storeV2.subscription) {
        this.storeV2.subscription.components.COPILOT_SLOT.used += addValue;
      }
    } else {
      this.store.jobsCount += addValue;
    }
  }

  public shouldDisplayTrialModal(params: { unlockCount: number }): boolean {
    const { unlockCount } = params;
    const isTrial = this.isSubscriptionFreePlan;
    const tsCredits = this.talentSearchCredits;
    const usedTsCredits = this.usedTalentSearchCredits;
    const totalUsedTsCredits = usedTsCredits + unlockCount;

    return isTrial && totalUsedTsCredits > tsCredits;
  }

  public async fetchSubscription(): Promise<void> {
    const employerId = this.meService.employer?.id;
    if (!employerId) return;

    if (this.useV2) {
      this.storeV2.subscription = await this.persistenceV2.fetchSubscription(employerId);
    } else {
      this.store.subscription = await this.persistence.fetchSubscription(employerId);

      const isEmployerUser = this.meService.isEmployerUser;

      if (this.isEmployerSubscribed && isEmployerUser && employerId) {
        const [talentSearchGating, jobsCount] = await Promise.all([
          this.persistence.fetchTalentSearchGating(employerId),
          this.persistence.fetchJobsCount(employerId),
        ]);

        this.store.talentSearchGating = talentSearchGating;
        this.store.jobsCount = jobsCount;
      }
    }

    this.trackingService.identifyCompany();
  }

  public async fetchPortalSessionUrl(): Promise<void> {
    const employerId = this.meService.employer?.id;
    if (!employerId) return;

    this.storeV2.portalSessionUrl = await this.persistenceV2.fetchPortalSessionUrl(employerId);
  }

  public clearPortalSessionUrl(): void {
    this.storeV2.portalSessionUrl = '';
  }
}
