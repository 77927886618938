<script lang="ts" setup>
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import TextCheckOptions from '@/components/Jobs/CopilotActivation/TextCheckOptions.vue';
import { computed } from 'vue';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';

const copilotActivationService = new CopilotActivationService();
const employerFeatureService = new EmployerFeatureService();

defineProps<{
  isDisabled: boolean;
}>();

const isScheduledUpdatesActive = computed<boolean>(
  () => copilotActivationService.scheduledUpdates === true,
);
const toggleScheduledUpdates = (selected: boolean) => {
  copilotActivationService.scheduledUpdates = selected;
};

const hasScheduledUpdatesFeature = computed<boolean>(
  () => employerFeatureService.hasScheduledUpdatesEnabled,
);
</script>

<template>
  <div
    v-if="hasScheduledUpdatesFeature"
    id="scheduled-updates"
    :class="{ 'pointer-events-none opacity-75': isDisabled }"
  >
    <div class="mb-4 text-lg font-extrabold">
      How would you like Copilot to manage this job's visibility on job boards?
    </div>
    <div class="flex flex-row gap-3">
      <TextCheckOptions
        :is-selected="!isScheduledUpdatesActive"
        @click="toggleScheduledUpdates(false)"
        ><template v-slot:title>Keep original</template>
        Always use your exact job details; rankings will decline over time, reducing inbound
        applicant traffic
      </TextCheckOptions>
      <TextCheckOptions
        :is-selected="isScheduledUpdatesActive"
        badge-content="RECOMMENDED"
        @click="toggleScheduledUpdates(true)"
        ><template v-slot:title>Optimize visibility</template>
        Copilot will adapt the title and description periodically to maintain high rankings and
        steady applicant flow
      </TextCheckOptions>
    </div>
  </div>
</template>
