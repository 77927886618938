import { defineStore } from 'pinia';
import type { JobApplicant, JobApplicantStatusHistory } from '@factoryfixinc/ats-interfaces';
import { ref } from 'vue';
import type { ProUserProfileWithRelations } from './pro-profile';
import type { JobApplicantWithJobTitle } from '@/core/shared/job/job.type';
import type { UserMessageStatus } from '../message/types/get-user-message-status.type';

export const useConversationProProfileStore = defineStore(
  'conversationProProfile',
  () => {
    const selectedPro = ref<ProUserProfileWithRelations | null>();
    const selectedProApplication = ref<JobApplicantWithJobTitle>();
    const selectedProMessageStatus = ref<UserMessageStatus>();
    const selectedProJobApplicantStatusHistory = ref<JobApplicantStatusHistory[]>([]);

    function setSelectedPro(pro: ProUserProfileWithRelations) {
      selectedPro.value = pro;
    }
    function clearSelectedPro() {
      selectedPro.value = null;
    }
    function clearSelectedProApplication() {
      selectedProApplication.value = undefined;
    }
    function selectJobApplication(id: JobApplicant['id']) {
      selectedProApplication.value =
        selectedPro.value?.jobApplicants?.find((a: JobApplicantWithJobTitle) => a.id == id) ||
        undefined;
    }

    function setSelectedProMessageStatus(status: UserMessageStatus) {
      selectedProMessageStatus.value = status;
    }

    function patchApplicationStatus(
      applicationId: JobApplicant['id'],
      newJobApplicant: JobApplicant,
    ) {
      if (selectedPro.value) {
        const { jobApplicants } = selectedPro.value;
        const application = jobApplicants?.find(
          (app: JobApplicantWithJobTitle) => app.id === applicationId,
        );
        if (application) {
          application.status = newJobApplicant.status;
          application.rejectionReason = newJobApplicant.rejectionReason;
          application.updateTs = newJobApplicant.updateTs;
        }
      }
    }
    return {
      selectedPro,
      selectedProApplication,
      selectedProMessageStatus,
      selectedProJobApplicantStatusHistory,
      setSelectedPro,
      clearSelectedPro,
      clearSelectedProApplication,
      patchApplicationStatus,
      selectJobApplication,
      setSelectedProMessageStatus,
    };
  },
  {
    persist: false,
  },
);
