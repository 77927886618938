<script setup lang="ts">
import { RouterView } from 'vue-router';
import { computed } from 'vue';

import Snackbar from '@/components/Shared/Snackbar/Snackbar.vue';

import SubscriptionService from '@/core/shared/subscription/subscription.service';
import UnsubscribedGate from '@/components/Shared/Gates/UnsubscribedGate/UnsubscribedGate.vue';

const subscriptionService = new SubscriptionService();
const isEmployerSubscribed = computed(() => subscriptionService.isEmployerSubscribed);
</script>

<template>
  <!-- Main Router View -->
  <v-main>
    <!-- Subscribed users -->
    <template v-if="isEmployerSubscribed">
      <Snackbar />
      <RouterView />
    </template>

    <!-- Unsubscribed users -->
    <template v-else>
      <UnsubscribedGate />
    </template>
  </v-main>
</template>
