<script setup lang="ts">
import CopilotPlainSvg from '@/assets/svg/copilot-plain.svg';
const innerProps = defineProps<{
  class?: string;
}>();
</script>

<template>
  <v-tooltip location="bottom" content-class="rounded-tooltip tooltip-top-arrow">
    <template #activator="{ props }">
      <div
        v-bind="props"
        :class="innerProps.class"
        class="flex h-6 items-center rounded border-highlight-600 bg-gradient-to-r from-copilot-500/10 to-highlight-600/10 px-1 py-0.5"
      >
        <img :src="CopilotPlainSvg" class="mr-0.5" alt="copilot" />
        <span class="text-[10px] font-bold text-highlight-600">Outreach</span>
      </div>
    </template>
    <div class="text-center text-xs">
      This candidate was sourced <br />
      through Copilot Active Outreach
    </div>
  </v-tooltip>
</template>

<style scoped></style>
