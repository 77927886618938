import '@/assets/scss/vuetify/main.scss';
import { type ThemeDefinition, createVuetify } from 'vuetify';
import tailwindConfig from '../../tailwind.config';

const colorKeys = Object.keys(tailwindConfig.theme.colors);
const colors: Record<string, string> = {};

colorKeys.forEach((key) => {
  const colorValue = tailwindConfig.theme.colors[key];

  if (typeof colorValue === 'string') {
    if (colorValue === 'transparent') {
      colors[key] = 'rgba(0, 0, 0, 0)';
      return;
    }
    colors[key] = colorValue;
    return;
  }

  Object.keys(colorValue).forEach((shade) => {
    const color = `${key}-${shade}`;
    const value = colorValue[shade];
    colors[color] = value;
  });
});

const screenKeys = Object.keys(tailwindConfig.theme.screens);
const screens: Record<string, number> = {};

screenKeys.forEach((key) => {
  const value = tailwindConfig.theme.screens[key];
  screens[key] = Number(value.replace('px', ''));
});

const defaultTheme: ThemeDefinition = {
  dark: false,
  colors: colors,
  variables: {
    'border-color': colors['shade-805'],
    'border-opacity': 0.13,
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'defaultTheme',
    themes: { defaultTheme },
  },
  display: {
    mobileBreakpoint: 'lg',
    thresholds: screens,
  },
  // defaults: useDefaults(),
});
