import { useAtsConversationApi } from '@/composables/useApi';
import SearchUtils from '@/utils/search-utils';
import type {
  ConversationIndex,
  ConversationIndexHeaders,
  FetchConversationIndexByApplicantIdInput,
  FetchConversationIndexesInput,
} from './conversation-index.type';

export class ConversationIndexPersistence {
  public async fetchConversationIndexes(
    params: FetchConversationIndexesInput,
  ): Promise<ConversationIndex[]> {
    const searchUrl = SearchUtils.convertSearchObjectToSearchUrl(params.params);
    const url = `conversation/employer/${params.employerId}/headers?${searchUrl}`;
    const { data, error } = await useAtsConversationApi(url).get().json<ConversationIndexHeaders>();

    if (error.value) {
      throw new Error(error.value);
    }

    return data.value?.headers || [];
  }

  public async fetchConversationIndexByApplicantId(
    params: FetchConversationIndexByApplicantIdInput,
  ): Promise<ConversationIndex> {
    const url = `conversation/employer/${params.employerId}/application/${params.applicationId}`;
    const { data, error } = await useAtsConversationApi(url).get().json<ConversationIndex>();

    if (error.value || !data.value) {
      throw new Error(error.value);
    }

    return data.value;
  }

  public async fetchConversationIndexesByProData(params: {
    employerId: number;
    proData: string;
  }): Promise<{
    count: number;
    headers: ConversationIndex[];
  }> {
    const query = new URLSearchParams({
      proData: params.proData,
    }).toString();

    const url = `/conversation/employer/${params.employerId}/conversation-headers?${query}`;
    const { data, error } = await useAtsConversationApi(url).get().json<{
      count: number;
      headers: ConversationIndex[];
    }>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('No conversation indexes found');
    }

    return data.value;
  }
}
