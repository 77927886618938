import { InternalError } from '@/core/shared/errors/internal.error';
import type { JobVersionHistory } from './types/job-version-history.type';
import { useAtsProjectsApi } from '@/composables/useApi';

export class VersionsPersistence {
  public async getJobVersionsByProjectId(
    projectId: number,
    employerId: number,
  ): Promise<JobVersionHistory[]> {
    const url = `/employer/${employerId}/project/${projectId}/job-version-history/headers`;
    const { data, error, statusCode } = await useAtsProjectsApi(url)
      .get()
      .json<JobVersionHistory[]>();

    if (error.value) {
      throw new InternalError('Could not load Job Versions', {
        status: statusCode.value,
        error: error.value,
        data: { projectId, employerId },
      });
    }

    return data.value ?? [];
  }
  public async getJobVersionById(
    projectId: number,
    employerId: number,
    jobVersionHistoryId: number,
  ): Promise<JobVersionHistory | undefined> {
    const url = `/employer/${employerId}/project/${projectId}/job-version-history/${jobVersionHistoryId}`;

    const { data, error, statusCode } = await useAtsProjectsApi(url)
      .get()
      .json<JobVersionHistory>();

    if (statusCode.value === 404) {
      return undefined;
    }
    if (error.value) {
      throw new InternalError('Could not get Job Version by id', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, jobVersionHistoryId },
      });
    }

    return data.value ?? undefined;
  }
}
