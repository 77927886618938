import type { ProEducationCertification, UserProfile } from '@factoryfixinc/ats-interfaces';
import dayjs from 'dayjs';

export function getProSortedEducation(
  educationList: ProEducationCertification[],
): ProEducationCertification[] {
  // make a copy since we can't do an in-place sort of a vuex state item
  const educationListCopy = JSON.parse(JSON.stringify(educationList));

  return educationListCopy.sort(
    (prev: ProEducationCertification, next: ProEducationCertification) => {
      const a = dayjs(prev.dateIssued, 'YYYY-MM').valueOf();
      const b = dayjs(next.dateIssued, 'YYYY-MM').valueOf();

      return b - a;
    },
  );
}

export function getNameFor(userProfile: Partial<UserProfile>): string {
  const unknownString = 'Unknown';
  if (userProfile) {
    if (!userProfile.nameFirst) {
      // Don't have first name
      if (!userProfile.nameLast) {
        if (!userProfile.id) {
          return unknownString;
        } else {
          return `${unknownString} (${userProfile.id})`;
        }
      } else {
        return userProfile.nameLast;
      }
    } else {
      // Have a first name
      // eslint-disable-next-line no-lonely-if
      if (!userProfile.nameLast) {
        return userProfile.nameFirst;
      } else {
        // Has everything, add a space!
        return `${userProfile.nameFirst} ${userProfile.nameLast}`;
      }
    }
  } else {
    return unknownString;
  }
}
