<script setup lang="ts">
import { onUnmounted } from 'vue';
import { ref } from 'vue';
import { onMounted } from 'vue';

const props = defineProps<{
  minElementWidth: number;
  maxElementWidth: number;
  elementOffsetWidth: number;
  element: HTMLElement | null;
}>();

const emit = defineEmits<{
  (e: 'change:elementWidth', width: number): void;
}>();

const handle = ref<HTMLElement | null>(null);
const isResizing = ref(false);

function onMouseMove(e: MouseEvent) {
  if (!isResizing.value || !props.element) {
    return;
  }

  const elementOffsetParent = props.element.offsetParent as HTMLElement;
  const containerOffsetLeft = elementOffsetParent.offsetLeft;
  const pointerRelativeXpos = e.clientX - containerOffsetLeft - props.elementOffsetWidth;

  if (pointerRelativeXpos > props.minElementWidth && pointerRelativeXpos < props.maxElementWidth) {
    emit('change:elementWidth', pointerRelativeXpos);
  }
}

function onMouseUp() {
  isResizing.value = false;
  document.body.style.cursor = 'default';
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
}

function setupResizeListeners() {
  if (!handle.value) {
    return;
  }

  handle.value.addEventListener('mousedown', () => {
    isResizing.value = true;
    document.body.style.cursor = 'ew-resize';
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  });
}

onMounted(() => {
  setupResizeListeners();
});

onUnmounted(() => {
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
});
</script>

<template>
  <div
    v-bind="$attrs"
    ref="handle"
    class="handle w-[1px] bg-tint-60 hover:cursor-ew-resize"
    :class="{ selected: isResizing }"
  />
</template>

<style scoped>
.handle {
  position: relative;
}

.handle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.handle:hover::after,
.handle.selected::after {
  @apply bg-highlight-300;
}
</style>
