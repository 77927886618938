import { useEmployerDashboardStore } from './employer-dashboard.store';
import { EmployerDashboardPersistence } from './employer-dashboard.persistence';
import type { EmployerDashboardEmployer } from './interfaces/employer-dashboard-employer.interface';
import { ErrorService } from '../shared/errors/error.service';
import type { EmployerDashboard } from './interfaces/employer-dashboard.interface';

export default class EmployerDashboardService {
  private store = useEmployerDashboardStore();
  private persistence = new EmployerDashboardPersistence();

  public set employer(employer: EmployerDashboardEmployer | null) {
    this.store.employer = employer;
  }

  public get employer(): EmployerDashboardEmployer | null {
    return this.store.employer;
  }

  public set employerDashboards(employerDashboards: EmployerDashboard[]) {
    this.store.employerDashboards = employerDashboards;
  }

  public get employerDashboards(): EmployerDashboard[] {
    return this.store.employerDashboards;
  }

  public async getEmployerDashboardEmployer(
    employerId: number,
  ): Promise<EmployerDashboardEmployer | null> {
    let employer = this.store.employer;
    try {
      const myEmployerId = `${employerId}`;
      employer = await this.persistence.getOrCreateEmployerData(myEmployerId);
    } catch (error) {
      employer = null;
      ErrorService.captureException(error);
    }

    this.employer = employer;
    return employer;
  }

  public async getAllEmployerDashboards(): Promise<EmployerDashboard[]> {
    let employerDashboards: EmployerDashboard[] = [];

    try {
      employerDashboards = await this.persistence.getAllEmployerDashboards();
    } catch (error) {
      employerDashboards = [];
      ErrorService.captureException(error);
    }

    this.employerDashboards = employerDashboards;
    return employerDashboards;
  }

  public async generateEmbedJWT(employerDashboardId: string, employerId: number): Promise<string> {
    return this.persistence.generateEmbedJWT({
      employerId: `${employerId}`,
      employerDashboardId,
    });
  }
}
