import { defineStore } from 'pinia';
import { ref } from 'vue';
import { type JobVersionHistory, JobVersionViewStatus } from './types/job-version-history.type';

export const useJobVersionsStore = defineStore(
  'jobVersions',
  () => {
    // Loading
    const isLoadingVersions = ref<boolean>(false);
    const isLoadingJobVersion = ref<boolean>(false);

    //Data
    const jobVersions = ref<JobVersionHistory[]>([]);
    const currentJobVersion = ref<JobVersionHistory | null>(null);
    const currentJobVersionId = ref<number | null>(null);
    const currentJobVersionViewStatus = ref<JobVersionViewStatus>(JobVersionViewStatus.NORMAL_MODE);

    return {
      isLoadingVersions,
      isLoadingJobVersion,
      jobVersions,
      currentJobVersion,
      currentJobVersionId,
      currentJobVersionViewStatus,
    };
  },
  {
    persist: false,
  },
);
