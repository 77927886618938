<script setup lang="ts">
import lottie, { type AnimationItem } from 'lottie-web';

import { computed, ref, watch } from 'vue';
import type { VueElement } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import JobDescriptionAnimation from '@/assets/json/job-description-animation.json';

const copilotActivationService = new CopilotActivationService();

const animation = ref<AnimationItem | null>(null);

const isGeneratingJobDescription = computed(() => {
  return copilotActivationService.isGeneratingJobDescription;
});

const animationRef = ref<VueElement | null>(null);

watch(isGeneratingJobDescription, (newValue) => {
  const loader = animationRef.value as VueElement;

  if (newValue && loader) {
    loadAnimation(loader);
  } else {
    animation.value?.stop();
    animation.value?.destroy();
  }
});

function loadAnimation(loader: VueElement) {
  animation.value = lottie.loadAnimation({
    container: loader,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: JobDescriptionAnimation,
    // The rendererSettings object configures how the animation is displayed
    // 'preserveAspectRatio: xMinYMid meet' ensures the animation maintains its aspect ratio,
    // is centered vertically (YMid), and starts at the left horizontally (xMin)
    rendererSettings: {
      preserveAspectRatio: 'xMinYMid meet',
    },
  });
}
</script>

<template>
  <div
    v-show="isGeneratingJobDescription"
    class="w-[calc(100%-4px) absolute left-0.5 top-[50px] z-10 flex h-[422px] items-center bg-white"
  >
    <div ref="animationRef" class="h-10 overflow-hidden" />
  </div>
</template>
