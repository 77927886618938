<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';

import { type ConversationIndex } from '@/core/conversations/conversation-index/conversation-index.type';

const props = defineProps<{
  conversationIndex: ConversationIndex;
}>();

const parsedConversationIndex = computed(() => {
  /**
   * Get only the first 3 applications and then the rest show them as +{count} more
   */
  let applicationIndexes = props.conversationIndex.applicationIndexes ?? [];
  const totalApplicationCount = applicationIndexes.length;
  applicationIndexes = applicationIndexes.slice(0, 3);

  // '+{count} more' calculation
  const additionalApplicationCount = totalApplicationCount - applicationIndexes.length;
  let additionalApplicationCountString = '';
  if (additionalApplicationCount > 0) {
    additionalApplicationCountString = `+${additionalApplicationCount} more`;
  }

  /**
   * If lastTs:
   * - Between last two weeks - green color | month day || if between 1 day => x hours ago
   * - Longer than two weeks - grey color | month day
   * - Before current year  - grey color | year month day
   */
  const date = dayjs(props.conversationIndex.lastMessageSentTs);
  const now = dayjs();

  const isWithinOneDay = now.diff(date, 'hour') <= 24;
  const isWithinTwoWeeks = now.diff(date, 'day') <= 14;
  const isWithinCurrentYear = now.year() === date.year();
  let timeAgoString = '';
  let timeAgoBgColor = '#DEFAEA';
  let timeAgoTextColor = '#1B7A45';

  if (isWithinCurrentYear) {
    if (isWithinTwoWeeks) {
      if (isWithinOneDay) {
        // Within one day
        timeAgoString = `${now.diff(date, 'hour')} hours ago`;
      } else {
        // Between last two weeks
        timeAgoString = date.format('MMMM DD');
      }
    } else {
      // Longer than two weeks
      timeAgoString = date.format('MMMM DD');
      timeAgoBgColor = '#E2E8F0';
      timeAgoTextColor = '#64748B';
    }
  } else {
    // Before current year
    timeAgoString = date.format('YYYY MMMM DD');
    timeAgoBgColor = '#E2E8F0';
    timeAgoTextColor = '#64748B';
  }

  const data = {
    proName: props.conversationIndex.proName ?? '',
    proEmail: props.conversationIndex.proEmail ?? '',
    proPhoneNumber: props.conversationIndex.proPhoneNumber ?? '',
    proId: props.conversationIndex.proId ?? '',
    lastMessageText: props.conversationIndex.lastMessageText ?? '',
    lastProJobTitle: props.conversationIndex.lastProJobTitle ?? '',
    lastProEmployerName: props.conversationIndex.lastProEmployerName ?? '',
    additionalApplicationCountString,
    lastMessageSentTs: timeAgoString,
    timeAgoBgColor,
    timeAgoTextColor,
  };

  return data;
});
</script>

<template>
  <div class="grid grid-cols-8 gap-8 px-4 py-4">
    <!-- Pro Info -->
    <div class="col-span-4 font-sans text-sm font-normal leading-[18px] text-shade-800">
      <p class="mb-1.5 truncate font-bold leading-[21px] text-shade-900">
        {{ parsedConversationIndex.proName }}
      </p>
      <p class="mb-1.5 truncate">{{ parsedConversationIndex.proEmail }}</p>
      <p class="mb-1.5 truncate">{{ parsedConversationIndex.proPhoneNumber }}</p>
    </div>

    <!-- Recent Work Experience -->
    <div class="col-span-2 font-sans text-sm font-normal leading-[18px] text-shade-800">
      <p class="mb-1.5 truncate">{{ parsedConversationIndex.lastProJobTitle }}</p>
      <p class="truncate">{{ parsedConversationIndex.lastProEmployerName }}</p>
    </div>

    <!-- Last Active -->
    <div class="col-span-2 text-right text-sm font-bold leading-[21px]">
      <span
        class="box-border inline-block h-[29px] rounded-[20px] px-2 py-1"
        :style="{
          backgroundColor: parsedConversationIndex.timeAgoBgColor,
          color: parsedConversationIndex.timeAgoTextColor,
        }"
      >
        {{ parsedConversationIndex.lastMessageSentTs }}
      </span>
    </div>
  </div>
</template>
