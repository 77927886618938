import { AtsConnectPersistence } from './ats-connect.persistence';
import type {
  SearchSyncedApplications,
  SearchSyncedApplicationsOutput,
} from './ats-sync-application.type';
import MeService from '@/core/shared/me/me.service';
import type { SyncedJobOutput } from './synced-job.type';

export default class AtsConnectService {
  private persistence = new AtsConnectPersistence();
  private meService = new MeService();

  public async syncJobApplicant(proUserProfileId: number, jobId: number): Promise<void> {
    await this.persistence.syncJobApplicant(proUserProfileId, jobId);
  }

  public async searchSyncedJobApplicants(
    params: SearchSyncedApplications,
  ): Promise<SearchSyncedApplicationsOutput> {
    const result = await this.persistence.searchSyncedJobApplicants({
      employerId: this.meService.employer?.id as number,
      search: params,
    });

    return result;
  }

  public async getSyncedJobByProjectId(projectId: number): Promise<SyncedJobOutput | null> {
    return this.persistence.getSyncedJobByProjectId(projectId);
  }
}
