<script setup lang="ts">
const props = defineProps<{
  halfStarLeft: string;
  halfStarRight: string;
  bgLeft: string;
  bgRight: string;
  width: number;
  height: number;
}>();
</script>

<template>
  <div :class="[`h-[${height}px]`, `w-[${width}px]`]">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="4" :fill="props.bgRight" />
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H8V16H4C1.79086 16 0 14.2091 0 12V4Z"
        :fill="props.bgLeft"
      />
      <path
        d="M7.14339 4.62367C7.53205 3.97785 8.46834 3.97785 8.857 4.62367L9.36171 5.46232C9.50134 5.69434 9.72909 5.8598 9.99289 5.9209L10.9465 6.14175C11.6808 6.31182 11.9701 7.20229 11.476 7.7715L10.8344 8.51066C10.6568 8.71515 10.5699 8.98288 10.5933 9.25266L10.6779 10.2278C10.7431 10.9787 9.98559 11.5291 9.29155 11.235L8.39029 10.8532C8.14095 10.7476 7.85944 10.7476 7.61011 10.8532L6.70884 11.235C6.01481 11.5291 5.25733 10.9787 5.3225 10.2278L5.40713 9.25266C5.43054 8.98288 5.34355 8.71515 5.16604 8.51066L4.5244 7.7715C4.03029 7.20229 4.31962 6.31182 5.05393 6.14175L6.0075 5.9209C6.2713 5.8598 6.49905 5.69434 6.63868 5.46232L7.14339 4.62367Z"
        :fill="props.halfStarRight"
      />
      <mask
        id="mask0_6024_5196"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="8"
        height="8"
      >
        <path
          d="M7.14339 4.62367C7.53205 3.97785 8.46834 3.97785 8.857 4.62367L9.36171 5.46232C9.50134 5.69434 9.72909 5.8598 9.99289 5.9209L10.9465 6.14175C11.6808 6.31182 11.9701 7.20229 11.476 7.7715L10.8344 8.51066C10.6568 8.71515 10.5699 8.98288 10.5933 9.25266L10.6779 10.2278C10.7431 10.9787 9.98559 11.5291 9.29155 11.235L8.39029 10.8532C8.14095 10.7476 7.85944 10.7476 7.61011 10.8532L6.70884 11.235C6.01481 11.5291 5.25733 10.9787 5.3225 10.2278L5.40713 9.25266C5.43054 8.98288 5.34355 8.71515 5.16604 8.51066L4.5244 7.7715C4.03029 7.20229 4.31962 6.31182 5.05393 6.14175L6.0075 5.9209C6.2713 5.8598 6.49905 5.69434 6.63868 5.46232L7.14339 4.62367Z"
          fill="#1B603A"
        />
      </mask>
      <g mask="url(#mask0_6024_5196)">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H8V16H4C1.79086 16 0 14.2091 0 12V4Z"
          :fill="props.halfStarLeft"
        />
      </g>
    </svg>
  </div>
</template>
