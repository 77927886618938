import { SearchPersistence } from '@/core/shared/search/search.persistence';
import type { PlacePrediction } from '@/core/shared/search/types/place-prediction.type';

export class SearchService {
  private persistence = new SearchPersistence();

  public async getAddressPredictions(address: string): Promise<PlacePrediction[]> {
    return this.persistence.getAddressPredictions(address);
  }
}
