import type { JobTitle } from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useJobTitleStore = defineStore(
  'job-title',
  () => {
    const jobTitles = ref<JobTitle[]>([]);

    return {
      jobTitles,
    };
  },
  { persist: true },
);
