<script setup lang="ts">
import { computed } from 'vue';

import { formatPhoneNumber } from '@/utils/strings/phone-number.util';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import ProHeaderTooltip from './ProHeaderTooltip.vue';

const props = defineProps({ windowWidth: Number });

const proProfileService = new ProProfileService();

const startHidingEmailAndPhone = computed(() => Number(props.windowWidth) <= 1100);
const startTruncatingText = computed(() => Number(props.windowWidth) <= 1280);
const userProfile = computed(() => proProfileService.selectedProProfile);
const proLocation = computed(() => {
  if (!(userProfile.value?.city && userProfile.value?.state))
    return userProfile.value?.pro?.postalCode || '';
  return `${userProfile.value.city}, ${userProfile.value.state}`;
});
const phoneNumber = computed(() => formatPhoneNumber(userProfile.value?.phone));
</script>

<template>
  <div class="mt-1.5 flex space-x-2 text-xs">
    <div v-if="userProfile?.displayTitle" class="font-sans text-black">
      {{ userProfile.displayTitle }}
    </div>
    <div v-if="proLocation" class="whitespace-nowrap text-shade-800">
      Located in {{ proLocation }}
    </div>
    <ProHeaderTooltip
      v-if="userProfile?.phone"
      :collapse-text="startHidingEmailAndPhone"
      :truncate-text="startTruncatingText"
      :text="phoneNumber"
      icon="phone"
    />
    <ProHeaderTooltip
      v-if="userProfile?.email"
      :collapse-text="startHidingEmailAndPhone"
      :truncate-text="startTruncatingText"
      :text="userProfile.email"
      icon="email"
    />
  </div>
</template>
