<script setup lang="ts">
import type { UserProfile } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';

const props = defineProps<{
  user: Partial<UserProfile>;
}>();

const avatarUrl = computed(() => props.user?.avatarUrl);
const nameFirst = computed(() => props.user?.nameFirst?.[0]);
const nameLast = computed(() => props.user?.nameLast?.[0]);
</script>

<template>
  <div>
    <img
      v-if="avatarUrl"
      width="32"
      height="32"
      :src="avatarUrl"
      alt="avatar"
      class="h-8 w-8 rounded-full"
    />
    <span
      v-else
      class="avatar-text flex h-8 w-8 items-center justify-center rounded-full bg-primary-50"
    >
      <span class="text-xs font-bold text-primary-800">{{ nameFirst }}{{ nameLast }}</span>
    </span>
  </div>
</template>
