import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDrawerStore = defineStore('drawer', () => {
  // open/close states
  const isMobileDrawerOpen = ref(false);
  const isProjectsDrawerOpen = ref<boolean>(true);

  // navigation
  const visitedConversationsTabRouteName = ref<string>('');

  return {
    isMobileDrawerOpen,
    isProjectsDrawerOpen,
    visitedConversationsTabRouteName,
  };
});
