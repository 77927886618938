import { useAtsEmployerApi } from '@/composables/useApi';
import type { EmployerDashboardEmployer } from './interfaces/employer-dashboard-employer.interface';
import type { EmployerDashboard } from './interfaces/employer-dashboard.interface';

export class EmployerDashboardPersistence {
  public async getOrCreateEmployerData(employerId: string): Promise<EmployerDashboardEmployer> {
    const url = `/employer-dashboard/get-or-create-employer`;
    const { data, error } = await useAtsEmployerApi(url)
      .post({
        employerId,
      })
      .json<EmployerDashboardEmployer>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('Error getting or creating employer data');
    }

    return data.value;
  }

  public async getAllEmployerDashboards(): Promise<Array<EmployerDashboard>> {
    const url = `/employer-dashboard/dashboards`;
    const { data, error } = await useAtsEmployerApi(url)
      .get()
      .json<{ dashboards: Array<EmployerDashboard> }>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value || !data.value.dashboards) {
      throw new Error('No employer dashboards found');
    }

    return data.value.dashboards;
  }

  public async generateEmbedJWT(body: {
    employerId: string;
    employerDashboardId: string;
  }): Promise<string> {
    const url = `/employer-dashboard/generate-embed-jwt`;
    const { data, error } = await useAtsEmployerApi(url)
      .post(body)
      .json<{ jwt: string; employerDashboardId: string; employerId: string }>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value || !data.value.jwt) {
      throw new Error('Could not generate embed JWT');
    }

    return data.value.jwt;
  }
}
