export enum SelectedFilterType {
  AROUND_RADIUS = 'AROUND_RADIUS',
  BRAND = 'BRAND',
  JOB_TITLE = 'JOB_TITLE',
  KEYWORD = 'KEYWORD',
  KNOWLEDGE_DISCIPLINE = 'KNOWLEDGE_DISCIPLINE',
  LAST_ACTIVE = 'LAST_ACTIVE',
  LOCATION = 'LOCATION',
  OBJECT_ID = 'OBJECT_ID',
  TECHNOLOGY = 'TECHNOLOGY',
  YEARS_OF_EXPERIENCE = 'YEARS_OF_EXPERIENCE',
}
