import { useDrawerStore } from './drawer.store';

export class DrawerService {
  private store = useDrawerStore();

  public get isMobileDrawerOpen(): boolean {
    return this.store.isMobileDrawerOpen;
  }

  public set isMobileDrawerOpen(value: boolean) {
    this.store.isMobileDrawerOpen = value;
  }

  public get isProjectsDrawerOpen(): boolean {
    return this.store.isProjectsDrawerOpen;
  }

  public set isProjectsDrawerOpen(value: boolean) {
    this.store.isProjectsDrawerOpen = value;
  }

  public get visitedConversationsTabRouteName(): string {
    return this.store.visitedConversationsTabRouteName;
  }

  public set visitedConversationsTabRouteName(value: string) {
    this.store.visitedConversationsTabRouteName = value;
  }

  public toggleProjectsDrawer(): void {
    this.store.isProjectsDrawerOpen = !this.store.isProjectsDrawerOpen;
  }
}
