import { useSubscriptionStore } from '@/core/shared/subscription/subscription.store';
import { useMeStore } from '@/core/shared/me/me.store';
import chmln from '@chamaeleonidae/chmln';

export default (): void => {
  if (import.meta.env.DEV) {
    return;
  }

  const meStore = useMeStore();

  if (!meStore.userProfile) {
    return;
  }

  try {
    chmln.init(import.meta.env.VITE_CHAMELEON_API_KEY, {
      fastUrl: 'https://fast.chameleon.io/',
    });
  } catch (error) {
    console.error(error);
    return;
  }

  const subscriptionStore = useSubscriptionStore();
  const { userProfile, employer } = meStore;

  const userName = `${userProfile.nameFirst} ${userProfile.nameLast}`;
  const subscriptionPlan = `${subscriptionStore?.subscription?.plan || 'N/A'} - ${
    subscriptionStore?.subscription?.planLength || 'N/A'
  }`;
  const employerAssignee = `${employer?.assignee?.nameFirst || 'N/A'} ${
    employer?.assignee?.nameLast || 'N/A'
  }`;

  const userProps = {
    user_role: userProfile.userAuthGroup?.groupMembership || 'N/A',
    uid: userProfile.id,
    email: userProfile.email,
    name: userName,
    subscription_status: subscriptionStore?.subscription?.status || 'N/A',
    live_jobs_count: subscriptionStore.jobsCount || 'N/A',
    company: {
      name: employer?.name,
      uid: employer?.id,
      company_assignee: employerAssignee,
      subscription_status: subscriptionStore?.subscription?.status || 'N/A',
      subscription_plan: subscriptionPlan,
    },
  };

  chmln.identify(userProfile.id, userProps);
};
