import {
  useAtsCandidateDiscoveryApi,
  useAtsSubscriptionApi,
  useFFRestApi,
} from '@/composables/useApi';
import type { TalentSearchGating } from './types/talent-search-gating.type';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import { InternalError } from '@/core/shared/errors/internal.error';
import type { ATSEmployerSubscription } from '@factoryfixinc/ats-interfaces';

export class SubscriptionPersistence {
  async fetchSubscription(employerId: number): Promise<ATSEmployerSubscription | undefined> {
    const { error, data, statusCode } = await useAtsSubscriptionApi(`/subscription/${employerId}`)
      .get()
      .json<ATSEmployerSubscription>();

    if (statusCode.value === HttpStatus.NOT_FOUND || !data.value) {
      return;
    }

    if (error.value) {
      throw new InternalError('Could not load subscription information', {
        error: error.value,
        data: { employerId },
        status: statusCode.value,
      });
    }

    return data.value;
  }

  async fetchTalentSearchGating(employerId: number): Promise<TalentSearchGating> {
    const { error, data, statusCode } = await useAtsCandidateDiscoveryApi(
      `/pro-unlock/employer/${employerId}`,
    )
      .get()
      .json<TalentSearchGating>();

    if (error.value || !data.value) {
      throw new InternalError('Could not load gating information', {
        error: error.value,
        status: statusCode.value,
        data: { employerId },
      });
    }

    return data.value;
  }

  async fetchJobsCount(employerId: number): Promise<number> {
    const query = new URLSearchParams({
      where: JSON.stringify({
        status: ['Live'],
      }),
    }).toString();

    const url = `/employer/${employerId}/job/summary/count?${query}`;
    const { data, error, statusCode } = await useFFRestApi(url).json<{
      count: number;
    }>();

    if (error.value || !data.value) {
      throw new InternalError('Could not load job count', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, query },
      });
    }

    return data.value.count;
  }
}
