import type {
  TaxonomyBrand,
  TaxonomyCategory,
  TaxonomyIndustry,
  TaxonomyKnowledgeDiscipline,
  TaxonomyMachine,
  TaxonomyMachineRelations,
} from '@factoryfixinc/ats-interfaces';

import { useTaxonomyStore } from './taxonomy.store';

import { arrayToRecord } from '@/utils/objects/array-to-record.util';
import { TaxonomyPersistence } from '@/core/shared/taxonomy/taxonomy.persistence';
import type { SuggestedTaxonomyMachine } from './types/suggested-taxonomy-machine.type';
import type { SuggestedTaxonomyKnowledgeDiscipline } from './types/suggested-taxonomy-knowledge-discipline.type';

export default class TaxonomyService {
  private readonly taxonomyPersistence = new TaxonomyPersistence();
  private readonly store = useTaxonomyStore();

  public async reload(): Promise<void> {
    this.store.reset();

    await Promise.all([
      this.fetchIndustries(),
      this.fetchMachines(),
      this.fetchBrands(),
      this.fetchKnowledgeDisciplines(),
      this.fetchCategories(),
    ]);
  }

  public get industries(): TaxonomyIndustry[] {
    return this.store.industries.sort((a, b) => a.title.localeCompare(b.title));
  }

  public get industriesRecord(): Record<number, TaxonomyIndustry> {
    return arrayToRecord(this.store.industries, 'id');
  }

  public get machines(): (TaxonomyMachine & {
    taxonomyMachineTaxonomyBrandMaps: TaxonomyMachineRelations['taxonomyMachineTaxonomyBrandMaps'];
  })[] {
    return this.store.machines;
  }

  public get machinesRecord(): Record<number, TaxonomyMachine> {
    return arrayToRecord(this.store.machines, 'id');
  }

  public get brands(): TaxonomyBrand[] {
    return this.store.brands;
  }

  public get brandsRecord(): Record<number, TaxonomyBrand> {
    return arrayToRecord(this.store.brands, 'id');
  }

  public get knowledgeDisciplines(): TaxonomyKnowledgeDiscipline[] {
    return this.store.knowledgeDisciplines;
  }

  public get knowledgeDisciplinesRecord(): Record<number, TaxonomyKnowledgeDiscipline> {
    return arrayToRecord(this.store.knowledgeDisciplines, 'id');
  }

  public get categories(): TaxonomyCategory[] {
    return this.store.categories;
  }

  public get categoriesRecord(): Record<number, TaxonomyCategory> {
    return arrayToRecord(this.store.categories, 'id');
  }

  public async fetchIndustries(): Promise<TaxonomyIndustry[]> {
    const industries = await this.taxonomyPersistence.fetchIndustries();
    this.store.industries = industries;

    return industries;
  }

  public async fetchMachines(): Promise<
    (TaxonomyMachine & {
      taxonomyMachineTaxonomyBrandMaps: TaxonomyMachineRelations['taxonomyMachineTaxonomyBrandMaps'];
    })[]
  > {
    const machines = await this.taxonomyPersistence.fetchMachines();
    this.store.machines = machines;

    return machines;
  }

  public async fetchBrands(): Promise<TaxonomyBrand[]> {
    const brands = await this.taxonomyPersistence.fetchBrands();
    this.store.brands = brands;

    return brands;
  }

  public async fetchKnowledgeDisciplines(): Promise<TaxonomyKnowledgeDiscipline[]> {
    const knowledgeDisciplines = await this.taxonomyPersistence.fetchKnowledgeDisciplines();
    this.store.knowledgeDisciplines = knowledgeDisciplines;

    return knowledgeDisciplines;
  }

  public async fetchCategories(): Promise<TaxonomyCategory[]> {
    const categories = await this.taxonomyPersistence.fetchCategories();
    this.store.categories = categories;

    return categories;
  }

  public async fetchSuggestedKnowledgeDisciplines(
    jobTitleTaxonomyCategoryId: number,
  ): Promise<SuggestedTaxonomyKnowledgeDiscipline[]> {
    return this.taxonomyPersistence.getSuggestedKnowledgeDisciplinesForJobTitle(
      jobTitleTaxonomyCategoryId,
    );
  }

  public async fetchSuggestedTechnologiesMachines(
    jobTitleId: number,
  ): Promise<SuggestedTaxonomyMachine[]> {
    return this.taxonomyPersistence.getSuggestedTechnologiesMachinesForJobTitle(jobTitleId);
  }
}
