import type { CriteriaColor } from '../application-score/application-score.type';

export interface ConversationMessage {
  id: number;
  conversationId: number;
  content: string;
  createdAt: Date;
  avatarUrl?: string;
  sender?: {
    id?: number | null;
    name: string;
  };
  metadata?: ScoreBlurbMetadata | ApplicationStatusMetadata;
}

export interface ConversationMessagesResponse {
  messages: ConversationMessage[];
}

export enum ConversationMessageType {
  SENT_BY_BOT = 'SENT_BY_BOT',
  SENT_BY_PRO = 'SENT_BY_PRO',
  SENT_BY_EMPLOYER = 'SENT_BY_EMPLOYER',
}

export interface NewMessageDto {
  message: string;
}

export interface CreatedMessage {
  id: number;
  conversationId: number;
  senderId: number;
  content: string;
  createTs: Date;
  updateTs: Date;
}

export interface GetMessagePagination {
  page?: number;
  itemsPerPage?: number;
}

export type GetMessagesByConversationResponse = {
  messages: MessagesByConversation[];
};

export type MessagesByConversation = {
  id: number;
  content: string;
  createTs: Date;
  sender: {
    senderId: number | null;
    name?: string;
  };
};

export enum MessageMetadataType {
  SCORE_BLURB = 'SCORE_BLURB',
  APPLICATION_STATUS = 'APPLICATION_STATUS',
}

export interface ScoreBlurbMetadata {
  type: MessageMetadataType.SCORE_BLURB;
  score: string;
  chips?: {
    title: string;
    text: string;
    color: CriteriaColor;
  }[];
  applicationId: number;
  scoreVersion?: string;
  jobTitle: string;
}

export interface ApplicationStatusMetadata {
  type: MessageMetadataType.APPLICATION_STATUS;
}
