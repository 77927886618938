<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import AlertTriangleIcon from '@/assets/svg/jobs/alert-triangle.svg?component';
import CopilotLightningIcon from '@/assets/svg/jobs/copilot-lightning.svg?component';
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import { EXTRA_CHARGES_WARNING_TEXT } from '@/core/jobs/copilot-activation/types/activation-messages.type';
import ConfirmCopilotDisabledChange from './ConfirmCopilotDisabledChange.vue';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  isCopilotActivated: {
    type: Boolean,
    default: false,
  },
  isLoadingCopilot: {
    type: Boolean,
    default: false,
  },
  isJobVersionEditMode: {
    type: Boolean,
    default: false,
  },
  isAtsSynced: {
    type: Boolean,
    default: false,
  },
  isShowingAdditionalChargeNotice: {
    type: Boolean,
    default: false,
  },
});

const activationService = new CopilotActivationService();
const openCopilotDisabledDialog = ref<boolean>(false);

const emit = defineEmits<{
  (e: 'updateJob', payload: { activate: boolean; byPassModal: boolean }): void;
  (e: 'disableJobVersionEditing'): void;
  (e: 'disableCopilot'): void;
}>();

const isDisabled = computed<boolean>(() => {
  return props.isLoading || props.isLoadingCopilot;
});
const isSaveAndExitDisabled = computed<boolean>(() => {
  return isDisabled.value || (props.isAtsSynced && !props.isCopilotActivated);
});

function handleSaveClick() {
  submitJobUpdate();
  openCopilotDisabledDialog.value = false;
}

function handleDisableCopilotClick() {
  if (activationService.requireCopilotActivationChangeConfirmation) {
    openCopilotDisabledDialog.value = true;
    return;
  }
  openCopilotDisabledDialog.value = false;
  submitDisableCopilot();
}

function submitDisableCopilot() {
  emit('disableCopilot');
  openCopilotDisabledDialog.value = false;
}

function submitJobUpdate() {
  emit('updateJob', { activate: false, byPassModal: true });
}

function handleCopilotDisableCanceled() {
  openCopilotDisabledDialog.value = false;
}
const showTooltip = computed<boolean>(() => {
  return props.isAtsSynced && !props.isCopilotActivated;
});
const isTooltipActive = ref<boolean>(false);

// Enable pointer events on the button even if it is disabled.
// This ensures the tooltip is shown on hover and hidden on mouse out.
const pointerEventsStyle = computed(() =>
  showTooltip.value ? 'pointer-events: all !important' : '',
);

// `showTooltip` determines if the tooltip visibility should be controlled by the model
// `isTooltipActive` represents the current visibility state of the tooltip
watch(isTooltipActive, () => {
  // If the `showTooltip` is `true` then we let the model show or hide the tooltip
  if (showTooltip.value) {
    return;
  }
  // if not, we force the tooltip to always be hidden by setting the model to false
  isTooltipActive.value = false;
});
</script>

<template>
  <div class="sticky-button-container flex items-center justify-between">
    <div>
      <v-tooltip
        location="top"
        content-class="rounded-tooltip tooltip-bottom-arrow"
        v-model="isTooltipActive"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            :ripple="false"
            :loading="isLoading"
            :disabled="isSaveAndExitDisabled"
            class="modal-button-secondary mr-4"
            :style="pointerEventsStyle"
            variant="flat"
            @click.prevent="handleSaveClick"
            >Save & exit</v-btn
          ></template
        >
        <span class="text-xs">ATS Synced jobs can only be edited when Copilot is active.</span>
      </v-tooltip>
      <v-btn
        v-if="isJobVersionEditMode"
        :ripple="false"
        class="modal-button-text ml-4"
        variant="flat"
        @click.prevent="emit('disableJobVersionEditing')"
        >Cancel</v-btn
      >
    </div>
    <v-tooltip location="top" content-class="rounded-tooltip tooltip-bottom-arrow">
      <template #activator="{ props }">
        <div
          v-if="isShowingAdditionalChargeNotice"
          v-bind="props"
          class="relative overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-caution-50 p-3.5 pl-12 font-sans text-sm font-normal leading-5 text-caution-800"
        >
          <div class="absolute left-4 top-3">
            <AlertTriangleIcon />
          </div>
          <div class="inline" v-html="EXTRA_CHARGES_WARNING_TEXT"></div>
        </div>
      </template>
      <span class="text-xs" v-html="EXTRA_CHARGES_WARNING_TEXT"></span>
    </v-tooltip>
    <div class="ml-4 flex items-center">
      <v-btn
        v-if="!isCopilotActivated"
        :ripple="false"
        :loading="isLoadingCopilot"
        :disabled="isDisabled"
        class="copilot-activation-button normal"
        variant="flat"
        @click.prevent="emit('updateJob', { activate: true, byPassModal: false })"
      >
        <span>
          <CopilotLightningIcon class="mr-2" />
        </span>
        <span class="text-sm font-semibold leading-5 text-tint-20"
          >Activate Copilot - Post job</span
        >
      </v-btn>
      <v-btn
        v-else
        :ripple="false"
        :loading="isLoadingCopilot"
        :disabled="isDisabled"
        class="modal-button-primary"
        variant="flat"
        @click.prevent="handleDisableCopilotClick"
      >
        <span>
          <CopilotLightningIcon class="mr-2" />
        </span>
        <span class="text-sm font-semibold leading-5 text-tint-20"
          >Deactivate Copilot - Remove job</span
        >
      </v-btn>
    </div>

    <ConfirmCopilotDisabledChange
      @save="submitDisableCopilot"
      @cancel="handleCopilotDisableCanceled"
      :is-open="openCopilotDisabledDialog"
      :is-loading="isLoading"
      v-if="openCopilotDisabledDialog"
    />
  </div>
</template>
<style></style>
