import type { SelectedFilter } from '../types/selected-filter.type';

export const DEFAULT_AROUND_RADIUS = 30;

export const defaultSearchQuery = {
  location: '',
  locationPredictions: [] as unknown[],
  aroundRadius: DEFAULT_AROUND_RADIUS,
  query: '',
  selectedFilters: [] as SelectedFilter[],
};
