<script setup lang="ts">
import lottie, { type AnimationItem } from 'lottie-web';

import { computed, ref, watch } from 'vue';
import type { VueElement } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import ScreenerQuestionsAnimation from '@/assets/json/screener-questions-animation.json';

const copilotActivationService = new CopilotActivationService();

const animation = ref<AnimationItem | null>(null);

const isGeneratingScreenerQuestions = computed(() => {
  return copilotActivationService.isGeneratingScreenerQuestions;
});

const animationRef = ref<VueElement | null>(null);

watch(isGeneratingScreenerQuestions, (newValue) => {
  const loader = animationRef.value as VueElement;

  if (newValue && loader) {
    loadAnimation(loader);
  } else {
    animation.value?.stop();
    animation.value?.destroy();
  }
});

function loadAnimation(loader: VueElement) {
  animation.value = lottie.loadAnimation({
    container: loader,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: ScreenerQuestionsAnimation,
    // The rendererSettings object configures how the animation is displayed
    // 'preserveAspectRatio: xMinYMid meet' ensures the animation maintains its aspect ratio,
    // is centered vertically (YMid), and starts at the left horizontally (xMin)
    rendererSettings: {
      preserveAspectRatio: 'xMinYMid meet',
    },
  });
}
</script>

<template>
  <div ref="animationRef" class="h-10 overflow-hidden bg-white" />
</template>
