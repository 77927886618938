import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { ViewedCandidate } from './types/viewed-candidate';
import { computed } from 'vue';

export const useViewedCandidateStore = defineStore('sourcing-viewed-candidate', () => {
  const viewedCandidates = ref<ViewedCandidate[]>([]);

  const viewedCandidatesRecord = computed(() => {
    return viewedCandidates.value.reduce(
      (prev, viewedCandidate) => {
        prev[viewedCandidate.candidateId] = viewedCandidate.updateTs;
        return prev;
      },
      {} as Record<number, number>,
    );
  });

  return {
    viewedCandidates,
    viewedCandidatesRecord,
  };
});
