<script setup lang="ts">
import ProjectOverlayNoDescriptionSvg from '@/assets/svg/suitcase-circle.svg?component';

const emit = defineEmits<{
  (e: 'click:add-job-details'): void;
}>();
</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center gap-4 pt-16">
      <div class="flex h-32 w-32 items-center justify-center rounded-full bg-tint-20">
        <ProjectOverlayNoDescriptionSvg />
      </div>

      <div class="flex max-w-[221px] flex-col items-center gap-2">
        <span class="text-sm font-bold not-italic leading-5 text-shade-800">
          No job description available
        </span>

        <span class="text-center text-sm font-normal not-italic leading-5 text-shade-800">
          Add job details to activate Copilot and have it source quality candidates for you
        </span>

        <button
          class="px-4 py-2 text-sm font-semibold text-highlight-500"
          @click="emit('click:add-job-details')"
        >
          Add job details
        </button>
      </div>
    </div>
  </div>
</template>
