export const isAValidShift = (
  value: string | null,
  message: string = 'Required',
): boolean | string => {
  if (value === null) {
    return true;
  }

  const parsedValue = parseInt(value);
  if (parsedValue !== 0 && !parsedValue) {
    return message;
  }

  return [-1, 0, 1, 2, 3, 4].includes(parsedValue) ? true : message;
};
