import { useApiGateway } from '@/composables/useApi';
import {
  EventType,
  type TrackingActionDataInput,
  type TrackingCompanyUserGroupInput,
  type TrackingEmployerUserIdentityInput,
  type TrackingEventInput,
  type TrackingVisitedPageInput,
} from './tracking.type';
import type { Me } from '../me/types/me.type';
import type { Employer } from '@factoryfixinc/ats-interfaces';
import { useMeStore } from '../me/me.store';
import type { TrackingActionName } from './tracking-actions';

import { useSubscriptionStore } from '../subscription/subscription.store';

export default class TrackingService {
  private meStore = useMeStore();
  private subscriptionStore = useSubscriptionStore();
  private static staticInstance: TrackingService;

  static get instance(): TrackingService {
    if (TrackingService.staticInstance) {
      return TrackingService.staticInstance;
    }
    TrackingService.staticInstance = new TrackingService();
    return TrackingService.staticInstance;
  }

  public async identifyEmployerUser(user?: Me, employer?: Employer): Promise<void> {
    if (!user || !employer) {
      return;
    }
    this.executeSilently(() =>
      this.saveIdentifyEmployerUser(user.id, {
        email: user.email,
        firstName: user.nameFirst,
        lastName: user.nameLast,
        name: `${user.nameFirst} ${user.nameLast}`,
        companyId: this.meStore.employer?.id,
        companyName: employer.name,
        createdAt: new Date(user.createTs),
        userType: 'Employer',
        employerRole: [user.userAuthGroup?.groupMembership as string] || [],
        phone: user.phone ?? '',
        jobTitle: user.displayTitle,
      }),
    );
  }

  public async identifyCompany(): Promise<void> {
    const userId = this.meStore.userProfile?.id;
    const company = this.meStore.employer;
    const companySubscription = this.subscriptionStore.subscription;

    if (userId && company) {
      this.executeSilently(() =>
        this.saveCompany(userId, {
          companyId: company?.id,
          companyName: company?.name ?? '',
          groupId: company?.id,
          accountCreatedAt: company?.createTs,
          address: {
            city: company?.city,
            country: company?.country,
            postalCode: company?.postalCode,
            state: company?.state,
            street: company?.street1,
          },
          contractRenewal: companySubscription?.endDate
            ? new Date(companySubscription?.endDate).toISOString()
            : undefined,
          contractStart: companySubscription?.startDate
            ? new Date(companySubscription?.startDate).toISOString()
            : undefined,
          subscriptionPlan: companySubscription?.plan,
          trialStart: companySubscription?.maxio?.trialStartedAt,
          trialEnd: companySubscription?.maxio?.trialEndedAt,
          paymentStatus: companySubscription?.maxio?.state,
          phone: company.phone ?? undefined,
          website: company.website ?? '',
          // paymentStatus: '',
          // phone: '',
          // industry: '',
        }),
      );
    }
  }

  public async registerPageVisit(page: Omit<TrackingVisitedPageInput, 'app'>): Promise<void> {
    const userId = this.meStore.userProfile?.id;
    if (userId && page) {
      await this.executeSilently(() =>
        this.saveVisitedPage(userId, {
          ...page,
          app: 'ats-ui',
        }),
      );
    }
  }

  static async trackAction(
    actionName: TrackingActionName | string,
    properties: Record<string, unknown> = {},
  ): Promise<void> {
    await this.staticInstance.trackActionEvent(actionName, {
      ...properties,
    });
  }

  public async trackActionEvent(
    actionName: string,
    properties: Record<string, unknown> = {},
  ): Promise<void> {
    const userId = this.meStore.userProfile?.id;
    const companyId = this.meStore.employer?.id || null;
    if (userId && actionName) {
      const propertiesWithCompany = {
        ...properties,
        company_id: companyId,
      };
      await this.executeSilently(() =>
        this.saveActionEvent(userId, {
          actionName,
          properties: propertiesWithCompany,
        }),
      );
    }
  }

  private async postEvent(payload: unknown): Promise<void> {
    await useApiGateway('/tracking/event', { keepalive: true }).post(payload);
  }

  private async saveCompany(userId: number, data: TrackingCompanyUserGroupInput): Promise<void> {
    const payload = {
      userId,
      data,
      type: EventType.UserGroup,
    };
    await this.postEvent(payload);
  }

  private async saveActionEvent(userId: number, data: TrackingActionDataInput): Promise<void> {
    const payload = {
      userId,
      data,
      type: EventType.Action,
    };
    await this.postEvent(payload);
  }

  private async saveVisitedPage(userId: number, data: TrackingVisitedPageInput): Promise<void> {
    const payload = {
      userId,
      data,
      type: EventType.VisitedPage,
    };
    await this.postEvent(payload);
  }

  private async saveIdentifyEmployerUser(
    userId: number,
    user: TrackingEmployerUserIdentityInput,
  ): Promise<void> {
    const payload: TrackingEventInput = {
      userId,
      data: user,
      type: EventType.UserIdentity,
    };
    await this.postEvent(payload);
  }

  /** Prevents the tracking/analytics calls from breaking the invoking execution if an error is thrown.
   * Also immediately returns instead of locking the promise by the network call
   */
  private executeSilently(procedure: () => Promise<unknown>): Promise<void> {
    return new Promise<void>(async (resolve) => {
      try {
        await procedure();
      } catch (e) {
        await true;
      } finally {
        resolve();
      }
    });
  }
}
