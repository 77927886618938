/**
 * Calculates exponential backoff with "full" jitter.
 * Inspired by https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
 */
export function getExponentialBackoffWithJitter(
  backoffBase: number,
  retriesAttempted: number,
  maximumBackoff?: number,
): number {
  const exponentialFactor = 2 ** retriesAttempted;
  const exponentialBackoff = backoffBase * exponentialFactor;
  const cappedBackoff = maximumBackoff
    ? Math.min(exponentialBackoff, maximumBackoff)
    : exponentialBackoff;
  const jitteredBackoff = Math.random() * cappedBackoff;
  return jitteredBackoff;
}
