export const isValidMaxPayRate = (
  payRateMin: number | null,
  payRateMax: number | null,
): boolean | string => {
  if (payRateMin === null || payRateMax === null) {
    return false;
  }

  return Number(payRateMin) > Number(payRateMax)
    ? 'Max pay rate must be greater than minimum pay rate'
    : true;
};
