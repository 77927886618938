import Hotjar from '@hotjar/browser';
import MeService from '@/core/shared/me/me.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import type { ATSEmployerSubscription } from '@factoryfixinc/ats-interfaces';

export default (): void => {
  if (import.meta.env.DEV) {
    return;
  }

  try {
    const hotjarSiteId = import.meta.env.VITE_HOTJAR_SITE_ID;
    const hotjarVersion = 6;

    Hotjar.init(hotjarSiteId, hotjarVersion);

    const meService = new MeService();
    const subscriptionService = new SubscriptionService();
    const userProfile = meService.userProfile;

    if (!userProfile) {
      return;
    }

    const identifyInformation: Record<string, string> = {};
    identifyInformation.First_Name = userProfile.nameFirst ?? 'N/A';
    identifyInformation.Last_Name = userProfile.nameLast ?? 'N/A';
    identifyInformation.Sign_Up_Date = userProfile.createTs;
    identifyInformation.FF_User_ID = userProfile.id.toString();

    const employer = meService.employer;

    if (employer) {
      identifyInformation.Company_Name = employer.name ?? 'N/A';
      identifyInformation.FF_Company_ID = employer.id.toString();
      identifyInformation.Assignee_Name =
        employer.assignee?.nameFirst + ' ' + employer.assignee?.nameLast || 'N/A';
    }

    if (!subscriptionService.useV2) {
      const subscription = subscriptionService.subscription as ATSEmployerSubscription;

      identifyInformation.Upcoming_Invoice_Amount = subscription.nextBillingAmount.toString();
      identifyInformation.Pricing_Plan =
        subscription.plan + ' - ' + subscription.planLength ?? 'N/A';
      identifyInformation.Job_Slots = subscription.jobSlotQuantity?.toString() ?? 'N/A';
      identifyInformation.Subscription_Status = subscription.status ?? 'N/A';
    }

    Hotjar.identify(userProfile.id.toString(), identifyInformation);
  } catch (error) {
    ErrorService.captureException(error);
  }
};
