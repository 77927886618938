import { ErrorService } from '@/core/shared/errors/error.service';
import MeService from '@/core/shared/me/me.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { isValidEmail } from '@/utils/forms/is-valid-email.form';
import { type Ref, computed, ref } from 'vue';

export function useInviteMember(): {
  isLoading: Ref<boolean>;
  emailInput: Ref<string>;
  isValidEmailInput: Ref<boolean>;
  inviteSent: Ref<boolean>;
  onInvite: () => Promise<void>;
  resetState: () => void;
} {
  const meService = new MeService();
  const isLoading = ref(false);
  const emailInput = ref('');
  const inviteSent = ref(false);
  const isValidEmailInput = computed(() => isValidEmail(emailInput.value));

  async function onInvite() {
    try {
      isLoading.value = true;

      if (!emailInput.value) return;

      const isAlreadyAMember = meService.isEmployerMember(emailInput.value);

      if (isAlreadyAMember) {
        SnackbarService.caution(
          'This email is already in use. Please have your teammate log in to access FactoryFix',
        );
        return;
      }

      await meService.inviteEmployerMember(emailInput.value);
      SnackbarService.success('Invitation sent');
      inviteSent.value = true;
    } catch (error) {
      ErrorService.captureException(error);
      SnackbarService.critical('Something went wrong. Please try again');
      inviteSent.value = false;
    } finally {
      isLoading.value = false;
    }
  }

  function resetState() {
    emailInput.value = '';
    inviteSent.value = false;
  }

  return {
    isLoading,
    emailInput,
    isValidEmailInput,
    inviteSent,
    onInvite,
    resetState,
  };
}
