import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';

type InternalErrorPayload = {
  status?: number | null;
  data?: Record<string, unknown>;
  error?: unknown;
};

type MessageInformation = {
  name: string;
  status: number;
  message: string;
  errorMessage?: string;
  data?: unknown;
};

export class InternalError extends Error {
  public name: string;
  public message: string;
  public status: number;
  public errorMessage?: string;
  public data?: Record<string, unknown>;

  constructor(message: string, payload: InternalErrorPayload = {}) {
    const { error, data, status } = payload;
    super(message);

    // https://stackoverflow.com/a/48342359
    Object.setPrototypeOf(this, new.target.prototype);

    this.name = error instanceof Error ? error.name : InternalError.name;
    this.status = status ?? HttpStatus.INTERNAL_SERVER_ERROR;
    this.data = data;
    this.errorMessage = error instanceof Error ? error.message : undefined;
    this.message = message;

    if (error instanceof InternalError) {
      this.name = error.name;
      this.errorMessage = error.errorMessage;
      this.status = error.status;

      if (typeof this.data === 'object' && typeof data === 'object') {
        this.data = { ...this.data, ...data };
      }

      if (typeof this.data === 'object' && typeof error.data === 'object') {
        this.data = { ...this.data, ...error.data };
      }
    }

    const messageInformation: MessageInformation = {
      name: this.name,
      status: this.status,
      message: this.message,
      errorMessage: this.errorMessage,
    };

    if (typeof this.data === 'object') {
      messageInformation.data = this.data;
    }

    this.message = `${prettifyObject(messageInformation)}`;
  }

  public toString(): string {
    return this.message;
  }
}

function prettifyObject(obj: unknown): string {
  return JSON.stringify(obj, null, 2);
}
