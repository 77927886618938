import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDialogStore = defineStore('dialog', () => {
  const isCreateProjectDialogOpen = ref(false);

  // Project Description Dialog
  const projectDescriptionOverlayProps = ref<{
    isOpen: boolean;
    projectId?: number;
    jobId?: number;
  }>({
    isOpen: false,
    projectId: undefined,
    jobId: undefined,
  });

  return {
    isCreateProjectDialogOpen,
    projectDescriptionOverlayProps,
  };
});
