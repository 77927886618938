import type { Project, UserProfile } from '@factoryfixinc/ats-interfaces';
import type { AssigneeWatcherAvatarData } from '../types/assignee-watcher-avatar-data.type';

export const getAssigneeAvatarData = (assignee: Project['assignee']): AssigneeWatcherAvatarData => {
  return _getAvatarData(assignee);
};

export const getWatcherAvatarData = (
  watchers: Project['watchers'],
): AssigneeWatcherAvatarData[] => {
  return watchers?.map((watcher) => _getAvatarData(watcher)) || [];
};

const _getAvatarData = (
  user: Pick<UserProfile, 'id' | 'nameFirst' | 'nameLast'> | null,
): AssigneeWatcherAvatarData => {
  let avatarString = '';
  let fullName = '';
  const id = user?.id || 0;

  if (user) {
    /**
     * Use 2 letters for the avatar.
     * If we have both first and last name, use the first letter of each.
     * If we only have first name, use the first 2 letters of it.
     * If we only have last name, use the first 2 letters of it.
     */
    if (user.nameFirst) {
      fullName += user.nameFirst;
      avatarString += user.nameFirst.charAt(0);

      if (!user.nameLast) {
        avatarString += user.nameFirst.charAt(1);
      } else {
        fullName += ` ${user.nameLast}`;
        avatarString += user.nameLast.charAt(0);
      }
    } else if (user.nameLast) {
      fullName += user.nameLast;
      avatarString += user.nameLast.charAt(0);
      avatarString += user.nameLast.charAt(1);
    }
  }

  return {
    fullName,
    avatarString,
    id,
  };
};
