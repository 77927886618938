<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import { DrawerService } from '@/core/shared/drawer/drawer.service';

const display = useDisplay();
const drawerService = new DrawerService();
const isMobile = computed(() => display.smAndDown.value);

function toggleMobileNavigationDrawer() {
  drawerService.isMobileDrawerOpen = !drawerService.isMobileDrawerOpen;
}
</script>

<template>
  <v-app-bar v-if="isMobile" color="shade-880" flat>
    <!-- FactoryFix Logo -->
    <template #prepend>
      <div class="flex h-12 w-12 items-center justify-center">
        <img src="@/assets/svg/ff-logo.svg" alt="FactoryFix" width="27.43" height="24" />
      </div>
    </template>

    <!-- Mobile Navigation Drawer Toggle -->
    <template #append>
      <div
        class="flex h-12 w-12 cursor-pointer items-center justify-center"
        @click="toggleMobileNavigationDrawer"
      >
        <img src="@/assets/svg/menu.svg" alt="Navigation Drawer Toggle" width="27.43" height="24" />
      </div>
    </template>
  </v-app-bar>
</template>
