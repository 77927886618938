<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'radash';
import ProjectSearchFiltersDialog from '@/components/Shared/Projects/ProjectSearchFiltersDialog.vue';
import FilterLinesSvg from '@/assets/svg/filter-lines-20.svg?component';
import { ProjectStatus } from '@factoryfixinc/ats-interfaces';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import MeService from '@/core/shared/me/me.service';
import ProjectService from '@/core/shared/project/project.service';

const emit = defineEmits<{
  (e: 'change:filters'): void;
}>();

const meService = new MeService();
const projectService = new ProjectService();
const route = useRoute();
const router = useRouter();
const isProjectSearchFiltersDialogOpen = ref<boolean>(false);
const searchQuery = computed(() => route.query);
const filtersCount = computed(() => projectService.filtersCount);

async function setTitleOrReqIdQuery(event: Event) {
  const searchText = event.target as HTMLInputElement;
  const query = { ...searchQuery.value };

  if (searchText.value) {
    query.titleOrReqId = searchText.value;
  } else {
    delete query.titleOrReqId;
  }

  await router.push({ query });
}

function openProjectSearchFiltersDialog() {
  isProjectSearchFiltersDialogOpen.value = true;
}

async function setProjectFilters() {
  const query = { ...searchQuery.value };
  // Title or Req ID
  if (query.titleOrReqId) {
    projectService.titleOrReqId = query.titleOrReqId as string;
  }
  // Copilot
  if (query.copilot) {
    projectService.copilot = query.copilot === 'true' ? true : null;
  }
  // Location
  if (query.location) {
    if (typeof query.location === 'string') {
      projectService.locations = [query.location];
    }

    if (Array.isArray(query.location)) {
      projectService.locations = query.location as string[];
    }
  }
  // Locations
  if (query.locations) {
    if (typeof query.locations === 'string') {
      projectService.locations = [query.locations];
    }

    if (Array.isArray(query.locations)) {
      projectService.locations = query.locations as string[];
    }
  }
  // Status
  if (query.status && query.status === ProjectStatus.LIVE) {
    projectService.status = ProjectStatus.LIVE;
  }
  // Team Ids
  if (query.teamIds) {
    if (typeof query.teamIds === 'string') {
      projectService.teamIds = [Number(query.teamIds)];
    }

    if (Array.isArray(query.teamIds)) {
      projectService.teamIds = query.teamIds.map(Number);
    }

    projectService.didSelectUserInProjectFilters = true;
  } else {
    selectOwnUserOnce();
  }

  emit('change:filters');
}

function selectOwnUserOnce() {
  if (projectService.didSelectUserInProjectFilters) {
    return;
  }

  projectService.didSelectUserInProjectFilters = true;
  const isOwnUserProfileInTeamList = meService.employerUsers?.some(
    (user) => user.id === meService.userProfile?.id,
  );

  if (!isOwnUserProfileInTeamList) {
    return;
  }

  const ownUserProfileId = meService.userProfile?.id as number;
  projectService.teamIds = [ownUserProfileId];
}

const debouncedSetTitleOrReqIdQuery = debounce({ delay: 500 }, setTitleOrReqIdQuery);

watch(searchQuery, async () => {
  await setProjectFilters();
});

onMounted(async () => {
  try {
    await Promise.all([projectService.getEmployerLocations()]);
    await setProjectFilters();
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load filters');
  }
});
</script>

<template>
  <div>
    <!-- Title Input -->
    <div
      class="relative flex h-[40px] items-center rounded-md bg-shade-840 p-2"
      data-test-id="project-filter-section"
    >
      <input
        v-model="projectService.titleOrReqId"
        type="text"
        placeholder="Search by job name or ATS Req ID"
        class="flex-1 bg-shade-840 text-sm focus:outline-none"
        @input="debouncedSetTitleOrReqIdQuery"
        data-test-id="project-search-text-filter"
      />
    </div>

    <!-- Filters Menu Button -->
    <button
      class="mt-2 inline-flex items-center rounded-md border px-2 py-1"
      @click="openProjectSearchFiltersDialog"
    >
      <!-- Label -->
      <div class="text-xs font-semibold leading-4">Filters</div>
      <!-- Icon w/ Count -->
      <div class="relative ml-1 cursor-pointer">
        <FilterLinesSvg class="stroke-white" />
        <div
          v-if="filtersCount"
          class="absolute right-[-4px] top-[-2px] h-[12px] w-[12px] rounded-full bg-highlight-600"
        >
          <div class="flex items-center justify-center">
            <span class="m-[-1.5px] text-2xs font-bold">{{ filtersCount }}</span>
          </div>
        </div>
      </div>

      <ProjectSearchFiltersDialog v-model="isProjectSearchFiltersDialogOpen" />
    </button>
  </div>
</template>
