<script setup lang="ts">
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import SettingsBar from '@/components/Shared/LayoutBar/SettingsBar/SettingsBar.vue';
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const display = useDisplay();
const drawerService = new DrawerService();

const isDesktop = computed(() => display.mdAndUp.value);
</script>
<template>
  <div>
    <v-navigation-drawer
      width="256"
      v-model:model-value="drawerService.isProjectsDrawerOpen"
      color="shade-860"
      :permanent="isDesktop"
    >
      <SettingsBar />
    </v-navigation-drawer>
    <RouterView />
  </div>
</template>
