<script setup lang="ts">
import FileSvg from '@/assets/svg/file-20.svg?component';
import AssigneeWatchersList from '@/components/Shared/Projects/ProjectCard/ProjectAssigneeWatcherList.vue';
import ProjectArchivedStatus from '@/components/Shared/Projects/ProjectCard/ProjectArchivedStatus.vue';
import ProjectAtsSyncStatus from '@/components/Shared/Projects/ProjectCard/ProjectAtsSyncStatus.vue';
import CountInformation from '@/components/Shared/Projects/ProjectCard/CountInformation.vue';
import NotificationBubble from '@/components/Shared/Notification/NotificationBubble.vue';
import ProjectActionsMenu from '@/components/Shared/Projects/ProjectCard/ProjectActionsMenu.vue';
import CopilotToggle from '@/components/Shared/Input/CopilotToggle/CopilotToggle.vue';

import {
  MAXIMUN_ALLOWED_SCREENER_QUESTIONS,
  MINIMUM_REQUIRED_SCREENER_QUESTIONS,
} from '@/core/jobs/copilot-activation/types/local-screener-question.type';

import {
  JobApplicantStatus,
  type JobWithRelations,
  type Project,
  ProjectStatus,
  type ProjectWithRelations,
  ScreeningAction,
  ScreeningType,
} from '@factoryfixinc/ats-interfaces';
import { computed, ref } from 'vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import ProjectService from '@/core/shared/project/project.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import JobService from '@/core/shared/job/job.service';
import { useRouter } from 'vue-router';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import ConfirmCopilotDisabledChange from '@/components/Jobs/CopilotActivation/ConfirmCopilotDisabledChange.vue';
import { EXTRA_CHARGES_WARNING_TEXT_SNACKBAR } from '@/core/jobs/copilot-activation/types/activation-messages.type';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName, TrackingCopilotSource } from '@/core/shared/tracking/tracking-actions';

const props = defineProps<{
  project: ProjectWithRelations<'candidates'>;
  isCardSelected: boolean;
}>();

const emits = defineEmits<{
  (e: 'select-project', project: Project): void;
}>();

const router = useRouter();

const dialogService = new DialogService();
const projectService = new ProjectService();
const jobService = new JobService();
const subscriptionService = new SubscriptionService();

const isActionsMenuOpen = ref(false);

const isArchived = computed(() => props.project.status === ProjectStatus.ARCHIVED);
const isCopilotActivated = computed(() => props.project.copilot);
const isAtsSynced = computed(() => props.project.remoteJobId !== null);
const atsLastSyncedTs = computed(() => `${props.project.lastSuccessfulSyncTs ?? ''}`);

const projectCandidatesCount = computed(() => props.project.candidates ?? {});

const newCandidatesCount = computed(() =>
  Math.max(projectCandidatesCount.value[JobApplicantStatus.NEW] ?? 0),
);
const engagedCandidatesCount = computed(() =>
  Math.max(projectCandidatesCount.value[JobApplicantStatus.CLIENT] ?? 0),
);
const reviewCandidatesCount = computed(() =>
  Math.max(projectCandidatesCount.value[JobApplicantStatus.REVIEW] ?? 0),
);

function selectProject() {
  emits('select-project', props.project);
}

function openProjectDescriptionOverlay() {
  dialogService.projectDescriptionOverlayProps = {
    isOpen: true,
    projectId: props.project.id,
    jobId: props.project.jobId,
  };
}

const isLoadingCopilot = ref(false);

async function onActivateCopilot() {
  // Check if all job slots are used before updating the project Copilot status.
  // This ensures that the reactivity does not interfere with the slot usage check.
  const localUsedAllSlots = hasUsedAllJobSlots.value;
  if (!props.project || isLoadingCopilot.value) return;

  try {
    isLoadingCopilot.value = true;
    const job = await jobService.getByIdWithRelations(props.project.jobId, ['screenerQuestions']);

    if (job && isValidJob(job)) {
      await projectService.updateProjectCopilotStatus(props.project.id, true);

      // If the company has used all job slots, then show a caution alert. with Undo action
      if (localUsedAllSlots) {
        SnackbarService.showSnackbar({
          text: EXTRA_CHARGES_WARNING_TEXT_SNACKBAR,
          variant: 'caution-alt',
          timeout: 5000,
          showUndo: true,
          undoAction: onDisableCopilot,
        });
      } else {
        SnackbarService.success('Copilot was successfully activated');
      }
      TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_COMPLETED, {
        source: trackActionSource.value,
        project_id: props.project.id,
        job_id: props.project.jobId,
      });
    } else {
      TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
        source: trackActionSource.value,
        project_id: props.project.id,
        job_id: props.project.jobId,
      });
      SnackbarService.critical('Please complete the job details before activating Copilot');
      await router.push(`/jobs/${props.project.id}/copilot-activation?validate=true`);
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to activate Copilot. Please try again later.');
  } finally {
    isLoadingCopilot.value = false;
  }
}

const openDisableCopilotConfirmationDialog = ref(false);

function requireConfirmationToDisableCopilot(job: JobWithRelations<'jobStatusHistories'>) {
  const screeningAction = job.screeningAction ?? ScreeningAction.SCREEN;
  if (screeningAction !== ScreeningAction.SCREEN) {
    return false;
  }
  return job.screeningType === ScreeningType.DYNAMIC;
}

async function onDisableCopilot() {
  if (!props.project || isLoadingCopilot.value) return;
  const job = await jobService.getById(props.project.jobId);

  if (job && requireConfirmationToDisableCopilot(job)) {
    openDisableCopilotConfirmationDialog.value = true;
    return;
  }
  submitDisableCopilot();
}
async function submitDisableCopilot() {
  openDisableCopilotConfirmationDialog.value = false;
  try {
    isLoadingCopilot.value = true;
    await projectService.updateProjectCopilotStatus(props.project.id, false);
    TrackingService.trackAction(TrackingActionName.COPILOT_DISABLED, {
      source: trackActionSource.value,
      project_id: props.project.id,
      job_id: props.project.jobId,
    });
    SnackbarService.success('Copilot was successfully deactivated');
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to deactivate Copilot. Please try again later.');
  } finally {
    isLoadingCopilot.value = false;
  }
}

const trimValues = (field: string): boolean =>
  field.toLocaleLowerCase().replace('no title', '').trim().length > 0;

function isValidJob(job: JobWithRelations<'screenerQuestions'>) {
  const areValidQuestions = areValidScreenerQuestions(job);
  if (!areValidQuestions) {
    return false;
  }
  const requiredFields: (keyof JobWithRelations<'screenerQuestions'>)[] = [
    'displayTitle',
    'rawDescription',
    'street1',
    'city',
    'state',
    'postalCode',
    'employerId',
    'jobTitleId',
    'hireType',
    'payRatePeriod',
  ];

  return requiredFields.every((field) => job[field] && trimValues(`${job[field]}`));
}
/* We need to validate if Job is doing Screening Questions or Company URL
 * Usually when its a new Job the screeningAction is null but by default
 * it will be saved as SCREEN on the Copilot Activation form
 */
function areValidScreenerQuestions(job: JobWithRelations<'screenerQuestions'>) {
  const { screeningAction, screeningType, screenerQuestions, employerCareerSiteUrl } = job;

  if (!screeningAction || screeningAction === ScreeningAction.SCREEN) {
    if (screeningType === ScreeningType.DYNAMIC) {
      return true;
    }
    if (!screenerQuestions || screenerQuestions.length === 0) {
      return false;
    }
    const validQuestions = screenerQuestions.filter(
      (question) => question.question.trim().length > 0,
    );

    return (
      validQuestions.length >= MINIMUM_REQUIRED_SCREENER_QUESTIONS &&
      validQuestions.length <= MAXIMUN_ALLOWED_SCREENER_QUESTIONS
    );
  } else if (screeningAction === ScreeningAction.URL && !employerCareerSiteUrl) {
    return false;
  }
  return true;
}

const trackActionSource = ref<TrackingCopilotSource.ACTION_MENU | TrackingCopilotSource.TOGGLE>(
  TrackingCopilotSource.TOGGLE,
);

function toggleCopilot(isActivated: boolean, isActionMenu = false) {
  trackActionSource.value = isActionMenu
    ? TrackingCopilotSource.ACTION_MENU
    : TrackingCopilotSource.TOGGLE;
  if (isActivated) {
    onDisableCopilot();
  } else {
    onActivateCopilot();
  }
}
const hasUsedAllJobSlots = computed(() => {
  return subscriptionService.usedJobSlots >= subscriptionService.jobSlots;
});
</script>

<template>
  <div
    class="project-card linear relative w-full"
    :id="`project-card-filter-job-${project.jobId}`"
    :class="{ 'project-card--selected': isCardSelected }"
    @keydown.exact.enter="selectProject"
  >
    <div>
      <div class="flex justify-between">
        <div class="mb-1 flex items-center">
          <!-- Title -->
          <span
            class="max-w-[225px] overflow-hidden truncate text-ellipsis whitespace-nowrap pr-4 text-[14px] font-bold leading-[21px] text-white"
            >{{ project.title }}
          </span>
        </div>
        <NotificationBubble class="project-card__notification" :count="0" v-if="false" />

        <div class="flex items-center">
          <!-- Job description -->
          <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
            <template #activator="{ props }">
              <FileSvg
                class="mr-1 text-tint-400 hover:!text-white"
                v-bind="props"
                @click="openProjectDescriptionOverlay"
              />
            </template>
            <span class="text-xs">Job description</span>
          </v-tooltip>

          <!-- Job settings -->
          <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
            <template #activator="{ props }">
              <ProjectActionsMenu
                class="project-card__actions"
                v-model="isActionsMenuOpen"
                v-bind="props"
                :project="project"
                @toggle-copilot="toggleCopilot"
                :isLoadingCopilot="isLoadingCopilot"
              />
            </template>
            <span class="text-xs">Job settings</span>
          </v-tooltip>
        </div>
      </div>

      <!-- Location and ID -->
      <div class="mt-0.5 flex text-xs font-normal leading-[10px] text-tint-300">
        <span class="font-medium">{{ project.location }} | </span>&nbsp;#{{ project.jobId }}
      </div>
    </div>
    <div class="project-card-counters mb-4 mt-4 flex items-center">
      <CountInformation title="New" :count="newCandidatesCount" />
      <CountInformation title="Engaged" :count="engagedCandidatesCount" />
      <CountInformation title="Review" :count="reviewCandidatesCount" />
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-between">
        <AssigneeWatchersList
          :assignee="project.assignee"
          :watchers="project.watchers"
          :project="project"
        />
      </div>
      <div>
        <!-- Archived Status -->
        <ProjectArchivedStatus v-if="isArchived" mini />
        <div v-else class="flex w-[100%] justify-start">
          <copilot-toggle
            v-model="isCopilotActivated"
            label="Copilot job status"
            :id="`card-${project.id}`"
            :isDisabled="isLoadingCopilot"
            :isLoading="isLoadingCopilot"
            @toggle-copilot="toggleCopilot"
          ></copilot-toggle>
          <!-- Ats Synced -->
          <ProjectAtsSyncStatus
            class="ml-1 mt-[2px] self-center"
            v-if="isAtsSynced"
            mini
            :last-synced="atsLastSyncedTs"
          />
        </div>
      </div>
    </div>
    <ConfirmCopilotDisabledChange
      @save="submitDisableCopilot"
      @cancel="openDisableCopilotConfirmationDialog = false"
      :is-open="openDisableCopilotConfirmationDialog"
      :is-loading="isLoadingCopilot"
      v-if="openDisableCopilotConfirmationDialog"
    />
  </div>
</template>

<style lang="postcss" scoped>
.project-card {
  @apply relative mb-[7px] flex w-full cursor-pointer flex-col;
  @apply justify-center rounded-md border-l-4 border-shade-860 p-3;
  @apply transition-all duration-150 hover:border-shade-840 hover:bg-shade-840;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 4%;
    left: 4%;
    height: 1px;
    @apply bg-shade-840 transition-all duration-150;
  }

  &.project-card--selected {
    @apply border-highlight-400 bg-shade-840;

    &::after {
      opacity: 0;
    }
  }
}
.project-card:hover {
  .project-card__notification {
    @apply bg-white text-shade-900 !important;
  }
}
.project-card:has(+ .project-card--selected) {
  &::after {
    opacity: 0;
  }
}
/** :last-child is not working for some reason */
.project-card:nth-last-child(-n + 2) {
  &::after {
    opacity: 0;
  }
}
</style>

<style>
/*
 * Must not be scoped since the component is moved to the beginning of the v-app
 * https://vuetifyjs.com/en/api/v-tooltip/#props-content-class
 */
.project-card-tooltip {
  padding: 0px !important;
  border-radius: 6px !important;
  padding: 3px 8px !important;
  background-color: #0f172a !important;
}
</style>
