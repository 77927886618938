<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  label: string;
  id: string;
}>();

const emits = defineEmits<{
  (e: 'toggleCopilot', checked: boolean): void;
}>();

const disabled = computed(() => props.isDisabled || props.isLoading);
</script>
<template>
  <div class="copilot-toggle" :class="{ loading: isLoading }">
    <label class="self-center text-xs" :for="id">{{ label }}</label>
    <input
      type="checkbox"
      class="cp-toggle"
      :disabled="disabled"
      :id="id"
      :checked="modelValue"
      @click.prevent.stop="emits('toggleCopilot', modelValue)"
    />
  </div>
</template>
<style lang="postcss" scoped>
.copilot-toggle {
  --toggle-width: 34px;
  --toggle-height: 20px;
  --handle-size: 17px;
  @apply flex items-center justify-between;

  /* Toggle button */
  .cp-toggle {
    @apply relative m-0 mb-[-8px] ml-2 cursor-pointer appearance-none;
    @apply overflow-hidden border-0 p-0 outline-0;

    /* Body */
    &:after {
      @apply clear-both inline-block rounded border border-shade-800 bg-shade-820;
      content: '';
      width: var(--toggle-width);
      height: var(--toggle-height);
    }

    /* Handle */
    &:before {
      @apply absolute left-[2px] top-[2px] block rounded-[3px] bg-shade-900;
      @apply shadow-[1px_0px_3px_rgba(0,0,0,0.35)];
      content: '';
      width: var(--handle-size);
      height: var(--handle-size);
      background-image: url('@/assets/svg/jobs/copilot-lightning.svg');
      background-size: 100% 100%;
    }
  }
  /* Shift the handle to left on check event */
  .cp-toggle:checked {
    &:before {
      left: calc(var(--toggle-width) - var(--handle-size) - 1px);
      box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.35);
      background: url('@/assets/svg/jobs/copilot-lightning.svg'),
        radial-gradient(90.71% 100% at 50% 100%, #ae35f7 0%, #5f30d1 100%);
      background-size: 100% 100%;
    }
    &:after {
      @apply bg-shade-820;
    }
  }
  .cp-toggle,
  .cp-toggle:before,
  .cp-toggle:after,
  .cp-toggle:checked:before,
  .cp-toggle:checked:after {
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
  }
  input[type='checkbox']:disabled {
    @apply opacity-70;
  }
  &.loading {
    .cp-toggle {
      &:after {
        @apply animate-linear-loading bg-gradient-to-r from-shade-820 via-tint-200 to-shade-820 bg-[length:200%_auto];
      }
      &:before {
        box-shadow: none;
        background: url('@/assets/svg/jobs/copilot-lightning.svg'), rgb(100, 100, 100);
        background-size: 100% 100%;
      }
    }
  }
}
</style>
