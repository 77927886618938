import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLayoutStore = defineStore(
  'layout',
  () => {
    const conversationsPanelWidth = ref<number>(336);

    return {
      conversationsPanelWidth,
    };
  },
  { persist: true },
);
