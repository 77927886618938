import { useFFRestApi } from '@/composables/useApi';
import type { Employer, UserProfile } from '@factoryfixinc/ats-interfaces';
import { useConversationWatcherStore } from './conversation-watcher.store';

export default class ConversationWatcherService {
  private conversationWatcherStore;

  constructor() {
    this.conversationWatcherStore = useConversationWatcherStore();
  }

  public async fetchConversationWatchers(employerId: Employer['id']): Promise<void> {
    const url = `/employer/${employerId}/user-profile`;
    const { data, error } = await useFFRestApi(url).get().json<UserProfile[]>();

    if (error?.value) {
      this.conversationWatcherStore.setWatcherList([]);
      throw new Error(error.value);
    }
    if (data.value) {
      this.conversationWatcherStore.setWatcherList(data.value);
    }
  }

  public async getParticipantById(userId: UserProfile['id']): Promise<UserProfile | undefined> {
    return this.conversationWatcherStore.getWatcherById(userId);
  }

  public getEmployerWatchers(): UserProfile[] {
    return this.conversationWatcherStore.watcherProfileList;
  }
}
