<script setup lang="ts">
import { computed } from 'vue';

import Divider from '@/components/Shared/Divider/Divider.vue';

const props = withDefaults(defineProps<{ title?: string; addDivider?: boolean }>(), {
  addDivider: true,
});

const displayingTitle = computed(() => {
  return props.title;
});
</script>

<template>
  <div>
    <h3 class="mb-4 font-sans text-lg font-extrabold leading-7 text-black" v-if="title">
      {{ displayingTitle }}
    </h3>
    <slot />

    <Divider class="my-10" v-if="addDivider" />
  </div>
</template>
