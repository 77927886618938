<script setup lang="ts"></script>

<template>
  <div class="flex h-full w-full items-center justify-center">
    <!-- Box -->
    <div class="flex items-center">
      <!-- Left Content -->
      <div class="mr-6 flex w-[364px] flex-col">
        <!-- Title -->
        <span class="font-serif text-2xl font-650">Your plan has expired</span>

        <!-- Paragraph -->
        <span class="mt-6 text-base">
          To maintain access to our platform, consider upgrading your plan. If you have any
          questions or need help exploring the best package option, our team is ready to help.
        </span>

        <!-- CTA -->
        <div class="mt-6 inline">
          <a href="mailto:sales@factoryfix.com?subject=Upgrade My Subscription">
            <button
              type="button"
              class="flex h-10 w-40 cursor-pointer items-center justify-center rounded-md border-2 border-shade-880 transition-colors hover:bg-tint-40"
            >
              <span class="font-sans text-sm font-semibold text-shade-880">Chat with our team</span>
            </button>
          </a>
        </div>
      </div>

      <!-- Right Content -->
      <div class="flex">
        <!-- Image -->
        <img
          src="@/assets/png/worker-wearing-safety-vest.png"
          alt="A worker wearing an orange safety vest, hard hat and safety goggles"
          class="w-[170px] object-contain"
        />
      </div>
    </div>
  </div>
</template>
