<script setup lang="ts"></script>

<template>
  <div class="box-border flex h-[79px] flex-wrap rounded-md bg-shade-860 p-3">
    <!-- First line -->
    <div class="linear-loading mb-2 h-[10px] w-7/12 rounded-full"></div>
    <div class="mb-2 h-[10px] w-5/12"></div>
    <!-- Second line -->
    <div class="linear-loading h-2 w-1/4 rounded-full"></div>
    <div class="h-2 w-3/4"></div>
    <!-- Third Line -->
    <div class="linear-loading mt-[10px] h-6 w-6 rounded-full"></div>
  </div>
</template>
