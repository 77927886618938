import { useFFRestApi } from '@/composables/useApi';
import type { Employer, JobApplicant, Pro } from '@factoryfixinc/ats-interfaces';
import type { Note } from './types/pro-note.type';

export default class ProNotesPersistence {
  async fetchProNotes(employerId: Employer['id'], proPreferenceId: Pro['id']): Promise<Note[]> {
    const url = `employer/${employerId}/pro/${proPreferenceId}/note`;
    const { data, error } = await useFFRestApi(url).json<Note[]>();
    if (error.value) throw new Error(error.value);
    if (!data.value) throw new Error('No notes found');
    return data.value;
  }

  async fetchJobApplicantsWithJobById(jobApplicantId: JobApplicant['id']): Promise<JobApplicant> {
    const filter = {
      include: [
        {
          relation: 'job',
          scope: { include: [{ relation: 'jobTitle' }] },
        },
      ],
    };
    const query = new URLSearchParams({ filter: JSON.stringify(filter) });
    const url = `job-applicant/${jobApplicantId}?${query.toString()}`;
    const { data, error } = await useFFRestApi(url).get().json<JobApplicant>();
    if (error.value) throw new Error(error.value);
    if (!data.value) throw new Error('No job applicant found');
    return data.value;
  }

  async postNote(
    employerId: Employer['id'],
    proPreferenceId: Pro['id'],
    description: string,
  ): Promise<Note> {
    const url = `employer/${employerId}/pro/${proPreferenceId}/note`;
    const { data, error } = await useFFRestApi(url)
      .post({
        description,
      })
      .json<Note>();
    if (error.value) throw new Error(error.value);
    if (!data.value) throw new Error('No note found');
    return data.value;
  }

  async deleteNote(noteId: Note['id']): Promise<void> {
    const url = `pro-note/${noteId}`;
    const { error } = await useFFRestApi(url).delete();
    if (error.value) throw new Error(error.value);
  }

  async updateNote(noteId: Note['id'], description: Note['description']): Promise<void> {
    const url = `pro-note/${noteId}`;
    const { error } = await useFFRestApi(url).patch({ description });
    if (error.value) throw new Error(error.value);
  }
}
