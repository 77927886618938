<script lang="ts" setup>
import { JobUtil } from '@/utils/job.util';
import type { Job } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';

const props = defineProps<{
  job: Job;
}>();

const location = computed(() => JobUtil.getLocation(props.job));
const shift = computed(() => JobUtil.getShift(props.job));
const hireType = computed(() => JobUtil.getHireType(props.job));
const payRange = computed(() => JobUtil.getPayRange(props.job));
</script>

<template>
  <div>
    <div class="mb-4 flex-col text-xs">
      <div><span class="font-bold">Location: </span> {{ location }}</div>
      <div><span class="font-bold">Hire type: </span> {{ hireType }}</div>
      <div><span class="font-bold">Shift: </span> {{ shift }}</div>
      <div><span class="font-bold">Pay Range: </span> {{ payRange }}</div>
    </div>

    <div v-if="job.description" v-html="job.description" class="prose break-words" />
  </div>
</template>
