<script setup lang="ts">
import { RouterView } from 'vue-router';
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import { useDisplay } from 'vuetify';
import { computed, onMounted, ref, watch } from 'vue';
import ProjectService from '@/core/shared/project/project.service';
import ProjectCreateJobDialog from '@/components/Shared/Projects/ProjectCreateJobDialog.vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import ProjectsBar from '@/components/Shared/LayoutBar/ProjectsBar/ProjectsBar.vue';

const display = useDisplay();
const drawerService = new DrawerService();
const dialogService = new DialogService();
const projectService = new ProjectService();

const hasStartedProjectListInfiniteScroll = ref<boolean>(false);
const isDesktop = computed(() => display.mdAndUp.value);

const hasProjects = computed(() => {
  return projectService.projects.length > 0;
});

const isSearchingProjects = computed(() => {
  return projectService.isSearchingProjects;
});

function startProjectListInfiniteScrollObserver() {
  const infiniteScroll = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      projectService.searchMoreProjects();
    }
  });

  const loadMoreElement = document.querySelector('#project-infinite-scroll') as Element;
  infiniteScroll.observe(loadMoreElement);
}

async function handleProjectListInfiniteScrollObserver(isSearchingProjects: boolean) {
  // Start infinite scroll observer when projects are loaded so that we don't
  // send a search request when it's already loading projects
  if (!isSearchingProjects && hasProjects.value && !hasStartedProjectListInfiniteScroll.value) {
    startProjectListInfiniteScrollObserver();
    hasStartedProjectListInfiniteScroll.value = true;
  }
}

function scrollSelectedProjectIntoView() {
  const currentProjectId = projectService.currentProject?.id;

  if (!currentProjectId) {
    return;
  }

  const selectedProjectElement = document.getElementById(`project-card-${currentProjectId}`);

  selectedProjectElement?.scrollIntoView({
    behavior: 'instant',
    block: 'center',
    inline: 'nearest',
  });
}

watch(isSearchingProjects, (value) => {
  handleProjectListInfiniteScrollObserver(value);
});

onMounted(scrollSelectedProjectIntoView);
</script>

<template>
  <div>
    <v-navigation-drawer
      width="336"
      v-model:model-value="drawerService.isProjectsDrawerOpen"
      color="shade-860"
      :permanent="isDesktop"
    >
      <ProjectsBar />
    </v-navigation-drawer>
    <ProjectCreateJobDialog v-model="dialogService.isCreateProjectDialogOpen" />
    <RouterView />
  </div>
</template>

<style scoped></style>
