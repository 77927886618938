import { defineStore } from 'pinia';
import type { TalentSearchGating } from './types/talent-search-gating.type';
import { ref } from 'vue';
import type { ATSEmployerSubscription } from '@factoryfixinc/ats-interfaces';

export const useSubscriptionStore = defineStore('subscription', () => {
  const subscriptionProductName = ref<string>();
  const subscription = ref<ATSEmployerSubscription>();
  const talentSearchGating = ref<TalentSearchGating>();
  const jobsCount = ref<number>(0);

  return {
    subscriptionProductName,
    subscription,
    talentSearchGating,
    jobsCount,
  };
});
