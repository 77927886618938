<script setup lang="ts">
import { RouterView } from 'vue-router';
import Snackbar from '@/components/Shared/Snackbar/Snackbar.vue';
import MobileAppBar from '@/components/Shared/AppBar/MobileAppBar/MobileAppBar.vue';
import MobileDrawer from '@/components/Shared/AppBar/MobileDrawer/MobileDrawer.vue';
import DesktopDrawer from '@/components/Shared/AppBar/DesktopDrawer/DesktopDrawer.vue';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { computed } from 'vue';
import UnsubscribedGate from '@/components/Shared/Gates/UnsubscribedGate/UnsubscribedGate.vue';

const subscriptionService = new SubscriptionService();
const isEmployerSubscribed = computed(() => subscriptionService.isEmployerSubscribed);
</script>

<template>
  <!-- Mobile Shared App Bar -->
  <MobileAppBar />

  <!-- Mobile Shared Navigation Drawer -->
  <MobileDrawer />

  <!-- Desktop Shared Navigation Drawer -->
  <DesktopDrawer />

  <!-- Main Router View -->
  <v-main>
    <!-- Subscribed users -->
    <template v-if="isEmployerSubscribed">
      <Snackbar />
      <RouterView />
    </template>

    <!-- Unsubscribed users -->
    <template v-else>
      <UnsubscribedGate />
    </template>
  </v-main>
</template>
