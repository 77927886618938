import MeService from '@/core/shared/me/me.service';
import ProNotesPersistence from './pro-notes.persistence';
import { useProNotesStore } from './pro-notes.store';
import type { Employer, Pro } from '@factoryfixinc/ats-interfaces';
import { getJobTitle } from '../../sourcing/utils/job';
import type { Note, NoteWithJobHeader } from './types/pro-note.type';
import { promisePoolAllSettled } from '@/utils/promise.util';

export default class ProNotesService {
  private meService = new MeService();
  private persistence = new ProNotesPersistence();
  private store = useProNotesStore();

  get employerId(): Employer['id'] | undefined {
    return this.meService.employer?.id;
  }

  get notesWithJobHeaders(): NoteWithJobHeader[] {
    return this.store.notesWithJobHeaders;
  }

  async fetchProNotes(proPreferenceId: Pro['id']): Promise<void> {
    if (!this.employerId) return;

    const notes = await this.persistence.fetchProNotes(this.employerId, proPreferenceId);
    if (!notes || !notes.length) {
      this.store.notesWithJobHeaders = [];
    }

    const uniqueJobApplicantIds = new Set(
      notes.filter((note) => !!note.jobApplicantId).map((note) => note.jobApplicantId),
    ) as Set<number>;

    const jobApplicants = await Promise.all(
      Array.from(uniqueJobApplicantIds).map((id) =>
        this.persistence.fetchJobApplicantsWithJobById(id),
      ),
    );

    const jobHeaders = jobApplicants.map((jobApplicant) => {
      const jobTitle = getJobTitle(jobApplicant?.job);
      const displayTitle = jobTitle.displayTitle;
      return {
        jobApplicantId: jobApplicant?.id,
        url: `/employer/jobs/${jobApplicant?.jobId}/candidates`,
        jobTitle: displayTitle,
      };
    });

    const notesWithJobHeaders: NoteWithJobHeader[] = notes
      .map((note) => {
        const jobHeader = jobHeaders.find(
          ({ jobApplicantId }) => note.jobApplicantId === jobApplicantId,
        );

        return {
          ...note,
          jobHeader,
        };
      })
      .reverse();
    this.store.notesWithJobHeaders = notesWithJobHeaders;
  }

  async createProNote(proPreferenceId: Pro['id'], description: string): Promise<void> {
    if (!this.employerId) return;
    await this.persistence.postNote(this.employerId, proPreferenceId, description);
  }

  async bulkCreateProsRejectedNote(
    prosPreferenceId: Pro['id'][],
    description: string,
  ): Promise<void> {
    const poolLimit = 5;

    await promisePoolAllSettled<number, void>(poolLimit, prosPreferenceId, (proId) =>
      this.createProRejectedNote(proId, description),
    );
  }

  async createProRejectedNote(proPreferenceId: Pro['id'], description: string): Promise<void> {
    const rejectedDescription = `Rejection reason: ${description}`;

    await this.createProNote(proPreferenceId, rejectedDescription);
  }

  async deleteProNote(noteId: Note['id']): Promise<void> {
    await this.persistence.deleteNote(noteId);
    this.store.notesWithJobHeaders = this.store.notesWithJobHeaders.filter(
      (note) => note.id !== noteId,
    );
  }

  async updateProNote(
    noteId: Note['id'],
    description: Note['description'],
  ): Promise<NoteWithJobHeader> {
    await this.persistence.updateNote(noteId, description);
    const note = this.store.notesWithJobHeaders.find((note) => note.id === noteId);
    if (!note) throw new Error('Note not found');
    note.description = description;
    return note;
  }
}
