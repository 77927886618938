<script setup lang="ts">
import {
  JobVersionAuthorType,
  type JobVersionHistory,
} from '@/core/jobs/versions/types/job-version-history.type';
import { getNameFor } from '@/core/sourcing/utils/userProfile';
import { computed, ref } from 'vue';
import VersionsService from '@/core/jobs/versions/versions.service';

const props = defineProps<{
  jobVersion: JobVersionHistory;
  isVersionSelected: boolean;
}>();

const authorType = ref<JobVersionAuthorType>(JobVersionAuthorType.USER);

const isAuthorCopilot = computed(() => {
  return (
    authorType.value === JobVersionAuthorType.COPILOT ||
    authorType.value === JobVersionAuthorType.SUGGESTED_COPILOT
  );
});

const isAuthorATS = computed(() => {
  return authorType.value === JobVersionAuthorType.ATS;
});

function formatName() {
  if (!props.jobVersion.author && !props.jobVersion.suggestedByCopilot) {
    authorType.value = JobVersionAuthorType.ATS;
    return 'ATS';
  }
  if (props.jobVersion.suggestedByCopilot && props.jobVersion.author) {
    authorType.value = JobVersionAuthorType.SUGGESTED_COPILOT;
    return `Copilot version accepted by ${getNameFor(props.jobVersion.author)}`;
  }
  if (props.jobVersion.author) {
    authorType.value = JobVersionAuthorType.USER;
    return `${getNameFor(props.jobVersion.author)}`;
  }
  authorType.value = JobVersionAuthorType.COPILOT;
  return 'Copilot';
}
</script>

<template>
  <div class="version-detail" :class="{ 'version-detail--selected': isVersionSelected }">
    <div class="text-left text-sm font-bold leading-5">
      {{ VersionsService.formatJobVersionDate(new Date(jobVersion.createTs)) }}
    </div>
    <div class="author">
      <span class="dot" :class="{ copilot: isAuthorCopilot, ats: isAuthorATS }"></span>
      {{ formatName() }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.version-detail {
  @apply relative mb-[18px] cursor-pointer rounded-lg bg-tint-0 px-4 py-2;
  @apply transition-all duration-150 hover:bg-tint-20;
  &:after {
    @apply pointer-events-none cursor-default transition-all duration-150;
    @apply absolute bottom-[-9px] left-0 right-0 border-b-[1px] border-tint-60;
    content: '';
  }
  &.version-detail--selected {
    @apply pointer-events-none cursor-default bg-tint-40;
    &::after {
      opacity: 0;
    }
  }
}
.version-detail:has(+ .version-detail--selected) {
  &::after {
    opacity: 0;
  }
}
/** :last-child is not working for some reason */
.version-detail:nth-last-child(-n + 1) {
  &::after {
    opacity: 0;
  }
}
.dot {
  @apply mr-2 inline-block h-2 min-h-2 w-2 min-w-2 self-center rounded-full bg-primary-300;
  &.copilot {
    @apply bg-highlight-500;
  }
  &.ats {
    @apply bg-inform-300;
  }
}

.author {
  @apply mt-[1px] flex  text-left text-sm font-normal leading-5 text-shade-820;
}
</style>
