import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { UserProfile } from '@factoryfixinc/ats-interfaces';

export const useConversationWatcherStore = defineStore(
  'conversationWatcher',
  () => {
    const watcherProfileList = ref<UserProfile[]>([]);
    const watcherMapById = computed<Map<number, UserProfile>>(() => {
      const mapById = new Map<number, UserProfile>();
      for (const watcher of watcherProfileList.value) {
        mapById.set(watcher.id, watcher);
      }
      return mapById;
    });

    function setWatcherList(watchers: UserProfile[]) {
      watcherProfileList.value = watchers;
    }

    function getWatcherById(userId: number): UserProfile | undefined {
      return watcherMapById.value.get(userId);
    }

    return {
      setWatcherList,
      getWatcherById,
      watcherProfileList,
    };
  },
  {
    persist: true,
  },
);
