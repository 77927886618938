<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import VersionsService from '@/core/jobs/versions/versions.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import ExclamationIcon from '@/assets/svg/exclamation-circle-16.svg?component';

import VersionHistoryDetails from './VersionHistoryDetails.vue';

const jobVersionsService = new VersionsService();

const props = defineProps<{
  projectId: number | undefined;
  isJobVersionEditMode: boolean;
  isCopilotActivated: boolean;
}>();

const selectedIndex = ref(0);

const projectId = computed(() => {
  const id = Number(props.projectId);
  if (isNaN(id)) {
    return undefined;
  }
  return id;
});

const jobVersions = computed(() => jobVersionsService.jobVersions);
const isLoading = computed(() => jobVersionsService.isLoadingVersions);
const isLoadingOneJobVersion = computed(() => jobVersionsService.isLoadingJobVersion);

async function changeVersionHistory(newIndex: number) {
  try {
    if (selectedIndex.value === newIndex || isLoadingOneJobVersion.value) {
      return;
    }
    selectedIndex.value = newIndex;

    if (!projectId.value) {
      return;
    }
    await jobVersionsService.getJobVersionById(projectId.value, jobVersions.value[newIndex].id);

    jobVersionsService.currentJobVersionId = jobVersions.value[newIndex].id;
  } catch (error) {
    ErrorService.captureException(error);
  }
}

async function loadJobVersions() {
  try {
    if (!projectId.value) {
      return;
    }
    await jobVersionsService.getJobVersionsHeader(projectId.value);
  } catch (error) {
    ErrorService.captureException(error);
  }
}

onMounted(() => {
  loadJobVersions();
});

function isSelected(index: number) {
  if (props.isJobVersionEditMode) {
    return false;
  }
  return selectedIndex.value === index;
}
</script>

<template>
  <div>
    <div v-if="isLoading" class="mr-3 flex items-center justify-center">
      <SpinnerLoader />
      <p class="ml-2 text-xs font-normal text-shade-800">Looking for versions</p>
    </div>
    <template v-else>
      <div v-if="jobVersions.length === 0" class="flex text-[10px] text-shade-800">
        <div class="ml-4 place-self-center">
          <ExclamationIcon width="16" height="16" />
        </div>
        <div v-if="isCopilotActivated" class="ml-2 place-self-center italic">
          Version history before August 7, 2024 is unavailable. This job was posted before the
          release of this feature.
        </div>
        <div v-else class="ml-2 place-self-center italic">
          Enable Copilot to create version history
        </div>
      </div>
      <VersionHistoryDetails
        v-for="(jobVersion, index) in jobVersions"
        :key="jobVersion.id"
        :is-version-selected="isSelected(index)"
        :job-version="jobVersion"
        @click="changeVersionHistory(index)"
        :class="{ 'loading-job': isLoadingOneJobVersion }"
      />
    </template>
  </div>
</template>
<style lang="postcss" scoped>
.loading-job {
  @apply cursor-wait opacity-80;
}
</style>
