import type { JobTitle } from '@factoryfixinc/ats-interfaces';

import { useJobTitleStore } from './job-title.store';

import { useFFRestApi } from '@/composables/useApi';
import { arrayToRecord } from '@/utils/objects/array-to-record.util';
import { JobTitlePersistence } from './job-title.persistence';

export default class JobTitleService {
  private jobTitlePersistence = new JobTitlePersistence();
  private store = useJobTitleStore();

  public get jobTitles(): JobTitle[] {
    return this.store.jobTitles;
  }

  public get jobTitlesRecord(): Record<number, JobTitle> {
    return arrayToRecord(this.store.jobTitles, 'id');
  }

  public async fetchJobTitles(): Promise<JobTitle[]> {
    const { data, error } = await useFFRestApi(`/job-title`).json<JobTitle[]>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('No job titles found');
    }

    const jobTitles = data.value;
    this.store.jobTitles = jobTitles;

    return jobTitles;
  }

  public async normalizeJobTitle(displayTitle: string): Promise<JobTitle | null> {
    return this.jobTitlePersistence.normalizeJobTitle(displayTitle);
  }
}
