import type {
  JobTitle,
  TaxonomyBrand,
  TaxonomyCategory,
  TaxonomyIndustry,
  TaxonomyKnowledgeDiscipline,
  TaxonomyMachine,
  TaxonomyMachineRelations,
} from '@factoryfixinc/ats-interfaces';
import { useFFRestApi } from '@/composables/useApi';
import { InternalError } from '@/core/shared/errors/internal.error';
import type { SuggestedTaxonomyKnowledgeDiscipline } from './types/suggested-taxonomy-knowledge-discipline.type';
import type { SuggestedTaxonomyMachine } from './types/suggested-taxonomy-machine.type';

export class TaxonomyPersistence {
  public async fetchIndustries(): Promise<TaxonomyIndustry[]> {
    const { data, error, statusCode } = await useFFRestApi(`/public/taxonomy-industry`)
      .get()
      .json<TaxonomyIndustry[]>();

    if (error.value) {
      throw new InternalError('Failed to fetch taxonomy industries', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value ?? [];
  }

  public async fetchMachines(): Promise<
    (TaxonomyMachine & {
      taxonomyMachineTaxonomyBrandMaps: TaxonomyMachineRelations['taxonomyMachineTaxonomyBrandMaps'];
    })[]
  > {
    const encodedFilter = encodeURIComponent(
      JSON.stringify({
        include: [{ relation: 'taxonomyMachineTaxonomyBrandMaps' }],
      }),
    );
    const path = `/public/taxonomy-machine?filter=${encodedFilter}`;
    const { data, error, statusCode } = await useFFRestApi(path).get().json<
      (TaxonomyMachine & {
        taxonomyMachineTaxonomyBrandMaps: TaxonomyMachineRelations['taxonomyMachineTaxonomyBrandMaps'];
      })[]
    >();

    if (error.value) {
      throw new InternalError('Failed to fetch taxonomy machines', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value ?? [];
  }

  public async fetchBrands(): Promise<TaxonomyBrand[]> {
    const { data, error, statusCode } = await useFFRestApi(`/public/taxonomy-brand`)
      .get()
      .json<TaxonomyBrand[]>();

    if (error.value) {
      throw new InternalError('Failed to fetch taxonomy brands', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value ?? [];
  }

  public async fetchKnowledgeDisciplines(): Promise<TaxonomyKnowledgeDiscipline[]> {
    const { data, error, statusCode } = await useFFRestApi(`/public/taxonomy-knowledge-discipline`)
      .get()
      .json<TaxonomyKnowledgeDiscipline[]>();

    if (error.value) {
      throw new InternalError('Failed to fetch knowledge disciplines', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value ?? [];
  }

  public async fetchCategories(): Promise<TaxonomyCategory[]> {
    const { data, error, statusCode } = await useFFRestApi(`/public/taxonomy-category`)
      .get()
      .json<TaxonomyCategory[]>();

    if (error.value) {
      throw new InternalError('Failed to fetch taxonomy categories', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value ?? [];
  }

  async getSuggestedTechnologiesMachinesForJobTitle(
    jobTitleId: JobTitle['id'],
  ): Promise<SuggestedTaxonomyMachine[]> {
    const path = `/public/job-title/${jobTitleId}/taxonomy-machine`;
    const { data, error, statusCode } = await useFFRestApi(path).json<SuggestedTaxonomyMachine[]>();

    if (error.value) {
      throw new InternalError(`Could not fetch suggested technologies machines for jobTitleId`, {
        error: error.value,
        status: statusCode.value,
        data: { jobTitleId },
      });
    }

    return data.value ?? [];
  }

  async getSuggestedKnowledgeDisciplinesForJobTitle(
    jobTitleTaxonomyCategoryId: JobTitle['taxonomyCategoryId'],
  ): Promise<SuggestedTaxonomyKnowledgeDiscipline[]> {
    const path = `/public/taxonomy-category/${jobTitleTaxonomyCategoryId}/taxonomy-knowledge-discipline`;
    const { data, error, statusCode } =
      await useFFRestApi(path).json<SuggestedTaxonomyKnowledgeDiscipline[]>();

    if (error.value) {
      throw new InternalError(
        `Could not fetch suggested knowledge discipline for jobTitleTaxonomyCategoryId`,
        {
          error: error.value,
          status: statusCode.value,
          data: { jobTitleTaxonomyCategoryId },
        },
      );
    }

    return data.value ?? [];
  }
}
