<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
const display = useDisplay();

const isDesktop = computed(() => display.mdAndUp.value);
</script>

<template>
  <div>
    <div class="linear-loading-light mb-8 mt-4 h-[18px] w-full rounded-full"></div>

    <div class="linear-loading-light mb-6 h-6 w-4/12 rounded-full"></div>

    <div class="linear-loading-light mb-2 h-[18px] w-14 rounded-full"></div>
    <div class="linear-loading-light mb-9 h-[18px] w-full rounded-full"></div>

    <div class="mb-9 flex flex-wrap">
      <div class="mr-4" :class="{ 'w-3/12 ': isDesktop, 'w-full': !isDesktop }">
        <div class="linear-loading-light mb-2.5 h-[18px] w-14 rounded-full"></div>
        <div class="w-100 linear-loading-light h-[18px] rounded-full"></div>
      </div>
      <div :class="{ 'w-3/12': isDesktop, 'w-full': !isDesktop }">
        <div class="linear-loading-light mb-2.5 h-[18px] w-14 rounded-full"></div>
        <div class="w-100 linear-loading-light h-[18px] rounded-full"></div>
      </div>
    </div>

    <div class="linear-loading-light mb-2 h-[18px] w-14 rounded-full"></div>
    <div class="mb-8 flex flex-wrap">
      <div class="mr-4" :class="{ 'w-3/12 ': isDesktop, 'w-full': !isDesktop }">
        <div class="w-100 linear-loading-light h-[18px] rounded-full"></div>
      </div>
      <div class="mr-4" :class="{ 'w-3/12': isDesktop, 'w-full': !isDesktop }">
        <div class="w-100 linear-loading-light h-[18px] rounded-full"></div>
      </div>
      <div :class="{ 'w-3/12': isDesktop, 'w-full': !isDesktop }">
        <div class="w-100 linear-loading-light h-[18px] rounded-full"></div>
      </div>
    </div>

    <div class="linear-loading-light mb-2 h-[18px] w-14 rounded-full"></div>
    <div class="linear-loading-light mb-9 h-[18px] w-full rounded-full"></div>

    <div class="linear-loading-light mb-2 h-[18px] w-14 rounded-full"></div>
    <div class="linear-loading-light mb-9 h-[18px] w-full rounded-full"></div>

    <div class="linear-loading-light mb-9 mt-10 h-[1px] w-full rounded-full"></div>

    <div class="linear-loading-light mb-6 h-6 w-4/12 rounded-full"></div>

    <div class="linear-loading-light mb-2 h-[18px] w-14 rounded-full"></div>
    <div class="linear-loading-light mb-9 h-[18px] w-full rounded-full"></div>

    <div class="linear-loading-light mb-2 h-[18px] w-14 rounded-full"></div>
    <div class="mb-8 flex flex-wrap">
      <div
        v-for="i in 6"
        :key="i"
        class="mr-2"
        :class="{ 'w-[14%] ': isDesktop, 'w-full': !isDesktop }"
      >
        <div class="w-100 linear-loading-light h-[18px] rounded-full"></div>
      </div>
    </div>
    <!-- Need this to adjust the width -->
    <div class="mt-3 flex w-full flex-wrap opacity-0">
      <div class="w-[200px]"></div>
      <div class="w-4"></div>
      <div class="w-[490px]"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
