<script setup lang="ts">
import ProjectService from '@/core/shared/project/project.service';
import ProjectCard from '@/components/Shared/Projects/ProjectCard/ProjectCard.vue';
import { computed } from 'vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import ProjectLoaderCard from '@/components/Shared/Projects/ProjectLoaderCard.vue';

defineProps<{
  projectId?: number;
}>();

const emit = defineEmits<{
  (e: 'change:project-id', projectId: number): void;
}>();

const projectService = new ProjectService();

const projects = computed(() => projectService.projects);
const currentProjectId = computed(() => projectService.currentProject?.id);

const isSearchingProjects = computed(() => {
  return projectService.isSearchingProjects;
});

const isSearchingMoreProjects = computed(() => {
  return projectService.isSearchingMoreProjects;
});

async function changeProject(projectId: number) {
  if (currentProjectId.value === projectId) {
    return;
  }

  TrackingService.trackAction(TrackingActionName.PROJECT_CARD_CLICKED, { source: 'Sourcing' });
  emit('change:project-id', projectId);
}
</script>

<template>
  <div>
    <template v-if="!isSearchingProjects">
      <ProjectCard
        v-for="project in projects"
        tabindex="0"
        :key="project.id"
        :id="`project-card-${project.id}`"
        :project="project"
        :is-card-selected="project.id === projectId"
        @click="changeProject(project.id)"
        @select-project="changeProject($event.id)"
      />
    </template>

    <template v-else>
      <ProjectLoaderCard v-for="i in 4" :key="i" />
    </template>

    <div id="project-infinite-scroll" class="mt-8 flex items-center justify-center">
      <SpinnerLoader v-show="isSearchingMoreProjects" />
    </div>
  </div>
</template>
