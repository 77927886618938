<script setup lang="ts">
import UserAvatar from '@/components/Shared/AppBar/UserAvatar/UserAvatar.vue';
import UserInfo from '../UserInfo/UserInfo.vue';
import CompanyInfo from '../CompanyInfo/CompanyInfo.vue';
import Divider from '../../Divider/Divider.vue';
import UserMenuItem from './UserMenuItem.vue';
import CompaniesMenu from '../CompanyInfo/CompaniesMenu.vue';
import GetHelpMenu from '../GetHelp/GetHelpMenu.vue';
import NavbarTooltip from '@/components/Shared/Tooltip/NavbarTooltip.vue';
import type { Employer, UserProfile } from '@factoryfixinc/ats-interfaces';
import MeService from '@/core/shared/me/me.service';
import { computed } from 'vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import AuthenticationService from '@/core/shared/authentication/authentication.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

defineProps<{
  user: Partial<UserProfile>;
  employer: Partial<Employer>;
}>();

const router = useRouter();
const modelValue = ref(false);

const authenticationService = new AuthenticationService();
const meService = new MeService();
const showBillingFrame = ref(false);
const subscriptionService = new SubscriptionService();

const employers = computed<Array<Partial<Employer>>>(
  () =>
    meService.userProfile?.employerUserMaps?.map((e) => ({
      id: e.id,
    })) ?? [],
);

function onUserClick() {
  router.push({ name: 'settings-profile' });
  modelValue.value = false;
}

function onSettingsClick() {
  router.push('/settings');
  modelValue.value = false;
}

function onLogoutClick() {
  authenticationService.logout();
  meService.redirectToUI2('/employer/auth/logout');
}

async function toggleBillingFrame(show?: boolean) {
  showBillingFrame.value = typeof show === 'boolean' ? show : !showBillingFrame.value;
  if (showBillingFrame.value) {
    await subscriptionService.fetchPortalSessionUrl();
  } else {
    subscriptionService.clearPortalSessionUrl();
  }
}
</script>

<template>
  <v-menu
    v-model="modelValue"
    transition="slide-x-transition"
    location="right top"
    :offset="28"
    :close-on-content-click="false"
    @update:modelValue="toggleBillingFrame(false)"
  >
    <template #activator="{ props }">
      <div class="user-avatar flex h-12 w-12 cursor-pointer items-center justify-center">
        <UserAvatar
          v-bind="props"
          :user="user"
          @click="TrackingService.trackAction(TrackingActionName.PROFILE_CLICK)"
        />
      </div>
      <NavbarTooltip title="Account"></NavbarTooltip>
    </template>

    <div
      :class="[
        'border-1',
        'flex',
        'flex-col',
        'rounded-md',
        'bg-white',
        'w-[320px]',
        'shadow-menu',
      ]"
    >
      <div class="flex flex-col py-2" id="user-menu">
        <UserMenuItem class="h-12" @click="onUserClick">
          <UserInfo :user="user" />
        </UserMenuItem>

        <Divider class="mx-3" />

        <CompaniesMenu :employers="employers" target="#user-menu">
          <template #activator="{ props }">
            <UserMenuItem link class="h-12" v-bind="props">
              <CompanyInfo :employer="employer" />
            </UserMenuItem>
          </template>
        </CompaniesMenu>

        <Divider class="mx-3" />

        <UserMenuItem class="h-10 text-sm" @click="onSettingsClick">Settings</UserMenuItem>
        <UserMenuItem
          v-if="subscriptionService.useV2"
          class="h-10 text-sm"
          @click="toggleBillingFrame"
          >Billing</UserMenuItem
        >
        <iframe
          v-if="subscriptionService.billingPortalUrl"
          :src="subscriptionService.billingPortalUrl"
          title="billing portal"
          class="h-[400px] w-full"
        ></iframe>
        <GetHelpMenu target="#user-menu">
          <template #activator="{ props }">
            <UserMenuItem v-bind="props" link class="h-10 text-sm">Get help</UserMenuItem>
          </template>
        </GetHelpMenu>

        <Divider class="mx-3" />

        <UserMenuItem class="h-10 text-sm" @click="onLogoutClick">Logout</UserMenuItem>
      </div>
    </div>
  </v-menu>
</template>
<style lang="scss" scoped>
.user-avatar {
  &:hover {
    :deep(.avatar-text) {
      box-shadow: 0px 0px 0px 4px var(--tw-shadow-color);
      @apply shadow-shade-840 transition-all duration-200;
    }
  }
}
</style>
