<script setup lang="ts">
import { isValidEmail } from '@/utils/forms/is-valid-email.form';
import { computed } from 'vue';
import { ref } from 'vue';
import MeService from '@/core/shared/me/me.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';

const meService = new MeService();
const isLoading = ref(false);

const isMenuOpen = ref(false);
const emailInput = ref<string>(meService.userProfile?.email ?? '');
const feedbackInput = ref<string>();
const isValidEmailInput = computed(() => isValidEmail(emailInput.value));
const isValidForm = computed(() => isValidEmailInput.value && feedbackInput.value);

async function onSubmitClick() {
  try {
    isLoading.value = true;

    if (!emailInput.value || !feedbackInput.value) {
      return;
    }

    await meService.sendHelpRequest(emailInput.value, feedbackInput.value);
    feedbackInput.value = '';
    isMenuOpen.value = false;
    SnackbarService.success('Your message has been sent successfully!.');
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Something went wrong. Please try again');
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <div class="flex flex-col p-4">
    <span class="text-base font-bold">Get help and submit feedback</span>

    <div class="flex flex-col">
      <input
        v-model="emailInput"
        type="text"
        class="col-span-8 mt-4 h-10 rounded-md border border-tint-100 p-2 text-sm"
        placeholder="Email.."
      />

      <textarea
        v-model="feedbackInput"
        type="text"
        rows="5"
        placeholder="What can we help with?"
        class="col-span-8·mt-4·min-h-6 rounded-md border border-tint-100 p-2 text-sm"
      />
    </div>

    <div class="flex justify-end">
      <button
        :disabled="!isValidForm || isLoading"
        :class="{
          'col-span-4 mt-4 rounded-md px-3 py-[6px] text-sm': true,
          'bg-black': isValidForm,
          'bg-shade-500': !isValidForm,
        }"
        @click="onSubmitClick"
      >
        Submit
      </button>
    </div>
  </div>
</template>
