<script setup lang="ts">
import CheckboxOffSvg from '@/assets/svg/checkbox-off.svg?component';
import CheckboxOnSvg from '@/assets/svg/checkbox-on.svg?component';
import CheckboxMinusSvg from '@/assets/svg/checkbox-minus.svg?component';
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  isMinusCheck?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'click', value: Event): void;
}>();

const handleClick = (event: Event) => {
  if (isDisabled.value === true) return;
  emit('update:modelValue', !props.modelValue);
  emit('click', event);
};

const isDisabled = computed(() => props.disabled === true);
</script>

<template>
  <div
    :class="{ 'cursor-pointer': !isDisabled, 'cursor-default': isDisabled }"
    @click="handleClick"
    data-testid="input-checkbox"
  >
    <template v-if="modelValue">
      <CheckboxMinusSvg v-if="isMinusCheck" data-testid="input-checkbox-minus" />
      <CheckboxOnSvg v-else data-testid="input-checkbox-on" />
    </template>

    <template v-else>
      <CheckboxOffSvg data-testid="input-checkbox-off" />
    </template>
  </div>
</template>
