// import type { JobVersionHistory } from './types/job-version-history.type';
import type { JobWithRelations } from '@factoryfixinc/ats-interfaces';
import { type JobVersionHistory, JobVersionViewStatus } from './types/job-version-history.type';
import { VersionsPersistence } from './versions.persistence';
import { useJobVersionsStore } from './versions.store';
import MeService from '@/core/shared/me/me.service';
import dayjs from 'dayjs';
import DateUtils from '@/utils/date-utils';

export default class VersionsService {
  private store = useJobVersionsStore();
  private meService = new MeService();
  private versionsPersistence = new VersionsPersistence();

  public get currentJobVersionViewStatus(): JobVersionViewStatus {
    return this.store.currentJobVersionViewStatus;
  }
  public set currentJobVersionViewStatus(status: JobVersionViewStatus) {
    this.store.currentJobVersionViewStatus = status;
  }

  // Show Job Version ordered by createTs and filter out job versions that are too close to each other
  public get jobVersions(): JobVersionHistory[] {
    return this.store.jobVersions
      .sort((a, b) => dayjs(b.createTs).unix() - dayjs(a.createTs).unix())
      .filter((jobVersion, index) => {
        if (index === 0) {
          return true; // Include the first job version
        }
        const prevJobVersion = this.store.jobVersions[index - 1];
        const timeDifference = dayjs(jobVersion.createTs).diff(
          dayjs(prevJobVersion.createTs),
          'second',
        );
        // Include job versions that are more than 5 second apart
        // due to side effect of the backend
        return timeDifference < -5;
      });
  }

  public get currentJobVersion(): JobVersionHistory | null {
    return this.store.currentJobVersion;
  }
  public set currentJobVersion(jobVersion: JobVersionHistory | null) {
    this.store.currentJobVersion = jobVersion;
  }
  public get jobVersionWithRelations(): JobWithRelations<
    | 'jobTitle'
    | 'screenerQuestions'
    | 'jobQualifications'
    | 'jobResponsibilities'
    | 'jobTaxonomyMachineMaps'
    | 'jobTaxonomyKnowledgeDisciplineMaps'
    | 'jobStatusHistories'
  > | null {
    if (!this.store.currentJobVersion || !this.store.currentJobVersion.snapshot) {
      return null;
    } else {
      return JSON.parse(this.store.currentJobVersion.snapshot);
    }
  }

  public get currentJobVersionId(): number | null {
    return this.store.currentJobVersionId;
  }
  public set currentJobVersionId(id: number | null) {
    this.store.currentJobVersionId = id;
  }

  public get isLoadingVersions(): boolean {
    return this.store.isLoadingVersions;
  }
  public get isLoadingJobVersion(): boolean {
    return this.store.isLoadingJobVersion;
  }

  public async getJobVersionById(
    projectId: number,
    jobVersionHistoryId: number,
  ): Promise<JobVersionHistory> {
    this.store.isLoadingJobVersion = true;

    try {
      const index = this.store.jobVersions.findIndex((p) => p.id === jobVersionHistoryId);

      //To optimize the request, we can check if we already have the job version snapshot in the store
      if (this.store.jobVersions[index] && this.store.jobVersions[index].snapshot) {
        this.store.currentJobVersion = this.store.jobVersions[index];
        return this.store.jobVersions[index];
      }

      const employerId = this.meService.employer?.id as number;
      const jobVersion = await this.versionsPersistence.getJobVersionById(
        projectId,
        employerId,
        jobVersionHistoryId,
      );

      if (!jobVersion) {
        throw new Error('Job Version not found');
      }

      // Will update the Job Version History in the store if it exists

      if (index !== -1) {
        //Must set the author to avoid losing the author when updating the job version
        jobVersion.author = this.store.jobVersions[index].author;
        this.store.jobVersions[index] = jobVersion;
      }
      this.store.currentJobVersion = jobVersion;
      return jobVersion;
    } catch (error) {
      throw error;
    } finally {
      this.store.isLoadingJobVersion = false;
    }
  }

  public async getJobVersionsHeader(projectId: number): Promise<void> {
    try {
      this.store.isLoadingVersions = true;
      const employerId = this.meService.employer?.id as number;
      const results = await this.versionsPersistence.getJobVersionsByProjectId(
        projectId,
        employerId,
      );
      this.store.jobVersions = results;
    } catch (error) {
      throw error;
    } finally {
      this.store.isLoadingVersions = false;
    }
  }

  public resetJobVersions(): void {
    this.store.jobVersions = [];
    this.store.currentJobVersion = null;
    this.store.currentJobVersionId = null;
    this.store.currentJobVersionViewStatus = JobVersionViewStatus.NORMAL_MODE;
    this.store.isLoadingVersions = false;
    this.store.isLoadingJobVersion = false;
  }

  public static formatJobVersionDate(date: Date): string {
    return DateUtils.isSame(date)
      ? 'Today at ' + DateUtils.formatTimestamp(date, 'hh:mma')
      : DateUtils.formatTimestamp(date, 'MMMM DD [at] hh:mma');
  }
}
