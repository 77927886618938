<script setup lang="ts">
import AddTeammateIcon from '@/assets/svg/navbar/add-teammate-icon.svg';
import AddTeammateIconOutlined from '@/assets/svg/navbar/add-teammate-icon-outlined.svg';
import { ref } from 'vue';
import InviteTeammateContent from './InviteTeammateContent.vue';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import TrackingService from '@/core/shared/tracking/tracking.service';
import NavbarIcon from '../DesktopDrawer/NavbarIcon.vue';

const isMenuVisible = ref(false);

async function onInviteSent() {
  isMenuVisible.value = false;
  TrackingService.trackAction(TrackingActionName.INVITE_TEAM_SENT);
}
</script>

<template>
  <v-menu
    v-model="isMenuVisible"
    transition="slide-x-transition"
    location="right top"
    :offset="28"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <div
        class="flex h-12 w-12 cursor-pointer items-center justify-center"
        v-bind="props"
        @click="TrackingService.trackAction(TrackingActionName.INVITE_TEAM_START)"
      >
        <NavbarIcon
          title="Add teammate"
          :src="AddTeammateIconOutlined"
          :selected-src="AddTeammateIcon"
        />
      </div>
    </template>

    <div
      :class="[
        'border-1',
        'flex',
        'flex-col',
        'rounded-md',
        'bg-white',
        'w-[320px]',
        'shadow-menu',
      ]"
    >
      <InviteTeammateContent @invite-sent="onInviteSent" />
    </div>
  </v-menu>
</template>
