<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import { type ConversationIndex } from '@/core/conversations/conversation-index/conversation-index.type';
import CandidateRowItem from '../CandidateRowItem/CandidateRowItem.vue';

const props = defineProps<{
  conversationIndexes: Array<ConversationIndex>;
}>();

const router = useRouter();
const conversationIndexService = new ConversationIndexService();

const hoveringItemIndex = ref<number>(-1);

const conversationIndexes = computed(() => {
  return props.conversationIndexes.map((conversationIndex, index) => {
    // Don't display lower divider if hovering over the item
    let displaysDivider = true;
    if (hoveringItemIndex.value === index) {
      displaysDivider = false;
    }

    // Don't display upper divider if hovering over the item (the previous item's lower divider)
    if (hoveringItemIndex.value !== -1 && hoveringItemIndex.value === index + 1) {
      displaysDivider = false;
    }

    return {
      ...conversationIndex,
      displaysDivider,
      index,
    };
  });
});

function selectHoveringItemIndex(index: number) {
  hoveringItemIndex.value = index;
}

function deselectHoveringItemIndex() {
  hoveringItemIndex.value = -1;
}

function goToCandidate(conversationIndex: ConversationIndex) {
  conversationIndexService.clearSelectedProAndApplication();
  conversationIndexService.resetConversationIndexesListAndSearch();
  const applicationIndexes = conversationIndex.applicationIndexes ?? [];
  const applicationId = applicationIndexes[0]?.applicationId ?? conversationIndex.proId;

  router.push({
    path: '/conversations/conversation',
    query: {
      isDeepLink: 1,
      jobApplicantId: applicationId,
    },
  });
}
</script>

<template>
  <div v-for="conversationIndex of conversationIndexes" :key="conversationIndex.conversationId">
    <CandidateRowItem
      :conversationIndex="conversationIndex"
      class="cursor-pointer rounded-lg hover:bg-tint-20"
      @mouseover="selectHoveringItemIndex(conversationIndex.index)"
      @mouseleave="deselectHoveringItemIndex"
      @click="goToCandidate(conversationIndex)"
    />
    <div
      class="h-[1px] w-full"
      :class="{
        'bg-tint-60': conversationIndex.displaysDivider,
      }"
    />
  </div>
</template>
