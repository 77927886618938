import type {
  TaxonomyBrand,
  TaxonomyCategory,
  TaxonomyIndustry,
  TaxonomyKnowledgeDiscipline,
  TaxonomyMachine,
  TaxonomyMachineRelations,
} from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTaxonomyStore = defineStore(
  'taxonomy',
  () => {
    const industries = ref<TaxonomyIndustry[]>([]);
    const machines = ref<
      (TaxonomyMachine & {
        taxonomyMachineTaxonomyBrandMaps: TaxonomyMachineRelations['taxonomyMachineTaxonomyBrandMaps'];
      })[]
    >([]);
    const brands = ref<TaxonomyBrand[]>([]);
    const knowledgeDisciplines = ref<TaxonomyKnowledgeDiscipline[]>([]);
    const categories = ref<TaxonomyCategory[]>([]);

    function reset() {
      industries.value = [];
      machines.value = [];
      brands.value = [];
      knowledgeDisciplines.value = [];
      categories.value = [];
    }

    return {
      industries,
      machines,
      brands,
      knowledgeDisciplines,
      categories,
      reset,
    };
  },
  { persist: true },
);
