type RecordKey<T> = {
  [key: string | number]: T;
};

export function arrayToRecord<T, K extends keyof T>(objects: T[], key: K): RecordKey<T> {
  if (!objects) {
    return {} as RecordKey<T>;
  }

  return objects.reduce((acc, obj) => {
    const objKey = obj[key];

    if (!objKey) {
      return acc;
    }

    return { ...acc, [objKey as string | number]: obj };
  }, {} as RecordKey<T>);
}
