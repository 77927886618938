<script setup lang="ts">
import BuildingSvg from '@/assets/svg/building.svg';
import type { Employer } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';

const props = defineProps<{
  employer: Partial<Employer>;
}>();

const name = computed(() => props.employer?.name);
</script>

<template>
  <div class="flex h-full w-full items-center">
    <span class="flex h-8 w-8 items-center justify-center rounded-full bg-inform-100">
      <img :src="BuildingSvg" class="h-4 w-4" />
    </span>

    <div class="ml-2 flex flex-col">
      <span class="truncate text-sm font-bold">{{ name }}</span>
    </div>
  </div>
</template>
