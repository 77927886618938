<script lang="ts" setup>
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';
import type { ProScore } from '@/core/conversations/application-score/application-score.type';
import { watch } from 'vue';
import { onMounted, ref } from 'vue';
import ApplicationScoreBlurb from './ApplicationScoreBlurb.vue';

import type { VMenu } from 'vuetify/components';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';

type MenuProps = InstanceType<typeof VMenu>['$props'];

const props = withDefaults(
  defineProps<{
    applicationId: number;
    fallbackScore?: number;
    jobTitle: string;
    jobId?: number;
    loadOnMount?: boolean;
    popupLocation?: MenuProps['location'];
    analyticsSource: string;
  }>(),
  { loadOnMount: false },
);

const emit = defineEmits<{
  (e: 'change:pro-score', value: ProScore): void;
}>();

const applicationScoreService = new ApplicationScoreService();
const showScoreDialog = ref<boolean>(false);
const isLoading = ref<boolean>(true);
const scoreDetails = ref<ProScore>();

function handleShowScoreChange(show: boolean) {
  showScoreDialog.value = show;
  if (show && !props.loadOnMount && !scoreDetails.value) {
    loadScoreDetails(props.applicationId);
  }
  if (show) {
    TrackingService.trackAction(TrackingActionName.SCORE_SUMMARY_VIEWED, {
      source: props.analyticsSource,
      applicationId: props.applicationId,
    });
  }
}

async function loadScoreDetails(applicantId: number | undefined) {
  if (!applicantId) {
    scoreDetails.value = undefined;
    return;
  }

  try {
    isLoading.value = true;
    scoreDetails.value = await applicationScoreService.fetchScoreDetails(applicantId);
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    // If no score details are returned, we should set the score to the fallback score
    if (!scoreDetails.value && props.fallbackScore) {
      scoreDetails.value = {
        score: props.fallbackScore,
        jobApplicationId: applicantId,
        summary: '',
      };
    }

    if (scoreDetails.value) {
      emit('change:pro-score', scoreDetails.value);
    }

    isLoading.value = false;
  }
}

watch([() => props.applicationId], ([applicationId]) => loadScoreDetails(applicationId));
onMounted(() => {
  if (props.loadOnMount) {
    loadScoreDetails(props.applicationId);
  }
});
</script>

<template>
  <v-menu
    v-model="showScoreDialog"
    :close-on-content-click="false"
    :open-delay="0"
    @update:model-value="handleShowScoreChange"
    attach
    open-on-hover
    :location="(popupLocation as MenuProps['location']) || 'end bottom'"
    width="200px"
  >
    <template v-slot:activator="{ props }">
      <span v-bind="props" class="inline-block cursor-pointer">
        <slot name="hoverTrigger" :scoreDetails="scoreDetails" :scoreIsLoading="isLoading"> </slot>
      </span>
    </template>
    <template #default>
      <ApplicationScoreBlurb
        :key="applicationId"
        :score-details="scoreDetails"
        :job-title="jobTitle"
        :job-id="jobId"
        :is-loading="isLoading"
        :fallback-score="fallbackScore"
        :analytics-source="analyticsSource"
        :application-id="applicationId"
      />
    </template>
  </v-menu>
</template>
