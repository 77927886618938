<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M2.397 15.096c.038-.344.057-.517.11-.678.045-.142.11-.278.194-.404.093-.141.215-.264.46-.509L14.167 2.5A2.357 2.357 0 1 1 17.5 5.833L6.495 16.84c-.245.245-.368.367-.51.46a1.668 1.668 0 0 1-.403.195c-.162.052-.334.071-.678.11l-2.82.313.313-2.82Z"
    />
  </svg>
</template>
