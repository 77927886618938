<script lang="ts" setup>
import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import { onMounted } from 'vue';
import { computed, ref, watch } from 'vue';

import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import DownloadIcon from '@/assets/svg/conversations/DownloadIcon.vue';
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import RejectedModal from '@/components/Conversations/Application/RejectedModal.vue';
import {
  REJECTED_WITH_REASON,
  jobApplicationOptions,
} from '@/core/conversations/pro-profile/utils/application-statuses';

const proProfileService = new ProProfileService();

const props = withDefaults(
  defineProps<{
    status?: JobApplicantStatus;
    rejectionReason?: string;
    showDownloadProfile?: boolean;
    slim?: boolean;
  }>(),
  {
    showDownloadProfile: false,
    slim: false,
  },
);

const emit = defineEmits(['updateValue']);

const selectedApplicationStatus = ref();
const applicationStatuses = computed(() => {
  return jobApplicationOptions.filter((option) => option.value !== JobApplicantStatus.CONTACT);
});
let previousStatus: string;

const updateValue = (newStatus: string) => {
  previousStatus = selectedApplicationStatus.value;
  selectedApplicationStatus.value = newStatus as JobApplicantStatus;

  if (newStatus === REJECTED_WITH_REASON) {
    rejectedModalVisible.value = true;
    return;
  }
  rejectedModalVisible.value = false;
  if (newStatus === previousStatus) return;
  emit('updateValue', { newStatus: newStatus as JobApplicantStatus, previousStatus });
};

const updateRejectedReason = (reason: string, isSilent: boolean) => {
  rejectedModalVisible.value = false;
  emit('updateValue', { newStatus: JobApplicantStatus.REJECTED, previousStatus, reason, isSilent });
};

const downloadProfile = async () => {
  try {
    if (!(proProfileService.selectedProApplication && proProfileService.selectedProProfile)) return;
    const { nameFirst, nameLast } = proProfileService.selectedProProfile;
    const { id: applicationId } = proProfileService.selectedProApplication;

    const pdfFile = await proProfileService.downloadProfilePdf(applicationId);
    const linkSource = `data:application/pdf;base64,${pdfFile}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = `${nameFirst}_${nameLast}_profile`;
    downloadLink.click();
    TrackingService.trackAction(TrackingActionName.PROFILE_DOWNLOADED);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to download profile');
  }
};

onMounted(() => {
  if (
    props.rejectionReason &&
    props.rejectionReason.length > 0 &&
    props.status === JobApplicantStatus.REJECTED
  ) {
    selectedApplicationStatus.value = REJECTED_WITH_REASON;
  } else if (props.status === JobApplicantStatus.CONTACT) {
    selectedApplicationStatus.value = 'Outreach';
  } else {
    selectedApplicationStatus.value = props.status;
  }
  previousStatus = selectedApplicationStatus.value;
});

const computedStatus = computed(() => props.status);
watch(computedStatus, (newValue) => {
  selectedApplicationStatus.value = newValue;
});

const rejectedModalVisible = ref(false);

// If user decides to close the modal without submitting, then we set the
// preivous status back to the selected status
const setPreviousStatus = () => {
  selectedApplicationStatus.value = props.status;
};
</script>
<template>
  <div
    class="application-picker flex min-w-[175px] items-center justify-end"
    :class="{ 'slim min-w-min': slim }"
  >
    <v-select
      :items="applicationStatuses"
      density="compact"
      variant="solo"
      :modelValue="selectedApplicationStatus"
      :menuIcon="ChevronIcon"
      :menu-props="{ maxHeight: 'unset', offset: '5px' }"
      @update:modelValue="updateValue"
      :persistent-counter="true"
    >
      <template v-slot:item="{ props }">
        <v-list-item
          variant="plain"
          class="hover:bg-tint-20"
          :ripple="false"
          v-bind="props"
        ></v-list-item>
      </template>
    </v-select>
    <div
      class="relative ml-1 cursor-pointer rounded-md p-2 text-shade-900 hover:bg-tint-40 active:bg-tint-60"
      @click="downloadProfile"
      v-if="props.showDownloadProfile"
    >
      <DownloadIcon class="pointer-events-none" />
      <v-tooltip activator="parent" location="bottom" content-class="application-picker__tooltip">
        Download profile
      </v-tooltip>
    </div>
    <RejectedModal
      v-model="rejectedModalVisible"
      @on-cancel="setPreviousStatus"
      @on-update-rejected-reason="updateRejectedReason"
    ></RejectedModal>
  </div>
</template>
<style lang="postcss">
.application-picker {
  &.slim {
    @apply h-6;
  }
  .v-input {
    @apply h-8 max-w-max;
  }
  &.slim .v-input {
    @apply h-6;
  }
  .v-input__details {
    @apply hidden;
  }

  .v-field {
    @apply rounded-lg border border-tint-80 font-sans text-xs font-semibold;
  }

  .v-field,
  .v-field__field,
  .v-field__input {
    @apply flex h-8 max-h-8 items-center;
  }

  &.slim .v-field,
  &.slim .v-field__field,
  &.slim .v-field__input {
    @apply h-6 max-h-6;
  }

  .v-field__input {
    @apply justify-center p-0 shadow-none;
    .v-select__selection {
      @apply text-shade-900;
    }
  }

  .v-field--appended {
    @apply pl-2 pr-1;
  }

  .v-field--variant-solo,
  .v-field--variant-solo-filled {
    @apply shadow-none;
  }
}
.application-picker__tooltip {
  @apply bg-shade-880 px-3 py-2 text-xs text-tint-0 !important;
}

.v-list-item {
  @apply !opacity-100;

  .v-list-item-title {
    @apply text-sm text-shade-900 !important;
  }
}
.v-list-item--active {
  @apply bg-highlight-50 bg-opacity-100  !important;
  .v-list-item-title {
    @apply font-bold  !important;
  }
}
</style>
