import { HireType, PayRatePeriod } from '@factoryfixinc/ats-interfaces';

export type LocalJobData = {
  employerName: string;
  jobTitleId: null | number;
  displayTitle: null | string;
  employerAssigneeId: null;
  hireType: null | HireType;
  shift: null | number;
  startTime: null | string;
  endTime: null | string;
  description: null | string; // html
  rawDescription: null | string; // markdown
  payRateMin: null | number;
  payRateMax: null | number;
  payRateSalaryMin: null | number;
  payRateSalaryMax: null | number;
  payRatePeriod: PayRatePeriod;
  minimumApplicantsPerWeek: 0;
  hiresPerPosition: 0;
  street1: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  anonymous: boolean;
};

// Utility CONSTANTS, used in the local job data values

/**
 * Hours starting from 7:00 AM
 * until 6:30 AM
 * This are used as the ordered hours when selecting shifts por a job
 */
export const ShiftHours = [
  { value: '7:00 AM' },
  { value: '7:30 AM' },
  { value: '8:00 AM' },
  { value: '8:30 AM' },
  { value: '9:00 AM' },
  { value: '9:30 AM' },
  { value: '10:00 AM' },
  { value: '10:30 AM' },
  { value: '11:00 AM' },
  { value: '11:30 AM' },
  { value: '12:00 PM' },
  { value: '12:30 PM' },
  { value: '1:00 PM' },
  { value: '1:30 PM' },
  { value: '2:00 PM' },
  { value: '2:30 PM' },
  { value: '3:00 PM' },
  { value: '3:30 PM' },
  { value: '4:00 PM' },
  { value: '4:30 PM' },
  { value: '5:00 PM' },
  { value: '5:30 PM' },
  { value: '6:00 PM' },
  { value: '6:30 PM' },
  { value: '7:00 PM' },
  { value: '7:30 PM' },
  { value: '8:00 PM' },
  { value: '8:30 PM' },
  { value: '9:00 PM' },
  { value: '9:30 PM' },
  { value: '10:00 PM' },
  { value: '10:30 PM' },
  { value: '11:00 PM' },
  { value: '11:30 PM' },
  { value: '12:00 AM' },
  { value: '12:30 AM' },
  { value: '1:00 AM' },
  { value: '1:30 AM' },
  { value: '2:00 AM' },
  { value: '2:30 AM' },
  { value: '3:00 AM' },
  { value: '3:30 AM' },
  { value: '4:00 AM' },
  { value: '4:30 AM' },
  { value: '5:00 AM' },
  { value: '5:30 AM' },
  { value: '6:00 AM' },
  { value: '6:30 AM' },
];

export const HireTypeItems = [
  {
    value: HireType.FULL_TIME,
  },
  {
    value: HireType.PART_TIME,
  },
  {
    value: HireType.PROJECT,
  },
];

export const ShiftItems = [
  { value: 1, text: 'First' },
  { value: 2, text: 'Second' },
  { value: 3, text: 'Third' },
  // Multiple shifts is stored as null in the db, locally used as -1 so that the combobox can display the placeholder
  { value: -1, text: 'Multiple Shifts' },
  { value: 0, text: 'Other' },
];

export const PayRatePeriodItems = [
  { value: PayRatePeriod.HOURLY, text: 'per hour' },
  { value: PayRatePeriod.ANNUAL, text: 'per year' },
];
