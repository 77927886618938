<script setup lang="ts">
import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import BusinessSvg from '@/assets/svg/business-20.svg?component';
import { computed } from 'vue';

const props = defineProps<{
  companyName: string;
  modelValue: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const isDisabled = computed(() => props.disabled === true);
</script>

<template>
  <div class="flex items-center">
    <!-- Company Name-->
    <div class="flex rounded bg-tint-20 px-3 py-2.5">
      <BusinessSvg class="fill-shade-800" />
      <span class="ml-2.5 text-sm font-bold text-shade-800">{{ companyName }}</span>
    </div>

    <!-- Keep Name Confidential Checkbox -->
    <div class="ml-3 flex items-center" :class="{ 'opacity-85': isDisabled }">
      <Checkbox
        :model-value="modelValue"
        @update:model-value="emit('update:modelValue', $event)"
        :disabled="isDisabled"
      />
      <span class="ml-2 text-xs">Keep company name confidential</span>
    </div>
  </div>
</template>
