<template>
  <div class="flex items-center justify-between pb-4 pl-6 pt-12">
    <div class="font-serif font-bold leading-6 text-white">Settings</div>
  </div>
  <div v-for="item in listItems" :key="item.name" class="mb-4 px-4">
    <div class="px-2 py-2 text-xs leading-5 text-tint-400">{{ item.name }}</div>
    <div v-for="child in item.children" :key="child.name">
      <router-link
        :to="child.route"
        activeClass="text-white font-bold rounded-md bg-shade-840"
        class="items flex"
      >
        <div class="px-2 py-1.5 text-xs leading-5 text-tint-80">{{ child.name }}</div>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const listItems = [
  {
    name: 'COMPANY',
    children: [
      { name: 'Company Overview', route: '/settings/company' },
      { name: 'Members', route: '/settings/members' },
    ],
  },
  {
    name: 'MY ACCOUNT',
    children: [
      { name: 'Profile', route: '/settings/profile' },
      { name: 'Notifications', route: '/settings/notifications' },
      { name: 'Security', route: '/settings/sign-in' },
    ],
  },
];
</script>

<style scoped></style>
