import { useProjectStore } from './project.store';
import { ProjectPersistence as SharedProjectPersistence } from '@/core/shared/project/project.persistence';

import MeService from '@/core/shared/me/me.service';
import type {
  Project,
  ProjectSource,
  ProjectWithRelations,
  SearchProjects,
} from '@factoryfixinc/ats-interfaces';
import { JobApplicantStatus, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import type {
  CreateProjectInput,
  DuplicateProjectInput,
  UpdateProjectInput,
} from '@/core/shared/project/project.type';
import { isEqual } from 'radash';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { PageSource } from '@/core/shared/tracking/tracking.type';
import SubscriptionService from '../subscription/subscription.service';
import { InternalError } from '../errors/internal.error';

export default class ProjectService {
  private store = useProjectStore();
  private subscriptionService = new SubscriptionService();
  private sharedPersistence = new SharedProjectPersistence();
  private meService = new MeService();

  public get projects(): ProjectWithRelations<'candidates'>[] {
    return this.store.projects;
  }

  public set projects(projects: ProjectWithRelations<'candidates'>[]) {
    this.store.projects = projects;
  }

  public get currentProject(): ProjectWithRelations<'candidates'> | undefined {
    return this.store.currentProject;
  }

  public get employerLocations(): string[] | undefined {
    return this.store.employerLocations;
  }

  public get title(): string {
    return this.store.title;
  }

  public set title(title: string) {
    this.store.title = title;
  }

  public get titleOrReqId(): string {
    return this.store.titleOrReqId;
  }

  public set titleOrReqId(titleOrReqId: string) {
    this.store.titleOrReqId = titleOrReqId;
  }

  public get source(): ProjectSource | null {
    return this.store.source;
  }

  public set source(source: ProjectSource | null) {
    this.store.source = source;
  }

  public get copilot(): boolean | null {
    return this.store.copilot;
  }

  public set copilot(copilot: boolean | null) {
    this.store.copilot = copilot;
  }

  public get status(): ProjectStatus | null {
    return this.store.status;
  }

  public set status(status: ProjectStatus | null) {
    this.store.status = status;
  }

  public get locations(): string[] {
    return this.store.locations;
  }

  public set locations(location: string[]) {
    this.store.locations = location;
  }

  public get teamIds(): number[] {
    return this.store.teamIds;
  }

  public set teamIds(teamIds: number[]) {
    this.store.teamIds = teamIds;
  }

  public get isSearchingProjects(): boolean {
    return this.store.isSearchingProjects;
  }

  public get isSearchingMoreProjects(): boolean {
    return this.store.isSearchingMoreProjects;
  }

  public get didSelectUserInProjectFilters(): boolean {
    return this.store.didSelectUserInProjectFilters;
  }

  public set didSelectUserInProjectFilters(value: boolean) {
    this.store.didSelectUserInProjectFilters = value;
  }

  public get hasCopilotFilter(): boolean {
    return this.store.copilot === true;
  }

  public get hasLocationFilter(): boolean {
    return this.store.locations.length > 0;
  }

  public get hasTeamFilter(): boolean {
    return this.store.teamIds.length > 0;
  }

  public get hasSourceFilter(): boolean {
    return Boolean(this.store.source) && this.store.source !== 'all';
  }

  public get hasStatusFilter(): boolean {
    return this.store.status !== 'live';
  }

  public get filtersCount(): number {
    return (
      Number(this.hasCopilotFilter) +
      Number(this.hasLocationFilter) +
      Number(this.hasTeamFilter) +
      Number(this.hasSourceFilter) +
      Number(this.hasStatusFilter)
    );
  }

  public get didLoadProjects(): boolean {
    return this.store.didLoadProjects;
  }

  public set didLoadProjects(value: boolean) {
    this.store.didLoadProjects = value;
  }

  public set isSearchingProjects(value: boolean) {
    this.store.isSearchingProjects = value;
  }

  public get areNoProjectsAvailable(): boolean {
    return (
      this.didLoadProjects &&
      !this.store.hasProjects &&
      !this.isSearchingProjects &&
      !this.isSearchingMoreProjects
    );
  }

  public get projectTitlesByJobId(): Map<number, string> {
    return this.store.projectTitlesByJobId;
  }

  public get lastSelectedSourcingProjectId(): number | undefined {
    return this.store.lastSelectedSourcingProjectId;
  }

  public set lastSelectedSourcingProjectId(lastSelectedSourcingProjectId: number | undefined) {
    this.store.lastSelectedSourcingProjectId = lastSelectedSourcingProjectId;
  }

  public get isProjectFromDeepLinkRemoved(): boolean {
    return this.store.isProjectFromDeepLinkRemoved;
  }

  public set isProjectFromDeepLinkRemoved(value: boolean) {
    this.store.isProjectFromDeepLinkRemoved = value;
  }

  private getSearchParams(): Partial<SearchProjects> {
    // Search Params
    const searchParams: Partial<SearchProjects> = {
      includeTotalCandidates: true,
      pagination: this.store.pagination,
    };

    if (this.store.copilot === true) {
      searchParams.copilot = this.store.copilot;
    }

    if (this.store.locations.length > 0) {
      searchParams.locations = this.store.locations;
    }

    if (this.store.title) {
      searchParams.title = this.store.title;
    }

    if (this.store.titleOrReqId) {
      searchParams.titleOrReqId = this.store.titleOrReqId;
    }

    if (this.store.status) {
      searchParams.status = this.store.status;
    }

    if (this.store.teamIds.length) {
      searchParams.teamIds = this.store.teamIds;
    }

    if (this.store.source) {
      searchParams.source = this.store.source;
    }

    return searchParams;
  }

  public async searchProjects(
    opts: { skipOnSameSearch?: boolean; skipTracking?: boolean; pageSource?: PageSource } = {
      skipOnSameSearch: false,
      skipTracking: false,
    },
  ): Promise<void> {
    try {
      this.store.isSearchingProjects = true;

      // Reset pagination
      this.store.pagination.page = 1;

      // Skip if search params are the same unless skipOnSameSearch is false
      if (opts.skipOnSameSearch && isEqual(this.store.searchParams, this.getSearchParams())) {
        return;
      }

      this.store.searchParams = this.getSearchParams();

      const result = await this.sharedPersistence.searchProjects({
        employerId: this.meService.employer?.id as number,
        search: this.store.searchParams,
      });

      this.store.projects = result.projects;
      this.fillProjectTitlesByJobId(this.store.projects);

      if (!opts?.skipTracking) {
        if (opts.pageSource === PageSource.Sourcing) {
          TrackingService.trackAction(TrackingActionName.SOURCING_PAGE_JOBS_FILTERED_OR_SEARCHED, {
            filters: {
              textual_search: this.title,
              copilot_status: this.copilot,
              location: this.locations,
              status: this.status,
              teamIds: this.teamIds,
            },
            source: 'Sourcing',
          });
        }

        if (opts.pageSource === PageSource.Conversations) {
          TrackingService.trackAction(
            TrackingActionName.CONVERSATIONS_PAGE_JOBS_FILTERED_OR_SEARCHED,
            {
              filters: {
                locations: this.locations,
                team: this.teamIds,
                hide_archived: this.status === 'live',
                textual_search: this.title,
              },
            },
          );
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.store.isSearchingProjects = false;
    }
  }

  public async searchMoreProjects(): Promise<void> {
    try {
      this.store.isSearchingMoreProjects = true;

      // Increment page
      this.store.pagination.page = this.store.pagination.page + 1;

      const searchParams = this.getSearchParams();

      const result = await this.sharedPersistence.searchProjects({
        employerId: this.meService.employer?.id as number,
        search: searchParams,
      });

      this.store.projects = [...this.store.projects, ...result.projects];
      this.fillProjectTitlesByJobId(this.store.projects);
    } catch (error) {
      throw error;
    } finally {
      this.store.isSearchingMoreProjects = false;
    }
  }

  public async getProjectById(projectId: number): Promise<Project> {
    const employerId = this.meService.employer?.id as number;
    const project = await this.sharedPersistence.getProjectById(employerId, projectId);

    if (!project) {
      throw new Error('Project not found');
    }

    // Will update the project in the store if it exists
    const index = this.store.projects.findIndex((p) => p.id === project.id);
    if (index !== -1) {
      this.store.projects[index] = project;
    }
    this.store.currentProject = project;
    return project;
  }

  public selectFirstProject(): ProjectWithRelations<'candidates'> | undefined {
    const project = this.store.projects[0];
    if (project) {
      this.store.currentProject = project;
      return project;
    }
  }

  public async getProjectByJobIdFromDeepLink(jobId: number): Promise<Project> {
    const employerId = this.meService.employer?.id as number;
    let project: ProjectWithRelations<'candidates'> | undefined;

    try {
      project = await this.sharedPersistence.getProjectByJobId(employerId, jobId);

      if (!project) {
        throw new InternalError('Project not found', {
          data: { employerId, jobId },
        });
      }
    } catch (error) {
      if (
        error instanceof InternalError &&
        JSON.parse((error as InternalError).message).message === 'Project is removed'
      ) {
        this.store.isProjectFromDeepLinkRemoved = true;
        return {} as Project;
      }

      throw error;
    }

    // Remove the current project from the store if it exists and add it to the top
    this.store.projects = this.store.projects.filter((p) => p.id !== project?.id);
    this.store.projects.unshift(project);
    this.store.currentProject = project;

    return project;
  }

  public getLocalProjectById(projectId: number): Project | undefined {
    return this.projects.find((project) => project.id === projectId);
  }

  public async searchAvailableProjects(): Promise<void> {
    const result = await this.sharedPersistence.searchProjects({
      employerId: this.meService.employer?.id as number,
      search: {},
    });

    this.store.hasProjects = result.projects.length > 0;
  }

  public async fetchProjectsTitleByJobIds(jobIds: number[]): Promise<void> {
    // filter out project's jobIds that are already in the store
    const existingJobIds = this.store.projects.map((project) => project.jobId);
    const missingJobIds = jobIds.filter((jobId) => !existingJobIds.includes(jobId));

    if (missingJobIds.length === 0) {
      return;
    }

    const result = await this.sharedPersistence.searchProjects({
      employerId: this.meService.employer?.id as number,
      search: {
        jobIds: missingJobIds,
      },
    });

    this.fillProjectTitlesByJobId(result.projects);
  }

  private fillProjectTitlesByJobId(projects: ProjectWithRelations<'candidates'>[]): void {
    projects.forEach((project) => {
      this.store.projectTitlesByJobId.set(project.jobId, project.title);
    });
  }

  public async getEmployerLocations(): Promise<void> {
    const result = await this.sharedPersistence.getEmployerLocations({
      employerId: this.meService.employer?.id as number,
    });

    this.store.employerLocations = result.locations;
  }

  public async addWatcher(
    projectId: number,
    userProfileId: number,
    watcher?: NonNullable<Project['watchers']>[number],
  ): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.addWatcher(employerId, projectId, userProfileId);

    // If we have the watcher, add it to the project locally
    if (watcher) {
      const project = this.store.projects.find((project) => project.id === projectId);
      if (project) {
        if (Array.isArray(project.watchers)) {
          project.watchers.push(watcher);
        } else {
          project.watchers = [watcher];
        }
      }
    }
  }

  public async removeWatcher(projectId: number, userProfileId: number): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.removeWatcher(employerId, projectId, userProfileId);

    // Remove watcher from the project locally
    const project = this.store.projects.find((project) => project.id === projectId);
    if (project && project.watchers) {
      project.watchers = project.watchers.filter((watcher) => watcher.id !== userProfileId);
    }
  }

  public async updateAssignee(
    projectId: number,
    userProfileId: number,
    assignee?: Project['assignee'],
  ): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.updateAssignee(employerId, projectId, userProfileId);

    // If we have the assignee, add it to the project locally
    if (assignee) {
      const project = this.store.projects.find((project) => project.id === projectId);
      if (project) {
        project.assignee = assignee;
      }
    }
  }

  public async archiveProject(projectId: number, wasCopilotActivated?: boolean): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.archiveProject(employerId, projectId);

    if (wasCopilotActivated) {
      this.subscriptionService.updateCopilotsUsedLocalValue(-1);
    }

    // Update project locally
    const project = this.store.projects.find((project) => project.id === projectId);
    if (project) {
      project.copilot = false;
      project.status = ProjectStatus.ARCHIVED;
    }
  }

  public async activateProject(projectId: number): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.activateProject(employerId, projectId);

    // Update project locally
    const project = this.projects.find((project) => project.id === projectId);
    if (project) {
      project.status = ProjectStatus.LIVE;
    }
  }

  public async deleteProject(projectId: number): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.deleteProject(employerId, projectId);

    // Remove project from the store
    this.store.projects = this.store.projects.filter((project) => project.id !== projectId);
    this.store.currentProject = this.store.projects?.[0] || undefined;
  }

  public async updateProject(projectId: number, data: UpdateProjectInput): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.updateProject(employerId, projectId, data);
  }

  public async duplicateProject(projectId: number, data: DuplicateProjectInput): Promise<Project> {
    const employerId = this.meService.employer?.id as number;
    const project = this.sharedPersistence.duplicateProject(employerId, projectId, data);

    return project;
  }

  public async createProject(data: CreateProjectInput): Promise<Project> {
    const employerId = this.meService.employer?.id as number;
    const project = await this.sharedPersistence.createProject(employerId, data);

    return project;
  }

  public async updateProjectCopilotStatus(projectId: number, enabled: boolean): Promise<void> {
    const employerId = this.meService.employer?.id as number;
    await this.sharedPersistence.updateCopilotStatus(employerId, projectId, enabled);

    this.subscriptionService.updateCopilotsUsedLocalValue(enabled ? 1 : -1);

    // Update project locally
    const project = this.projects.find((project) => project.id === projectId);
    if (project) {
      project.copilot = enabled;
    }
  }

  public updateLocalProjectJobApplicationStatusCount({
    jobId,
    newStatus,
    previousStatus,
  }: {
    jobId: number;
    newStatus: JobApplicantStatus;
    previousStatus: JobApplicantStatus;
  }): void {
    // This will avoid double counting when the status is the same
    if (newStatus === previousStatus) return;

    // Update on selected project if it matches the jobId
    const currentProject = this.store.currentProject;
    if (currentProject?.jobId === jobId && currentProject.candidates) {
      // To avoid negative values
      const previousStatusCount = (currentProject.candidates as Record<JobApplicantStatus, number>)[
        previousStatus
      ];
      (currentProject.candidates as Record<JobApplicantStatus, number>)[previousStatus] -=
        previousStatusCount <= 0 ? 0 : 1;
      (currentProject.candidates as Record<JobApplicantStatus, number>)[newStatus] += 1;
    }
  }
}
