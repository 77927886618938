import type { JobTitle } from '@factoryfixinc/ats-interfaces';
import { useJobBoardSearchApi } from '@/composables/useApi';
import { InternalError } from '@/core/shared/errors/internal.error';

export class JobTitlePersistence {
  async normalizeJobTitle(displayTitle: string): Promise<JobTitle | null> {
    const encodedDisplayTitle = encodeURIComponent(displayTitle);
    const path = `/normalize-job-title-full-obj?jobTitle=${encodedDisplayTitle}`;
    const { data, error, statusCode } = await useJobBoardSearchApi(path).get().json<JobTitle>();

    if (error.value) {
      throw new InternalError(`Could not fetch normalized job title`, {
        error: error.value,
        status: statusCode.value,
        data: { displayTitle },
      });
    }

    return data.value ?? null;
  }
}
