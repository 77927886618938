<script setup lang="ts">
import { computed } from 'vue';
import LeftPurpleHighlightSvg from '@/assets/svg/conversations/selected-conversation-vertical-line.svg';

const props = defineProps<{
  selectedTheme?: boolean;
}>();

const isSelectedTheme = computed(() => props.selectedTheme);
</script>

<template>
  <div
    class="relative box-border flex h-[102px] w-full flex-wrap rounded-lg p-3"
    :class="{
      'bg-highlight-0': isSelectedTheme,
    }"
  >
    <!-- Left Highlight -->
    <img
      v-if="isSelectedTheme"
      :src="LeftPurpleHighlightSvg"
      class="absolute left-0 top-0 h-full"
    />

    <!-- First line -->
    <div
      class="h-2 w-2/6 rounded-full"
      :class="{
        'linear-loading-highlight': isSelectedTheme,
        'linear-loading-tint': !isSelectedTheme,
      }"
    ></div>
    <div class="h-2 w-3/6"></div>
    <div
      class="linear-loading-highlight h-2 w-1/6 rounded-full"
      :class="{
        'linear-loading-highlight': isSelectedTheme,
        'linear-loading-tint': !isSelectedTheme,
      }"
    ></div>
    <!-- Second line -->
    <div
      class="linear-loading-highlight h-2 w-full rounded-full"
      :class="{
        'linear-loading-highlight': isSelectedTheme,
        'linear-loading-tint': !isSelectedTheme,
      }"
    ></div>
    <!-- Third Line -->
    <div class="flex h-4 w-full items-center">
      <div
        class="linear-loading-highlight mr-2 h-2 w-1/6 rounded-full"
        :class="{
          'linear-loading-highlight': isSelectedTheme,
          'linear-loading-tint': !isSelectedTheme,
        }"
      ></div>
      <div
        v-for="i in 5"
        :key="i"
        class="linear-loading-highlight mr-1 h-4 w-4 rounded"
        :class="{
          'linear-loading-highlight': isSelectedTheme,
          'linear-loading-tint': !isSelectedTheme,
        }"
      ></div>
    </div>
  </div>
</template>
