<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import draggable from 'vuedraggable';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import DragDotsSvg from '@/assets/svg/jobs/drag-dots.svg?component';
import TrashIcon from '@/assets/svg/conversations/TrashBigIcon.vue';
import PlusIcon from '@/assets/svg/plus-16.svg?component';
import CopilotLightningIcon from '@/assets/svg/jobs/copilot-lightning.svg?component';
import GenerateScreenerQuestionsAnimation from '@/components/Jobs/CopilotActivation/GenerateScreenerQuestionsAnimation.vue';
import {
  type LocalScreenerQuestion,
  MAXIMUN_ALLOWED_SCREENER_QUESTIONS,
  MINIMUM_REQUIRED_SCREENER_QUESTIONS,
} from '@/core/jobs/copilot-activation/types/local-screener-question.type';
import MessageTemplate from '@/components/Shared/Input/Template/MessageTemplate.vue';
import { clone } from '@/utils/objects/clone.util';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import VersionsService from '@/core/jobs/versions/versions.service';
import { JobVersionViewStatus } from '@/core/jobs/versions/types/job-version-history.type';
import { ScreeningType } from '@factoryfixinc/ats-interfaces';

const copilotActivationService = new CopilotActivationService();
const jobVersionsService = new VersionsService();

const props = defineProps<{
  disabled: boolean;
}>();

const isFormDirty = ref(false);
const suggestions = ref<LocalScreenerQuestion[]>([]);

const hasReachedMaximumAllowedQuestionsNumber = computed(() => {
  return copilotActivationService.screenerQuestions.length >= MAXIMUN_ALLOWED_SCREENER_QUESTIONS;
});

const hasAvailableSpaceForSuggestions = computed(() => {
  const filledQuestions = copilotActivationService.screenerQuestions.filter(
    (question) => question.text.trim().length > 0,
  );
  return filledQuestions.length < MAXIMUN_ALLOWED_SCREENER_QUESTIONS;
});

const isGeneratingScreenerQuestions = computed(() => {
  return copilotActivationService.isGeneratingScreenerQuestions;
});
const generatedScreenerQuestionsDocument = computed(() => {
  return copilotActivationService.generatedScreenerQuestionsDocument;
});

const generateScreenerQuestionsBtnText = computed(() => {
  return isGeneratingScreenerQuestions.value ? 'Stop generating' : 'Generate screener questions';
});

const fetchedSuggestions = computed(() => {
  return (
    generatedScreenerQuestionsDocument.value?.screeningQuestions?.generatedScreeningQuestions ?? []
  );
});

const availableSuggestions = computed<LocalScreenerQuestion[]>(() => {
  const selectedQuestionsTexts = copilotActivationService.screenerQuestions.map(
    (question) => question.text,
  );

  const availableSuggestions = suggestions.value.filter((suggestion) => {
    return !selectedQuestionsTexts.includes(suggestion.text);
  });

  return availableSuggestions;
});

const hasSuggestions = computed<boolean>(() => {
  return availableSuggestions.value.length > 0;
});

const areScreenerQuestionsValid = computed<boolean>(() => {
  if (copilotActivationService.screeningType === ScreeningType.DYNAMIC) {
    return true;
  }
  const validQuestions = copilotActivationService.screenerQuestions.filter(
    (question) => question.text.trim().length > 0,
  );

  return (
    validQuestions.length >= MINIMUM_REQUIRED_SCREENER_QUESTIONS &&
    validQuestions.length <= MAXIMUN_ALLOWED_SCREENER_QUESTIONS
  );
});

const hasTriedToUpdateJob = computed(() => {
  return copilotActivationService.hasTriedToUpdateJob;
});

const shouldShowError = computed<boolean>(() => {
  return hasTriedToUpdateJob.value && !areScreenerQuestionsValid.value;
});

function cleanQuestion(id: number) {
  const localQuestions = clone(copilotActivationService.screenerQuestions);
  const index = localQuestions.findIndex((question) => question.id === id);
  localQuestions[index].text = '';

  copilotActivationService.screenerQuestions = localQuestions;
}

function removeQuestion(id: number) {
  // Remove the questions and then update the array for the reactivity
  const newArray = copilotActivationService.screenerQuestions.filter(
    (question) => question.id !== id,
  );
  copilotActivationService.screenerQuestions = newArray;
}

function getNewQuestion(): LocalScreenerQuestion {
  const ids = copilotActivationService.screenerQuestions.map((question) => question.id);
  const suggestionIds = suggestions.value.map((question) => question.id);
  const usedIds = [...ids, ...suggestionIds];

  let newId = Math.floor(Math.random() * 1000);
  while (usedIds.includes(newId)) {
    newId = Math.floor(Math.random() * 1000);
  }

  const question = {
    text: '',
    id: newId,
  };

  return question;
}

function addNewQuestion() {
  const newQuestion = getNewQuestion();
  copilotActivationService.screenerQuestions = [
    ...copilotActivationService.screenerQuestions,
    newQuestion,
  ];
}

function addSuggestion(question: LocalScreenerQuestion) {
  // If there is any empty question, we will replace it with the suggestion
  const localQuestions = clone(copilotActivationService.screenerQuestions);
  const emptyQuestionIndex = localQuestions.findIndex((q) => q.text.trim().length === 0);

  if (emptyQuestionIndex !== -1) {
    localQuestions[emptyQuestionIndex] = question;
    copilotActivationService.screenerQuestions = localQuestions;
  } else {
    copilotActivationService.screenerQuestions = [
      ...copilotActivationService.screenerQuestions,
      question,
    ];
  }
}

function setFormAsDirty() {
  isFormDirty.value = true;
}

watch(fetchedSuggestions, (value) => {
  const parsedQuestions: LocalScreenerQuestion[] = [];

  if (Array.isArray(value)) {
    for (const question of value) {
      const addingQuestion = getNewQuestion();
      addingQuestion.text = question;
      parsedQuestions.push(addingQuestion);
    }
  }

  suggestions.value = parsedQuestions;
});

async function generateSuggestions() {
  await copilotActivationService.generateScreenerQuestions();
}

async function handleGenerateSuggestions() {
  if (isGeneratingScreenerQuestions.value) {
    copilotActivationService.stopGeneratingScreenerQuestions();
  } else {
    TrackingService.trackAction(TrackingActionName.SCREENERS_GENERATED, {
      project_id: copilotActivationService.selectedProjectId,
      job_id: copilotActivationService.selectedJobId,
    });
    await generateSuggestions();
  }
}
const isJobVersionViewMode = computed(() => {
  return jobVersionsService.currentJobVersionViewStatus === JobVersionViewStatus.VIEW_MODE;
});

const isDisabled = computed(() => {
  return (
    props.disabled ||
    copilotActivationService.screenerQuestions.length <= MINIMUM_REQUIRED_SCREENER_QUESTIONS
  );
});
</script>

<template>
  <div>
    <draggable
      v-model="copilotActivationService.screenerQuestions"
      itemKey="id"
      group="textareas"
      class="mb-10"
    >
      <template #item="{ element }">
        <v-text-field
          v-model.trim="element.text"
          placeholder="Add screening question"
          variant="outlined"
          density="compact"
          class="mb-[30px] last:mb-0"
          hide-details
          @keydown="setFormAsDirty"
          :disabled="disabled"
        >
          <!-- outer drag | delete icons -->
          <!-- Drag -->
          <template #prepend>
            <div class="question-draggable-handle cursor-pointer">
              <DragDotsSvg />
            </div>
          </template>

          <!-- Clean -->
          <template #append-inner>
            <button class="clean-btn cursor-pointer" @click="cleanQuestion(element.id)">
              <PlusIcon class="rotate-45" />
            </button>
          </template>

          <!-- Delete -->
          <template #append>
            <button
              class="disabled:hidden"
              :disabled="isDisabled"
              @click="removeQuestion(element.id)"
            >
              <TrashIcon class="text-shade-800" />
            </button>
          </template>
        </v-text-field>
      </template>
    </draggable>
    <div v-if="shouldShowError" class="v-field--error -mt-6 mb-10 ml-14">
      <MessageTemplate
        message="3 to 7 Screening Questions are required to proceed."
        class="text-[#B00020]"
      />
    </div>

    <!-- Add questions -->
    <div class="mb-6">
      <div class="add-question-div">
        <button
          v-show="!isJobVersionViewMode"
          class="flex h-8 w-full items-center justify-center rounded-md border-2 border-shade-880 px-3 py-1.5 disabled:opacity-50"
          :disabled="hasReachedMaximumAllowedQuestionsNumber"
          type="button"
          @click="addNewQuestion"
        >
          <span>
            <PlusIcon class="mr-2" />
          </span>
          <span class="text-sm font-semibold leading-5 text-shade-880"> Add question </span>
        </button>
      </div>
    </div>

    <!-- Generate Suggested Questions -->
    <div class="mb-10">
      <div class="add-question-div">
        <button
          v-show="!isJobVersionViewMode"
          class="flex h-8 w-full items-center justify-center rounded-md px-3 py-1.5"
          :class="{
            'bg-shade-900': !isGeneratingScreenerQuestions,
            'rounded-md border-2 border-shade-880': isGeneratingScreenerQuestions,
          }"
          type="button"
          @click="handleGenerateSuggestions"
        >
          <span>
            <CopilotLightningIcon class="mr-2" />
          </span>
          <span
            class="text-sm font-semibold leading-5 text-tint-20"
            :class="{
              'text-shade-900': isGeneratingScreenerQuestions,
              'text-tint-20': !isGeneratingScreenerQuestions,
            }"
          >
            {{ generateScreenerQuestionsBtnText }}
          </span>
        </button>
      </div>
      <GenerateScreenerQuestionsAnimation v-show="isGeneratingScreenerQuestions" class="mt-6" />
    </div>

    <!-- Suggested Questions -->
    <div v-if="hasSuggestions" class="mb-10">
      <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">Suggestions</p>
      <div v-for="suggestion in availableSuggestions" :key="suggestion.id">
        <button
          :disabled="!hasAvailableSpaceForSuggestions"
          class="mb-2 flex cursor-pointer items-center justify-between rounded-full border border-tint-80 px-3 py-1.5 disabled:opacity-50"
          @click="addSuggestion(suggestion)"
        >
          <div class="inline-block">
            <span>{{ suggestion.text }}</span>
          </div>
          <div class="inline-block">
            <PlusIcon class="ml-2" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  .clean-btn {
    display: none;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }

    .clean-btn {
      display: block;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}
</style>
