import {
  type Job,
  JobStatus,
  type JobWithRelations,
  PayRatePeriod,
} from '@factoryfixinc/ats-interfaces';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export class JobUtil {
  static getLocation(job?: Job, options?: { format: 'short' }): string {
    const notFound = 'Unspecified Location';

    if (!job) {
      return notFound;
    }

    if (options?.format === 'short') {
      if (!job.state || !job.city) {
        return notFound;
      }

      return `${job.city}, ${job.state}`;
    }

    // handle special cases first
    if (!job.state || !job.city) {
      if (job.street1 && job.postalCode) {
        return `${job.street1} ${job.postalCode}`;
      }

      return notFound;
    }

    // handle generically
    let addr = '';
    addr = this.appendWithSeparator(addr, job.street1);
    addr = this.appendWithSeparator(addr, job.city);
    addr = this.appendWithSeparator(addr, job.state);
    addr = this.appendWithSeparator(addr, job.postalCode, ' ');
    return addr || notFound;
  }

  static getShift(job?: Job): string {
    if (!job) {
      return 'No Shift';
    }

    if (job?.shift === null) {
      return 'Multiple Shifts';
    }

    const shift = job.shift;
    if (shift === 1) {
      return '1st Shift';
    } else if (shift === 2) {
      return '2nd Shift';
    } else if (shift === 3) {
      return '3rd Shift';
    } else if (shift === 4) {
      return 'Short-term Project';
    } else if (shift === 0) {
      // TODO: remove snake_case after v3 job results are no longer used
      const startTime = job.startTime;
      const endTime = job.endTime;

      if (startTime && endTime) {
        return `${this.formatTimeHelper(startTime)} - ${this.formatTimeHelper(endTime)}`;
      }
    }

    return 'No Shift';
  }

  static getHireType(job?: Job): string {
    return job?.hireType ? job.hireType : 'Unspecified Hire Type';
  }

  static getPayRange(job?: Job): string {
    if (!job) {
      return 'Undisclosed Pay Rate';
    }

    // Salary was added. If a job does not have a payPeriod set, default to hourly.
    if (job.payRatePeriod === PayRatePeriod.HOURLY || !job.payRatePeriod) {
      if (job.payRateMin && job.payRateMax) {
        return `$${job.payRateMin} - $${job.payRateMax} /hr`;
      }
      if (job.payRateMin) {
        return `$${job.payRateMin} /hr`;
      }
      if (job.payRateMax) {
        return `$${job.payRateMax} /hr`;
      }
    }

    if (job.payRatePeriod === PayRatePeriod.ANNUAL) {
      if (job.payRateSalaryMin && job.payRateSalaryMax) {
        return `${this.shortenSalary(job.payRateSalaryMin)} - ${this.shortenSalary(
          job.payRateSalaryMax,
        )} /yr`;
      }
      if (job.payRateSalaryMin) {
        return `${this.shortenSalary(job.payRateSalaryMin)} /yr`;
      }
      if (job.payRateSalaryMax) {
        return `${this.shortenSalary(job.payRateSalaryMax)} /yr`;
      }
    }

    return 'Undisclosed Pay Rate';
  }

  static getJobLiveStatusDuration(
    job?: JobWithRelations<'jobStatusHistories'>,
  ): string | undefined {
    if (!job?.jobStatusHistories?.length) {
      return;
    }

    const liveDate = job.jobStatusHistories
      .sort((a, b) => new Date(b.createTs).getTime() - new Date(a.createTs).getTime())
      .find((history) => history.status === JobStatus.LIVE)?.createTs;

    if (!liveDate) {
      return;
    }

    const timeAgo = dayjs().to(dayjs(liveDate));
    const date = dayjs(liveDate).format('LL');

    return `${timeAgo} (${date})`;
  }

  private static formatTimeHelper(time: string): string {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const dateTimeString = `${currentDate} ${time}`;

    return dayjs(dateTimeString, 'YYYY-MM-DD h:mmA').format('LT');
  }

  private static shortenSalary(salary: number): string {
    return `$${Math.round(salary / 1000)}k`;
  }

  private static appendWithSeparator(base: string, item?: string, separator = ', '): string {
    if (!base.length) {
      return item ?? '';
    }

    if (item) {
      return base + separator + item;
    } else {
      return base;
    }
  }
}
