<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  vertical?: boolean;
  strong?: boolean;
}>();

// Defaults
const horizontal = computed(() => !props.vertical);
const faint = computed(() => !props.strong);
</script>

<template>
  <div>
    <div
      :class="{
        'h-[1px] w-full': horizontal,
        'h-full  w-[1px]': vertical,
        'bg-tint-100': strong,
        'bg-tint-60': faint,
      }"
    >
      &nbsp;
    </div>
  </div>
</template>
