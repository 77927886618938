<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  total: number;
  used: number;
}>();

const addOnCount = computed(() => props.used - props.total);
const hasAddOns = computed(() => addOnCount.value > 0);
const usedSlots = computed(() =>
  hasAddOns.value ? props.used - addOnCount.value : props.used ?? 0,
);
</script>

<template>
  <div v-show="total" class="inline-flex flex-col items-center text-[10px]">
    <span class="font-bold text-white">Job Slots</span>
    <span class="text-white">{{ usedSlots }}/{{ total }}</span>
    <template v-if="hasAddOns">
      <span class="text-tint-400">+{{ addOnCount }}</span>
      <span class="text-tint-400">add-on</span>
    </template>
  </div>
</template>
