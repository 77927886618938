<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import debounce from 'lodash/debounce';
import type { PlacePrediction } from '@/core/shared/search/types/place-prediction.type';

import { SearchService } from '@/core/shared/search/search.service';

import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.vue';

import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
}>();

const router = useRouter();
const searchService = new SearchService();
const projectService = new ProjectService();

const isEnablingCopilot = ref<boolean>(false);
const isCreatingJob = ref<boolean>(false);
const localSelectedJobTitle = ref<string>('');
const localSelectedLocation = ref<string | null>(null);
const locationPredictions = ref<PlacePrediction[]>([]);
const populateAddressPredictions = debounce(_populateAddressPredictions, 300);

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

const canCreate = computed<boolean>(() => {
  return Boolean(localSelectedJobTitle.value?.trim() && localSelectedLocation.value?.trim());
});

const isCreateButtonDisabled = computed<boolean>(() => {
  return !canCreate.value || isCreatingJob.value;
});

function handleUpdateModelValue(value: boolean, trackAction: boolean = true) {
  if (trackAction) {
    TrackingService.trackAction(TrackingActionName.NEW_SEARCH_ABANDONED, {
      job_title: localSelectedJobTitle.value || '',
      location: localSelectedLocation.value || '',
    });
  }
  emit('update:modelValue', value);
}

async function _populateAddressPredictions(address: string) {
  if (!address || !address?.trim()) {
    locationPredictions.value = [];
  } else {
    locationPredictions.value = await searchService.getAddressPredictions(address);
  }
}

function clearInputs() {
  localSelectedJobTitle.value = '';
  localSelectedLocation.value = '';
}

async function handleCreateJob() {
  try {
    isCreatingJob.value = true;

    if (!localSelectedJobTitle.value?.trim() || !localSelectedLocation.value?.trim()) {
      return;
    }

    const project = await projectService.createProject({
      title: localSelectedJobTitle.value,
      location: localSelectedLocation.value,
    });

    await TrackingService.trackAction(TrackingActionName.NEW_SEARCH_COMPLETED, {
      project_id: project.id,
      job_id: project.jobId,
      job_title: localSelectedJobTitle.value,
      location: localSelectedLocation.value,
    });

    handleUpdateModelValue(false, false);
    isCreatingJob.value = false;

    if (isEnablingCopilot.value) {
      await TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
        source: 'new_search',
        project_id: project.id,
        job_id: project.jobId,
      });
      await router.push(`/jobs/${project.id}/copilot-activation`);
    } else {
      clearInputs();
      await projectService.searchProjects({ skipTracking: true });
      await router.push(`/sourcing/job/${project.id}`);
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to create job. Please try again later.');
  } finally {
    isCreatingJob.value = false;
  }
}
</script>

<template>
  <v-dialog :model-value="isOpen" max-width="600px" height="550px" persistent>
    <div class="rounded-2xl bg-white p-8">
      <img
        class="float-right mt-1 cursor-pointer"
        src="@/assets/svg/close-black.svg"
        alt="close"
        width="20"
        height="20"
        @click="handleUpdateModelValue(false)"
      />
      <p class="mb-6 font-serif text-2xl font-black text-copilot">Create a new search</p>
      <!-- Title -->
      <div class="mb-6">
        <p class="mb-1 text-sm font-bold text-copilot">
          What job title are you looking to hire for?
        </p>
        <input
          v-model="localSelectedJobTitle"
          type="text"
          placeholder="eg. Maintenance Technician."
          class="w-full rounded border-[1px] border-tint-80 px-3 py-2 text-sm focus:border-highlight-500 focus:outline-none"
        />
      </div>

      <!-- Location -->
      <div class="mb-0.5">
        <p class="mb-1 text-sm font-bold text-copilot">Where is the job located?</p>
        <v-combobox
          v-model:model-value="localSelectedLocation"
          :items="locationPredictions"
          item-title="value"
          item-value="value"
          variant="outlined"
          density="compact"
          placeholder="eg. Chicago, IL"
          :menu-icon="ChevronIcon"
          :return-object="false"
          :menu-props="{ maxWidth: '536px' }"
          @update:search="populateAddressPredictions"
        >
        </v-combobox>
      </div>

      <!-- Actions -->
      <div class="flex items-center justify-end">
        <v-btn
          :ripple="false"
          class="modal-button-text"
          variant="flat"
          @click="handleUpdateModelValue(false)"
          >Cancel</v-btn
        >
        <v-btn
          :ripple="false"
          :disabled="isCreateButtonDisabled"
          :loading="isCreatingJob"
          class="modal-button-primary ml-4"
          variant="flat"
          @click="handleCreateJob"
          >Search</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="postcss">
:deep(.v-field) {
  border-radius: 6px;

  .v-field__input {
    @apply text-sm;
  }
  .v-field__outline {
    &__start,
    &__end {
      opacity: 1;
      @apply border-y border-tint-80;
    }
  }

  &--focused {
    .v-field__outline {
      opacity: 1;
      &__start {
        @apply border-l border-highlight-500;
      }
      &__end {
        @apply border-r border-highlight-500;
      }
    }
  }
}
</style>
