import { useFFRestApi } from '@/composables/useApi';
import type {
  Filter,
  UserProfileRelation,
  UserProfileWithRelations,
} from '@factoryfixinc/ats-interfaces';

export class ProProfilePersistence {
  private async getById<Relation extends UserProfileRelation>(
    userProfileId: number,
    employerId: number,
    filter: Filter = {},
  ): Promise<UserProfileWithRelations<Relation> | undefined> {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    const path = `/employer/${employerId}/pro/${userProfileId}?filter=${encodedFilter}`;

    const { data: userProfile, error } = await useFFRestApi(path)
      .get()
      .json<UserProfileWithRelations<Relation>>();

    if (error?.value || !userProfile.value) {
      throw new Error(error.value);
    }
    return userProfile.value;
  }

  getByIdWithRelations<Relation extends UserProfileRelation>(
    userProfileId: number,
    employerId: number,
    relations: Relation[],
  ): Promise<UserProfileWithRelations<Relation> | undefined> {
    const filter: Filter = {
      include: [],
    };

    if (relations.includes('pro' as Relation) && filter.include) {
      filter.include.push({
        relation: 'pro',
        scope: {
          include: [
            { relation: 'proTaxonomyIndustryMaps' },
            { relation: 'proTaxonomyKnowledgeDisciplineMaps' },
            {
              relation: 'proTaxonomyMachineMaps',
              scope: {
                include: [{ relation: 'proTaxonomyMachineMapTaxonomyBrandMaps' }],
              },
            },
          ],
        },
      });
    }

    if (relations.includes('userAuthGroup' as Relation) && filter.include) {
      filter.include.push({ relation: 'userAuthGroup' });
    }

    if (relations.includes('userRoles' as Relation) && filter.include) {
      filter.include.push({ relation: 'userRoles' });
    }

    if (relations.includes('employerUserMaps' as Relation) && filter.include) {
      filter.include.push({
        relation: 'employerUserMaps',
        scope: {
          include: [
            {
              relation: 'employer',
              scope: {
                include: [{ relation: 'subscription' }],
              },
            },
          ],
        },
      });
    }

    if (relations.includes('proEducationCertifications' as Relation) && filter.include) {
      filter.include.push({ relation: 'proEducationCertifications' });
    }

    if (relations.includes('proHistoryEmployers' as Relation) && filter.include) {
      filter.include.push({ relation: 'proHistoryEmployers' });
    }

    if (relations.includes('proReferences' as Relation) && filter.include) {
      filter.include.push({ relation: 'proReferences' });
    }

    if (relations.includes('proAchievementMaps' as Relation) && filter.include) {
      filter.include.push({
        relation: 'proAchievementMaps',
        scope: {
          include: [{ relation: 'achievement' }],
        },
      });
    }

    if (relations.includes('jobApplicants' as Relation) && filter.include) {
      filter.include.push({
        relation: 'jobApplicants',
        scope: {
          include: [
            {
              relation: 'job',
              scope: {
                include: [{ relation: 'jobTitle' }],
              },
            },
          ],
        },
      });
    }

    return this.getById<Relation>(userProfileId, employerId, filter);
  }
}
