import { defineStore } from 'pinia';
import type { EmployerDashboardEmployer } from './interfaces/employer-dashboard-employer.interface';
import { ref } from 'vue';
import type { EmployerDashboard } from './interfaces/employer-dashboard.interface';

export const useEmployerDashboardStore = defineStore(
  'employerDashboard',
  () => {
    const employer = ref<EmployerDashboardEmployer | null>(null);
    const employerDashboards = ref<Array<EmployerDashboard>>([]);

    return {
      employer,
      employerDashboards,
    };
  },
  {
    persist: false,
  },
);
