import {
  type EmployerWithRelations,
  GroupMembership,
  type UserProfile,
  type UserProfileWithRelations,
} from '@factoryfixinc/ats-interfaces';

import { useMeStore } from './me.store';

import { useFFRestApi } from '@/composables/useApi';
import { hasAuthGroup } from '@/utils/authorization/has-auth-group.util';
import type { Me } from './types/me.type';
import { MePersistence } from './me.persistence';
import { InternalError } from '@/core/shared/errors/internal.error';
import TrackingService from '../tracking/tracking.service';

export default class MeService {
  private store = useMeStore();
  private persistence = new MePersistence();
  private trackingService = TrackingService.instance;

  public clear(): void {
    this.store.clear();
  }

  public get userProfile(): Me | undefined {
    return this.store.userProfile;
  }

  public get employer():
    | EmployerWithRelations<
        'assignee' | 'employerWatcherMaps' | 'subscription' | 'employerIndustries'
      >
    | undefined {
    return this.store.employer;
  }

  public get isEmployerUser(): boolean {
    return (
      hasAuthGroup(this.store.userProfile, GroupMembership.COMPANY_ADMIN) ||
      hasAuthGroup(this.store.userProfile, GroupMembership.HIRING_MANAGER)
    );
  }

  public get isEmployerAdmin(): boolean {
    return hasAuthGroup(this.store.userProfile, GroupMembership.COMPANY_ADMIN);
  }

  public get employerUsers(): UserProfileWithRelations<'userAuthGroup'>[] | undefined {
    return this.store.employerUsers;
  }

  public async fetchMe(): Promise<Me> {
    const { data, error, statusCode } = await useFFRestApi('/user-profile/me').get().json<Me>();

    const userProfile = data.value ?? undefined;
    const status = statusCode.value ?? undefined;

    if (!userProfile || error.value) {
      throw new InternalError('Could not load /me information', {
        error: error.value,
        status,
      });
    }

    this.store.userProfile = userProfile;

    return userProfile;
  }

  public async fetchAndSetEmployer(): Promise<
    EmployerWithRelations<'assignee' | 'employerWatcherMaps' | 'subscription'>
  > {
    const id = this.getSelectedEmployerId();
    if (!id) {
      throw new InternalError('Cannot fetch employer. Employer ID is not set.');
    }

    const filter = encodeURIComponent(
      JSON.stringify({
        include: [
          { relation: 'assignee' },
          { relation: 'employerWatcherMaps' },
          { relation: 'subscription' },
          {
            relation: 'employerIndustries',
            scope: { include: [{ relation: 'industry' }] },
          },
        ],
      }),
    );

    const { data, error } = await useFFRestApi(`/employer/${id}?filter=${filter}`)
      .get()
      .json<
        EmployerWithRelations<
          'assignee' | 'employerWatcherMaps' | 'subscription' | 'employerIndustries'
        >
      >();

    if (!data.value) {
      throw new Error(error.value);
    }

    const employer = data.value;
    this.store.employer = employer;

    this.trackingService.identifyEmployerUser(this.store.userProfile, this.store.employer);

    return employer;
  }

  public getSelectableEmployerIds(): number[] {
    if (!this.store.userProfile?.employerUserMaps?.length) {
      return [];
    }

    return this.store.userProfile.employerUserMaps.map((map) => map.employerId);
  }

  public getSelectedEmployerId(): number | undefined {
    if (!this.store.userProfile?.id) {
      return;
    }

    return this.store.selectedEmployerIds[this.store.userProfile.id];
  }

  public setSelectedEmployerId(employerId: number): boolean {
    const userProfileId = this.userProfile?.id;
    const selectableEmployerIds = this.getSelectableEmployerIds();
    const selectedEmployerBelongsToUser = selectableEmployerIds.includes(employerId);
    const currentSelectedEmployerId = this.getSelectedEmployerId();

    if (
      userProfileId &&
      selectedEmployerBelongsToUser &&
      currentSelectedEmployerId !== employerId
    ) {
      // Set the selected employer for the current user
      this.store.selectedEmployerIds[userProfileId] = employerId;

      return true;
    }

    return false;
  }

  /**
   * If no selected employer has been set, default to the first one
   * that the current user is tied to.
   */
  public async selectDefaultEmployerIfUnset(): Promise<void> {
    const userProfileId = this.store.userProfile?.id;
    const tiedEmployers = this.getSelectableEmployerIds();
    let selectedEmployerId = this.getSelectedEmployerId();

    if (userProfileId && !selectedEmployerId && tiedEmployers.length) {
      selectedEmployerId = tiedEmployers[0];
      this.store.selectedEmployerIds[userProfileId] = selectedEmployerId;
    }
  }

  public redirectToUI2(path: string, params?: Record<string, string | number>): void {
    const ui2BaseURL = import.meta.env.VITE_UI2_BASE_URL;
    const url = new URL(`${ui2BaseURL}${path}`);
    const ui3SelectedEmployerId = this.getSelectedEmployerId();

    // Always include the selected employer ID in the query params
    let query: Record<string, string | number> = {
      fromUI3: 'true',
    };

    if (ui3SelectedEmployerId) {
      query.ui3SelectedEmployerId = String(ui3SelectedEmployerId);
    }

    query = { ...query, ...(params ?? {}) };

    Object.entries(query).forEach(([key, value]) => {
      url.searchParams.append(key, String(value));
    });

    window.open(url.href, '_self');
  }

  public async fetchEmployerUsers(): Promise<UserProfile[] | null> {
    const employerId = this.store.employer?.id;

    if (!employerId) {
      return null;
    }
    const filter = encodeURIComponent(
      JSON.stringify({
        include: [{ relation: 'userAuthGroup' }],
      }),
    );

    const url = `/employer/${employerId}/user-profile?filter=${filter}`;
    const { data, error } = await useFFRestApi(url)
      .get()
      .json<UserProfileWithRelations<'userAuthGroup'>[]>();

    if (error?.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('No employer users found');
    }

    const users = data.value;
    this.store.employerUsers = users;

    return users;
  }

  public isEmployerMember(email: string): boolean {
    return this.store.employerUsers?.some((user) => user.email === email) ?? false;
  }

  public async inviteEmployerMember(email: string): Promise<void> {
    return this.persistence.inviteEmployerMember(email);
  }

  public async sendHelpRequest(email: string, message: string): Promise<void> {
    return this.persistence.sendHelpRequest(email, message);
  }

  public async updateUserProfile(data: Partial<UserProfile>): Promise<void> {
    const employerId = this.store.employer?.id;
    const userProfileId = this.store.userProfile?.id;
    if (!employerId || !userProfileId) return;

    return this.persistence.updateUserProfile(employerId, userProfileId, data);
  }

  public async updateMemberUserProfile(
    userProfileId: UserProfile['id'],
    data: Partial<UserProfile>,
  ): Promise<void> {
    const employerId = this.store.employer?.id;
    if (!employerId) return;

    return this.persistence.updateUserProfile(employerId, userProfileId, data);
  }

  public async updateUserAuthGroup(groupMembership: GroupMembership): Promise<void> {
    const employerId = this.store.employer?.id;
    const userProfileId = this.store.userProfile?.id;
    if (!employerId || !userProfileId) return;

    return this.persistence.updateUserAuthGroup(employerId, userProfileId, groupMembership);
  }

  public async updateMemberUserAuthGroup(
    userProfileId: UserProfile['id'],
    groupMembership: GroupMembership,
  ): Promise<void> {
    const employerId = this.store.employer?.id;
    if (!employerId) return;

    return this.persistence.updateUserAuthGroup(employerId, userProfileId, groupMembership);
  }

  public async updatePassword(password: string): Promise<void> {
    const userProfileId = this.store.userProfile?.id;
    if (!userProfileId) return;

    return this.persistence.updatePassword(userProfileId, password);
  }
}
