<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter();

function navigateToSourcing() {
  router.push({ name: 'sourcing-index' });
}
</script>

<template>
  <div class="flex h-full w-full flex-col pt-4">
    <div class="flex h-8 items-center gap-3 pl-4">
      <div>
        <img src="@/assets/svg/layout-left-black.svg" />
      </div>
      <h2 class="font-serif text-base font-extrabold text-shade-900">Conversations</h2>
    </div>

    <div class="flex h-full w-full flex-col items-center justify-center gap-y-2">
      <div class="flex h-32 w-32 items-center justify-center rounded-full bg-tint-40">
        <img
          src="@/assets/svg/conversations/conversation_messages.svg"
          alt="Conversation messages"
          class="h-16 w-16"
        />
      </div>
      <p class="mt-4 text-sm font-bold text-shade-800">No conversations... yet!</p>
      <p class="max-w-[204px] text-center text-sm text-shade-800">
        Head over to Sourcing to start searching for top talent.
      </p>
      <button
        type="button"
        class="cursor-pointer text-sm font-semibold text-highlight-500 outline-none"
        @click="navigateToSourcing"
      >
        Go to Sourcing
      </button>
    </div>
  </div>
</template>
