export const waitUntil = (condition: () => boolean, interval = 300): Promise<void> =>
  new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (condition()) {
        clearInterval(intervalId);
        resolve();
      }
    }, interval);
  });

export enum PromisePoolAllSettledStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export interface PromisePoolAllSettledResult<T> {
  status: PromisePoolAllSettledStatus;
  value?: T;
  reason?: unknown;
}

export async function promisePoolAllSettled<T, K>(
  poolLimit: number,
  array: Array<T>,
  iteratorFn: (item: T) => Promise<K>,
): Promise<Array<PromisePoolAllSettledResult<K>>> {
  const ret = [];
  const executing: Promise<unknown>[] = [];
  for (const item of array) {
    const p = Promise.resolve()
      .then(() => iteratorFn(item))
      .then((value) => ({ status: PromisePoolAllSettledStatus.Fulfilled, value }))
      .catch((reason) => ({ status: PromisePoolAllSettledStatus.Rejected, reason }));

    ret.push(p);

    if (poolLimit <= array.length) {
      const e: Promise<unknown> = p.then(() => executing.splice(executing.indexOf(e), 1));
      executing.push(e);
      if (executing.length >= poolLimit) {
        await Promise.race(executing);
      }
    }
  }

  return Promise.all(ret);
}

export const getRejectedsErrors = (
  error: PromisePoolAllSettledResult<unknown>[] = [],
): unknown[] => {
  if (!Array.isArray(error)) return [];
  return error
    .filter((e) => e?.status === PromisePoolAllSettledStatus.Rejected)
    .map((e) => e.reason);
};

export const getResolvedDataItems = <T>(error: PromisePoolAllSettledResult<T>[] = []): T[] => {
  if (!Array.isArray(error)) return [];
  const resolvedPromises = error.filter((e) => e?.status === PromisePoolAllSettledStatus.Fulfilled);
  // If was fulfilled, then the value is always defined
  return resolvedPromises.map((p) => p.value as unknown as T);
};
