<script setup lang="ts">
import UserAvatar from '@/components/Shared/AppBar/UserAvatar/UserAvatar.vue';
import { getNameFor } from '@/core/sourcing/utils/userProfile';
import type { UserProfile } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';

const props = defineProps<{
  user: Partial<UserProfile>;
  link?: boolean;
}>();

const email = computed(() => props.user?.email);
const fullName = computed(() => getNameFor(props.user));
</script>

<template>
  <div class="flex h-full w-full items-center">
    <UserAvatar :user="user" />

    <div class="ml-2 flex flex-col">
      <span class="text-sm font-bold">{{ fullName }}</span>
      <span class="text-xs text-shade-800">{{ email }}</span>
    </div>
  </div>
</template>
