import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { PlacePrediction } from '@/core/shared/search/types/place-prediction.type';
import { InternalError } from '@/core/shared/errors/internal.error';

export class SearchPersistence {
  public async getAddressPredictions(address: string): Promise<PlacePrediction[]> {
    const { data, error } = await useAtsCandidateDiscoveryApi(
      `/geolocation/address-predict?address=${address}`,
    ).json<PlacePrediction[]>();

    if (error.value) {
      throw new InternalError('Could not fetch address predictions', {
        error: error.value,
        data: { address },
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response from address predictions', {
        data: { address },
      });
    }

    return data.value;
  }
}
