<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ProjectSource, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import MeService from '@/core/shared/me/me.service';

import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.vue';
import { getUserName } from '@/core/shared/user-profile/utils';
import ProjectService from '@/core/shared/project/project.service';

const projectService = new ProjectService();
const meService = new MeService();

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
}>();

const route = useRoute();
const router = useRouter();

//Source
const localSourceFilter = ref<ProjectSource | null>(projectService.source);
const sourceFilter = computed<ProjectSource | null>(() => {
  return projectService.source;
});
const sourceItems = [
  {
    value: ProjectSource.ALL,
    title: 'All',
  },
  {
    value: ProjectSource.ATS,
    title: 'ATS',
  },
  {
    value: ProjectSource.FACTORY_FIX,
    title: 'FactoryFix',
  },
];

// Copilot
const localCopilotFilter = ref<boolean | null>(projectService.copilot);
const copilotFilter = computed<boolean | null>(() => {
  return projectService.copilot;
});

// Live Jobs
const localLiveJobsFilter = ref<boolean>(projectService.status === ProjectStatus.LIVE);
const statusFilter = computed<ProjectStatus | null>(() => {
  return projectService.status;
});

// Employer Locations
const localLocationsFilter = ref<string[]>(projectService.locations);
const locationsFilter = computed<string[]>(() => {
  return projectService.locations;
});
const employerLocations = computed(() => {
  const locations: Array<{ value: string; title: string; isSelected: boolean }> = [];
  projectService.employerLocations?.forEach((location) => {
    const isSelected = localLocationsFilter.value.includes(location);
    locations.push({
      value: location,
      title: location,
      isSelected,
    });
  });

  // Sort by name asc
  locations.sort((a, b) => {
    if (a.title?.toLowerCase() < b.title?.toLowerCase()) {
      return -1;
    }
    return 1;
  });

  return locations;
});

// Teams
const localTeamIdsFilter = ref<number[]>(projectService.teamIds);
const teamIdsFilter = computed<number[]>(() => {
  return projectService.teamIds;
});

const employerUsers = computed(() => {
  const teamMemberList: Array<{ value: number; title: string; isSelected: boolean }> = [];
  let me: { value: number; title: string; isSelected: boolean } | null = null;
  meService.employerUsers?.forEach((employerUser) => {
    const isMe = employerUser.id === meService.userProfile?.id;
    const title = `${getUserName(employerUser)} ${isMe ? '[Me]' : ''}`;
    const data = {
      value: employerUser.id,
      title,
      isSelected: localTeamIdsFilter.value.includes(employerUser.id),
    };

    if (isMe) {
      me = data;
    } else {
      teamMemberList.push(data);
    }
  });

  // Sort by name asc
  teamMemberList.sort((a, b) => {
    if (a.title?.toLowerCase() < b.title?.toLowerCase()) {
      return -1;
    }
    return 1;
  });

  // Add me to the top of the list
  if (me) {
    teamMemberList.unshift(me);
  }

  return teamMemberList;
});

// Search Query
const searchQuery = computed(() => route.query);

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

function closeDialog() {
  emit('update:modelValue', false);
}

async function setSearchFilters({ closeMenu = true } = {}) {
  // Set new filters on the store
  projectService.source = localSourceFilter.value;
  projectService.copilot = localCopilotFilter.value;
  // Null value will fetch for both archived and live projects
  projectService.status = localLiveJobsFilter.value ? ProjectStatus.LIVE : null;
  projectService.locations = localLocationsFilter.value ?? [];
  projectService.teamIds = localTeamIdsFilter.value ?? [];

  if (closeMenu) {
    closeDialog();
  }

  // Update the query values
  const query = { ...searchQuery.value };

  if (projectService.source) {
    query.source = projectService.source;
  } else {
    delete query.source;
  }

  if (projectService.copilot) {
    query.copilot = `${projectService.copilot}`;
  } else {
    delete query.copilot;
  }

  if (projectService.status === ProjectStatus.LIVE) {
    query.status = ProjectStatus.LIVE;
  } else {
    // all will set the status to null and thus fetch for both archived and live projects
    // we use this instead of deleting the query.status property because we want to
    // keep the false state.
    query.status = 'all';
  }

  if (projectService.locations.length) {
    query.locations = projectService.locations;
  } else {
    delete query.locations;
  }

  if (projectService.teamIds.length) {
    query.teamIds = projectService.teamIds.map(String);
  } else {
    delete query.teamIds;
  }

  // Search happens in the parent component, updating the query should be enough
  // to trigger the search
  await router.push({ query });
}

function updateSourceFilter(value: ProjectSource) {
  localSourceFilter.value = value;
}

function updateCopilotFilter(value: boolean | null) {
  localCopilotFilter.value = Boolean(value);
}

function updateLiveJobsFilter(value: boolean | null) {
  localLiveJobsFilter.value = Boolean(value);
}

function updateLocationFilter(value: string[] | null) {
  localLocationsFilter.value = value ?? [];
}

function updateTeamIdsFilter(value: number[] | null) {
  if (value) {
    localTeamIdsFilter.value = value;
  } else {
    localTeamIdsFilter.value = [];
  }
}

function clearFilters() {
  // Reset the defaults
  localSourceFilter.value = ProjectSource.ALL;
  localCopilotFilter.value = false;
  localLiveJobsFilter.value = true;
  localLocationsFilter.value = [];
  localTeamIdsFilter.value = [];

  setSearchFilters({ closeMenu: false });
}

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

watch(sourceFilter, (newValue: ProjectSource | null) => {
  localSourceFilter.value = newValue;
});

watch(copilotFilter, (newValue: boolean | null) => {
  localCopilotFilter.value = newValue;
});

watch(statusFilter, (newValue: ProjectStatus | null) => {
  localLiveJobsFilter.value = newValue === ProjectStatus.LIVE;
});

watch(locationsFilter, (newValue: string[]) => {
  localLocationsFilter.value = newValue;
});

watch(teamIdsFilter, (newValue: number[]) => {
  localTeamIdsFilter.value = newValue;
});
</script>

<template>
  <v-menu
    :model-value="isOpen"
    :close-on-content-click="false"
    location="bottom"
    :activator="undefined"
    target="[data-test-id='project-filter-section']"
    width="256px"
    offset="40"
    @update:model-value="handleUpdateModelValue"
  >
    <v-card class="mt-1 !px-6 !py-4">
      <div>
        <p class="mb-0.5 text-xs font-bold">Team</p>
        <v-select
          v-model:model-value="localTeamIdsFilter"
          :items="employerUsers"
          item-title="title"
          item-value="value"
          variant="outlined"
          density="compact"
          multiple
          :menu-icon="ChevronIcon"
          class="v-select--selected"
          :menu-props="{
            contentClass: 'pt-1',
          }"
          @update:model-value="updateTeamIdsFilter"
        >
          <template v-slot:item="{ props, item }">
            <div
              v-bind="props"
              class="select-item"
              :class="{
                'bg-highlight-50 font-bold': item?.raw.isSelected,
              }"
            >
              <Checkbox :model-value="item?.raw.isSelected" class="mr-1" />
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <p class="mb-0.5 text-xs font-bold">Location</p>
        <v-select
          v-model:model-value="localLocationsFilter"
          :items="employerLocations"
          variant="outlined"
          density="compact"
          :menu-icon="ChevronIcon"
          multiple
          class="v-select--selected"
          :menu-props="{
            contentClass: 'pt-1',
          }"
          @update:model-value="updateLocationFilter"
        >
          <template v-slot:item="{ props, item }">
            <div
              v-bind="props"
              class="select-item"
              :class="{
                'bg-highlight-50 font-bold': item?.raw.isSelected,
              }"
            >
              <Checkbox :model-value="item?.raw.isSelected" class="mr-1" />
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <p class="mb-0.5 text-xs font-bold">Job source</p>
        <v-select
          v-model:model-value="localSourceFilter"
          :items="sourceItems"
          item-title="title"
          item-value="value"
          variant="outlined"
          density="compact"
          :menu-icon="ChevronIcon"
          class="v-select--selected"
          :menu-props="{
            contentClass: 'pt-1',
          }"
          @update:model-value="updateSourceFilter"
        >
          <template v-slot:item="{ props, item }">
            <div v-bind="props" class="select-item">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
      </div>
      <div class="flex items-center justify-start">
        <span>
          <v-switch
            v-model:model-value="localLiveJobsFilter"
            :true-value="false"
            :false-value="true"
            color="highlight-500"
            class="mr-2 !inline-block"
            hide-details
            @update:model-value="updateLiveJobsFilter"
            density="compact"
          />
        </span>
        <span class="mt-[-6px] pl-2 text-sm">Show archived jobs</span>
      </div>
      <div class="flex items-center justify-start">
        <span>
          <v-switch
            v-model:model-value="localCopilotFilter"
            color="highlight-500"
            class="mr-2 !inline-block"
            hide-details
            @update:model-value="updateCopilotFilter"
            density="compact"
          />
        </span>
        <span class="mt-[-6px] pl-2 pr-1 text-sm">Copilot jobs only</span>
      </div>
      <div class="flex justify-end !p-0 font-sans text-sm font-semibold">
        <button class="mr-4 px-3 py-1.5" @click="clearFilters">Clear filters</button>
        <button class="rounded-md bg-black px-3 py-1.5" @click="setSearchFilters()">Apply</button>
      </div>
    </v-card>
  </v-menu>
</template>
<style scoped>
:deep(.v-field) {
  border-radius: 6px;

  .v-field__input {
    @apply text-sm;
  }
  .v-field__outline {
    &__start,
    &__end {
      opacity: 1;
      @apply border-y border-tint-80;
    }
  }

  &--focused {
    .v-field__outline {
      opacity: 1;
      &__start {
        @apply border-l border-highlight-500;
      }
      &__end {
        @apply border-r border-highlight-500;
      }
    }
  }
}
.select-item {
  @apply mb-0.5 flex h-8 min-h-[40px] cursor-pointer items-center justify-start px-3 py-1 text-sm hover:bg-tint-20;
}

:deep(.v-switch) {
  &__track {
    opacity: 1;
    @apply bg-tint-400;
  }
  &__track.bg-highlight-500 {
    opacity: 1;
  }
  &__thumb {
    @apply border border-tint-400 !bg-white;
  }
}
</style>
