export function unique<T>(array: T[], selector: (item: T) => number): T[] {
  const seen = new Set();

  return array.filter((item) => {
    const selectorValue = selector(item);

    if (!seen.has(selectorValue)) {
      seen.add(selectorValue);
      return true;
    }

    return false;
  });
}
