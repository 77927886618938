import typography from '@tailwindcss/typography';

/** @type {import('tailwindcss').Config} */
export default {
  content: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  theme: {
    extend: {
      fontWeight: {
        650: '650',
      },
      keyframes: {
        'linear-loading': {
          '0%': { backgroundPosition: '0 0' },
          '50%': { backgroundPosition: '-200% 0' },
          '100%': { backgroundPosition: '-200% 0' },
        },
        'zoom-out': {
          '0%': {
            transform: 'scale(1, 1)',
            opacity: '100%',
          },
          '100%': {
            backgroundPosition: 'scale(0, 0)',
            opacity: '0%',
          },
        },
      },
      animation: {
        'linear-loading': 'linear-loading 2s linear infinite',
        'zoom-out': 'zoom-out 1s linear 1',
      },
      boxShadow: {
        // eslint-disable-next-line max-len
        menu: '0px 8px 40px -16px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.20), 0px 16px 64px -24px rgba(0, 0, 0, 0.20)',
      },
      dropShadow: {
        1: '0 1px 3px rgba(166, 175, 195, 0.40)',
        2: [
          '0 4px 6px rgba(13, 10, 44, 0.03)',
          '0 12px 16px rgba(13, 10, 44, 0.03)',
          '0 1px 2px rgba(13, 10, 44, 0.10)',
        ],
        3: [
          '0 8px 40px rgba(0, 0, 0, 0.10)',
          '0 1px 2px rgba(0, 0, 0, 0.20)',
          '0 16px 64px rgba(0, 0, 0, 0.20)',
        ],
        4: '0 10px 15px rgba(5, 13, 29, 0.18)',
        5: ['0 12px 34px rgba(13, 10, 44, 0.08)', '0 34px 26px 0 rgba(13, 10, 44, 0.05)'],
        6: '0 20px 20px rgba(5, 13, 29, 0.20)',
      },
      lineHeight: {
        4.5: '18px',
      },
      fontSize: {
        '2xs': '10px',
        '3xs': '8px',
      },
      typography(theme) {
        return {
          DEFAULT: {
            css: {
              color: theme('colors.black'),
              fontSize: '12px',
              'p[data-description-part]': {
                fontWeight: 'bold',
              },
              'p.overview': {
                fontWeight: 'bold',
              },
              h1: {
                fontSize: '12px',
              },
              h2: {
                fontSize: '12px',
              },
              h3: {
                fontSize: '12px',
              },
              h4: {
                fontSize: '12px',
              },
              h5: {
                fontSize: '12px',
              },
              h6: {
                fontSize: '12px',
              },
            },
          },
        };
      },
    },
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      transparent: 'transparent',
      tint: {
        0: '#FFFFFF',
        20: '#F8FAFC',
        40: '#F1F5F9',
        60: '#EBF0F5',
        80: '#E2E8F0',
        100: '#CBD5E1',
        200: '#BAC5D6',
        300: '#A7B5C9',
        400: '#94A3B8',
      },
      shade: {
        500: '#94A3B8',
        600: '#8191AA',
        700: '#73839B',
        800: '#64748B',
        805: '#648CAF' /* for border default color */,
        820: '#475569',
        840: '#334155',
        860: '#1E293B',
        880: '#0F172A',
        900: '#000000',
      },
      primary: {
        0: '#F1FCF5',
        50: '#DEFAEA',
        100: '#BEF4D5',
        200: '#8CE9B4',
        300: '#53D58B',
        400: '#2BBA69',
        500: '#1E9B54',
        600: '#1B7A45',
        700: '#1B603A',
        800: '#184F32',
        900: '#123A25',
        1000: '#0B2517',
      },
      success: {
        0: '#F2FFF9',
        50: '#D4F4E5',
        100: '#A8E8C8',
        200: '#85D1AD',
        300: '#56B98A',
        400: '#30A66D',
        500: '#189358',
        600: '#057741',
        700: '#01522B',
        800: '#002614',
        900: '#002614',
        1000: '#002614',
      },
      caution: {
        0: '#FFFAED',
        50: '#FFF4D8',
        100: '#FBEABC',
        200: '#F2DA99',
        300: '#EDCD77',
        400: '#E1BB56',
        500: '#CFA432',
        600: '#BC8C10',
        700: '#966F0A',
        800: '#7B5B06',
        900: '#5F4604',
        1000: '#372800',
      },
      critical: {
        0: '#FFF4F4',
        50: '#FDE6E6',
        100: '#F6CDCD',
        200: '#F5B1B1',
        300: '#EE8A8A',
        400: '#E96363',
        500: '#CE3E3E',
        600: '#B91F1F',
        700: '#950D0D',
        800: '#6C0505',
        900: '#5A0303',
        1000: '#390303',
      },
      highlight: {
        0: '#F9F9FF',
        50: '#ECEAFD',
        100: '#DAD8FC',
        200: '#BFB8FA',
        300: '#9F90F5',
        400: '#8063EF',
        500: '#6C40E5',
        600: '#5F30D1',
        700: '#4E27B0',
        800: '#422290',
        900: '#331875',
        1000: '#210E50',
      },
      inform: {
        0: '#F3FBFF',
        50: '#E5F5FD',
        100: '#D1EEFA',
        200: '#B7E1F1',
        300: '#96CEE7',
        400: '#7ABEDB',
        500: '#5CADD0',
        600: '#3998C1',
        700: '#1278A4',
        800: '#055274',
        900: '#013C55',
        1000: '#002738',
      },
      copilot: {
        DEFAULT: '#1E1950',
        0: '#FBF4FF',
        50: '#F5E6FF',
        100: '#EDD2FF',
        200: '#E0AFFF',
        300: '#CD7CFF',
        400: '#BA4AFF',
        500: '#AE35F7',
        600: '#9216DA',
        700: '#7B18B1',
        800: '#65158E',
        900: '#46016A',
        1000: '#2E0046',
      },
    },
    fontFamily: {
      sans: ['Inter', 'sans-serif'],
      serif: ['Museo', 'serif'],
    },
    screens: {
      xs: '320px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
  },
  plugins: [typography],
};
