import { useFFRestApi } from '@/composables/useApi';
import { InternalError } from '../errors/internal.error';
import { ApiRequestFatalError } from '../errors/api-request-fatal.error';
import { ApiRequestServerError } from '../errors/api-request-server.error';

export class AuthenticationPersistence {
  public async refreshIdToken(): Promise<string> {
    const { data, error, statusCode } = await useFFRestApi('/auth/refresh').get().json<{
      token: string;
    }>();

    if (error.value) {
      throw new InternalError('Could not refresh auth token', {
        error: error.value,
        status: statusCode.value,
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response received from id token refresh');
    }

    return data.value.token;
  }

  public async getAccessTokenWithEmployerContext(employerId: number): Promise<string> {
    // NOTE: useFFRestApi grabs idToken from the auth service and uses it as the bearer token here
    const { data, error, statusCode } = await useFFRestApi(
      `/auth/access-token/employer/${employerId}`,
    )
      .get()
      .json<{ token: string }>();

    if (error.value) {
      if (statusCode.value && statusCode.value >= 400 && statusCode.value < 500) {
        throw new ApiRequestFatalError(
          `Request for access token failed with status code ${statusCode.value}`,
        );
      }
      if (statusCode.value && statusCode.value >= 500) {
        throw new ApiRequestServerError(
          `Request for access token failed with status code ${statusCode.value}`,
        );
      }

      throw new InternalError('Could not get access token', {
        error: error.value,
        status: statusCode.value,
        data: { employerId },
      });
    }

    if (!data.value?.token) {
      throw new InternalError('Empty response received from access token request');
    }

    return data.value.token;
  }
}
